import "core-js/stable";
import "cross-fetch/polyfill";
import smoothscroll from "smoothscroll-polyfill";

import React from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "@material-ui/core";
import "moment/locale/da";

import "./index.css";
import "./firebase";
import { Application } from "./application/Application";
import { theme } from "./theme";
import { UserProvider } from "./user/UserProvider";
import { BlockingProgressProvider } from "./components/blocking-progress";
import { SnackbarProvider } from "./components/snackbar";
import { DialogProvider } from "./components/dialog";

smoothscroll.polyfill();

ReactDOM.render(
    <React.StrictMode>

        <ThemeProvider theme={theme}>
            <UserProvider>
                <DialogProvider>
                    <SnackbarProvider>
                        <BlockingProgressProvider>

                            <Application />

                        </BlockingProgressProvider>
                    </SnackbarProvider>
                </DialogProvider>
            </UserProvider>
        </ThemeProvider>

    </React.StrictMode>,
    document.getElementById("root")
);
