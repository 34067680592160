import { makeStyles } from "@material-ui/core";
import { PropsWithChildren } from "react";

const useStyles = makeStyles({
    root: {
        marginBottom: "48px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > *": {
            marginBottom: "24px",
            
            "&:last-child": {
                marginBottom: 0,
            },
        },
    },
});

export const DialogSection = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {props.children}
        </div>
    );
};
