import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from "react";

import { BlockingProgress } from "./BlockingProgress";

const Context = createContext<{
    block: () => void;
    unblock: () => void;
}>(undefined as any);

export const BlockingProgressProvider = (props: PropsWithChildren<{}>) => {
    const [blocking, setBlocking] = useState(false);

    const block = useCallback(() => {
        setBlocking(true);
    }, []);

    const unblock = useCallback(() => {
        setBlocking(false);
    }, []);

    const value = useMemo(() => ({ block, unblock }), [block, unblock]);

    return (
        <Context.Provider value={value}>

            {props.children}
            <BlockingProgress open={blocking} />

        </Context.Provider>
    );
};

export const useBlockingProgress = () => {
    return useContext(Context);
}
