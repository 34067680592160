import { useCallback, useMemo } from "react";

import { Collapse } from "@material-ui/core";
import { da } from "date-fns/locale";
import moment from "moment";

import { AlternativeHint, DateField, Group, Label } from "../../../form";
import { validateDate, validateDateNotAfter, validateDateNotBefore } from "../../../validation/validation";
import { useForm } from "../../framework/react/FormProvider";
import { FormItemSupport } from "../../framework/core/form-item.support";

export interface DatePickerFormItem {
    id: string;
    type: "date-picker";
    label: string;
    value: string;
    dirty: boolean;
    minimumDate: string | null;
    maximumDate: string | null;
}

interface DatePickerFormItemComponentProps {
    item: DatePickerFormItem;
}

export const DatePickerFormItemComponent = (props: DatePickerFormItemComponentProps) => {
    const { controller, validationAnimationDuration, updateItem } = useForm();
    const { item } = props;

    const updateValue = useCallback((value: string) => {
        updateItem(item.id, (state: DatePickerFormItem) => ({ ...state, value }));
    }, [updateItem, item.id]);

    const markDirty = useCallback(() => {
        updateItem(item.id, state => controller.markDirty(state));
    }, [controller, updateItem, item.id]);

    const error = useMemo(() => validate(item), [item]);

    const minimumDate = moment(item.minimumDate).toDate();
    const maximumDate = moment(item.maximumDate).toDate();

    return (
        <div id={`${item.id}-section`}>
            <Label htmlFor={item.id} style={{ marginBottom: "8px" }}>{item.label}</Label>
            <Group error={Boolean(item.dirty && error)}>
                <DateField id={item.id} value={item.value} onChange={updateValue} onBlur={markDirty} placeholder="DD-MM-YYYY" minimumDate={minimumDate} maximumDate={maximumDate} locale={da} />

                <Collapse in={Boolean(item.dirty && error)} timeout={validationAnimationDuration}>
                    <AlternativeHint message={error} />
                </Collapse>
            </Group>
        </div>
    );
};

export class DatePickerFormItemSupport implements FormItemSupport<DatePickerFormItem> {
    supportedType = "date-picker" as const;
    updateChildren = undefined;

    markDirty(item: DatePickerFormItem): DatePickerFormItem {
        return { ...item, dirty: true };
    }

    getAllChildren() {
        return [];
    }

    getActiveChildren() {
        return [];
    }

    blocksParent(item: DatePickerFormItem): boolean {
        return Boolean(validate(item));
    }
}

const validate = (item: DatePickerFormItem): string | undefined => {
    const rules = [validateDate(item.value)];

    if ( item.minimumDate ) rules.push(validateDateNotBefore(item.value, item.minimumDate));
    if ( item.maximumDate ) rules.push(validateDateNotAfter(item.value, item.maximumDate));

    return rules.find(Boolean);
}
