import { useCallback, useMemo } from "react";

import { makeStyles } from "@material-ui/core";
import SeeInvoiceIcon from "@material-ui/icons/Description";
import firebase from "firebase/app";
import moment from "moment";

import { CardContent } from "../../components/card/CardContent";
import { Translation, useTranslation } from "../../internationalisation/translation.hook";
import { RegistrationDocument, Task } from "../registration.model";
import { Card } from "../../components/card/Card";
import { CardHeader } from "../../components/card/CardHeader";
import { CardStatus } from "../../components/card/CardStatus";
import { MenuButton } from "../../components/menu/MenuButton";
import { MenuItem } from "../../components/menu/MenuItem";
import { InvoiceDocument } from "../../invoice/invoice.model";
import { useUser } from "../../user/UserProvider";
import { useSnackbar } from "../../components/snackbar";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
        }
    },
    registration: {
        "& > *": {
            marginBottom: "16px",
        },
    
        "& > *:last-child": {
            marginBottom: 0,
        }
    },
    task: {
        "& > h4": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#4A4A4A",
            marginTop: 0,
            marginBottom: "4px",
        },

        "& > p": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            margin: 0,
        },
    },
    dottedSeparator: {
        backgroundImage: "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='-10%25' x2='110%25' y1='0' y2='0' fill='none' rx='4' ry='4' stroke='%23818181' stroke-width='2' stroke-dasharray='1%2c 4' stroke-dashoffset='2' stroke-linecap='butt'/%3e%3c/svg%3e\")",
        height: "1px",
    }
});

interface RegistrationCardProps {
    customerName: string;
    registration: RegistrationDocument;
    now: string;
}

export const RegistrationCard = (props: RegistrationCardProps) => {
    const classes = useStyles();
    const { t, formatCurrency } = useTranslation();
    const { customerName, registration, now } = props;
    
    const status = useMemo(() => {
        if ( registration.paymentInformation === undefined ) return null;
        if ( registration.paymentInformation === null ) return <CardStatus status="afventer fakturering" color="default" />;
        if ( registration.paymentInformation.customerPaidAmount >= registration.paymentInformation.gardenerEarnedAmount ) return <CardStatus status="betalt" color="success" />;
        
        const invoiceDueDate = moment(registration.paymentInformation.invoiceDueDate).add(1, "day").add(10, "hours");
        if ( moment(now).isAfter(invoiceDueDate) ) return <CardStatus status="forfalden" color="warning" />;
        
        return null;
    }, [now, registration.paymentInformation]);

    const gardener = useUser();
    const { openSnackbar } = useSnackbar();
    const goToInvoice = useCallback(async () => {
        const invoice = await getInvoiceByGardenerIdAndRegistrationId(gardener.id, registration.id);
        if ( !invoice ) return openSnackbar("warning", "Vi kunne ikke finde din faktura. Du kan kontakte os via \"Bed om hjælp\" oppe i topbaren.");
        
        window.open(`https://gogogarden.dk/billing/invoices/invoice?id=${invoice.id}`, "_blank");
    }, [gardener, openSnackbar, registration.id]);

    const menuButton = (
        <MenuButton>
            <MenuItem icon={<SeeInvoiceIcon />} onClick={goToInvoice}>Se faktura</MenuItem>
        </MenuButton>
    );

    return (
        <Card>

            {status}

            <CardHeader title={customerName} button={menuButton} />

            <CardContent className={classes.registration}>
                {registration.tasks.map((task, index) => (
                    <div key={index} className={classes.task}>
                        <h4>{task.description}</h4>
                        <p>{formatTask(t, task)}</p>
                    </div>
                ))}
            </CardContent>

            {registration.paymentInformation ? (
                <>
                    <div className={classes.dottedSeparator} />
                    
                    <CardContent>
                        <div className={classes.group}>
                            <label>{t("RegistrationCard: commission-earned-label")}</label>
                            <span>{
                                t("RegistrationCard: commission-earned-description")
                                    .replace("$PAID_AMOUNT", formatCurrency(registration.paymentInformation.customerPaidAmount))
                                    .replace("$EARNED_AMOUNT", formatCurrency(registration.paymentInformation.gardenerEarnedAmount))
                            }</span>
                        </div>
                    </CardContent>
                </>
            ) : null}
        </Card>
    )
};

function formatTask(t: Translation, task: Task): string {
    const durationInHours = task.durationInMinutes / 60;
    const durationValue = durationInHours.toString().replace(".", ",");
    const durationLabel = durationInHours === 1 ? t("RegistrationOverviewPage: hours-singular") : t("RegistrationOverviewPage: hours-plural");
    const duration = `${durationValue} ${durationLabel}`;

    const tools = (() => {
        if ( task.tools === "customers-tools" ) return t("RegistrationOverviewPage: tools-customer");
        if ( task.tools === "helper-tools" ) return t("RegistrationOverviewPage: tools-gardener");
        if ( task.tools === "large-tools" ) return t("RegistrationOverviewPage: tools-gardener-large");

        return t("RegistrationOverviewPage: tools-unknown");
    })();

    return `${duration} ${t("RegistrationOverviewPage: with")} ${tools}`;
};

async function getInvoiceByGardenerIdAndRegistrationId(gardenerId: string, registrationId: string): Promise<InvoiceDocument | null> {
    const snapshot = await firebase.firestore().collection("invoices").where("gardener.id", "==", gardenerId).where("registrationId", "==", registrationId).get();
    const invoices = snapshot.docs
        .map(document => ({ id: document.id, ...document.data() } as InvoiceDocument))
        .filter(invoice => invoice.status === "did-invoice");
    
    if ( invoices.length === 0 ) return null;
    if ( invoices.length > 1 ) return null;

    return invoices[0];
}
