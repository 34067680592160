import { MouseEvent, PropsWithChildren, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles, Tab } from "@material-ui/core";

const useTabStyles = makeStyles({
    root: {
        padding: "6px 16px",
        minWidth: "auto",
        fontSize: "14px",
        fontWeight: 500,
        color: "#4A4A4A",
    },
    selected: {
        color: "#297A1D",
    },
});

interface TabBarItemProps {
    href: string;
}

export const TabBarItem = (props: PropsWithChildren<TabBarItemProps>) => {
    const tabClasses = useTabStyles();

    const { href, children, ...otherProps } = props;

    const history = useHistory();
    const onClick = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        history.push(href);
    }, [history, href]);

    return (
        <Tab component="a" href={href} label={children} classes={tabClasses} onClick={onClick} {...otherProps} />
    );
}
