import { makeStyles } from "@material-ui/core";
import { cloneElement } from "react";

const useStyles = makeStyles({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "& > p": {
            fontSize: "16px",
            fontWeight: 600,
            color: "#4A4A4A",
            margin: 0,
        },
    },
    button: {
        margin: "-16px",
        marginRight: "-8px",
    },
});

export interface DialogSectionHeaderProps {
    text: string;
    button?: JSX.Element;
}

export const DialogSectionHeader = (props: DialogSectionHeaderProps) => {
    const classes = useStyles();

    const button = props.button ? cloneElement(props.button, { className: classes.button }) : null;

    return (
        <div className={classes.root}>
            <p>{props.text}</p>

            {button}
        </div>
    );
};
