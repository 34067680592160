import { Section } from "../../components/section/Section";
import { SectionHeader } from "../../components/section/SectionHeader";
import { ListSectionItem, ListSectionItemProps } from "./ListSectionItem";
import { Message } from "../Message";

export interface ListSectionProps {
    title: string;
    items: Omit<ListSectionItemProps, "index" | "onClick">[];
    placeholder: string;
    onClick: (index: number) => void;
}

export const ListSection = (props: ListSectionProps) => {
    const { title, items, placeholder, onClick } = props;

    return (
        <Section>
            <SectionHeader>{title}</SectionHeader>

            {items.length === 0 ? (
                <Message text={placeholder} />
            ) : null}

            {items.map((item, index) => (
                <ListSectionItem key={index} heading={item.heading} subheading={item.subheading} alert={item.alert} index={index} onClick={onClick} />
            ))}

        </Section>
    );
};
