import { makeStyles } from "@material-ui/core";

import { TextButtonBase, TextButtonBaseProps } from "./TextButtonBase";

const useStyles = makeStyles({
    root: {
        color: "#297A1D",
    },
});

export const GreenTextButton = (props: TextButtonBaseProps) => {
    const classes = useStyles();

    const { className, ...otherProps } = props;

    return (
        <TextButtonBase className={className ? `${classes.root} ${className}` : classes.root} {...otherProps} />
    );
};
