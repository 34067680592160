import { useCallback, useMemo } from "react";

import { Collapse } from "@material-ui/core";

import { AlternativeHint, Group, Label } from "../../../form";
import { FormItem } from "../../framework/core/form-item.model";
import { FormItemComponent } from "../components/FormItemComponent";
import { useForm } from "../../framework/react/FormProvider";
import { HorizontalButtons } from "../../../form/horizontal-buttons/HorizontalButtons";
import { MultipleChoiceGroup } from "../../../form/multiple-choice";
import { FormItemSupport } from "../../framework/core/form-item.support";
import { FormController } from "../../framework/core/form.controller";

export type MultiChoiceFormItem<AnswerItems extends (FormItem | null)[], NextItem extends FormItem> = VoidMultiChoiceFormItem<AnswerItems, NextItem> | HorizontalButtonsMultiChoiceFormItem<AnswerItems, NextItem> | VerticalButtonsMultiChoiceFormItem<AnswerItems, NextItem>;

export interface VoidMultiChoiceFormItem<AnswerItems extends (FormItem | null)[], NextItem extends FormItem> {
    type: "multi-choice",
    render: "void";
    questionText: string | null;
    answerTexts: string[];
    answerItems: AnswerItems;
    // TODO: I'd prefer this interface. But I need to figure out how to handle the typing
    /*choices: Array<{
        answerText: string;
        item: FormItem;
    }>*/
    afterItem: NextItem;
    selectedChoiceIndexes: number[];
}

export interface HorizontalButtonsMultiChoiceFormItem<AnswerItems extends (FormItem | null)[], NextItem extends FormItem> {
    id: string;
    type: "multi-choice",
    render: "horizontal-buttons";
    questionText: string | null;
    answerTexts: string[];
    answerItems: AnswerItems;
    // TODO: I'd prefer this interface. But I need to figure out how to handle the typing
    /*choices: Array<{
        answerText: string;
        item: FormItem;
    }>*/
    afterItem: NextItem;
    selectedChoiceIndexes: number[];
    dirty: boolean;
}

export interface VerticalButtonsMultiChoiceFormItem<AnswerItems extends (FormItem | null)[], NextItem extends FormItem> {
    id: string;
    type: "multi-choice",
    render: "vertical-buttons";
    questionText: string | null;
    answerTexts: string[];
    answerItems: AnswerItems;
    // TODO: I'd prefer this interface. But I need to figure out how to handle the typing
    /*choices: Array<{
        answerText: string;
        item: FormItem;
    }>*/
    afterItem: NextItem;
    selectedChoiceIndexes: number[];
    dirty: boolean;
}

interface MultiChoiceFormItemComponentProps<AnswerItems extends (FormItem | null)[], NextItem extends FormItem> {
    item: MultiChoiceFormItem<AnswerItems, NextItem>;
}

export const MultiChoiceFormItemComponent = <AnswerItems extends (FormItem | null)[], NextItem extends FormItem>(props: MultiChoiceFormItemComponentProps<AnswerItems, NextItem>) => {
    const { item } = props;

    return (
        <>
            {(() => {
                switch ( item.render ) {
                    case "horizontal-buttons": return <HorizontalButtonsMultiChoiceFormItemComponent item={item} />;
                    case "vertical-buttons": return <VerticalButtonsMultiChoiceFormItemComponent item={item} />;
                    case "void": return null;
                }
            })()}

            {item.selectedChoiceIndexes.map(index => {
                const childItem = item.answerItems[index];
                if ( !childItem ) return null;

                return <FormItemComponent key={index} item={childItem} />;
            })}

            {item.selectedChoiceIndexes.length > 0 ? <FormItemComponent item={item.afterItem} /> : null}
        </>
    );
};

interface HorizontalButtonsMultiChoiceFormItemComponentProps<NextItem extends (FormItem | null)[], AfterItem extends FormItem> {
    item: HorizontalButtonsMultiChoiceFormItem<NextItem, AfterItem>;
}

export const HorizontalButtonsMultiChoiceFormItemComponent = <NextItem extends (FormItem | null)[], AfterItem extends FormItem>(props: HorizontalButtonsMultiChoiceFormItemComponentProps<NextItem, AfterItem>) => {
    const { item } = props;
    const { validationAnimationDuration, updateItem } = useForm();

    const selectAnswer = useCallback((selectedChoiceIndex: number | null) => {
        if ( selectedChoiceIndex === null ) return;

        updateItem(item.id, (state: HorizontalButtonsMultiChoiceFormItem<NextItem, AfterItem>) => {
            if ( state.selectedChoiceIndexes.includes(selectedChoiceIndex) ) {
                return {
                    ...state,
                    selectedChoiceIndexes: state.selectedChoiceIndexes.filter(x => x !== selectedChoiceIndex),
                };
            } else {
                return {
                    ...state,
                    selectedChoiceIndexes: [...state.selectedChoiceIndexes, selectedChoiceIndex].sort((a, b) => a - b),
                };
            }
        });
    }, [updateItem, item.id]);

    const error = useMemo(() => validate(item), [item]);

    return (
        <div id={`${item.id}-section`}>
            {item.questionText ? <Label style={{ marginBottom: "8px" }}>{item.questionText}</Label> : null}
            <Group error={Boolean(item.dirty && error)}>

                <HorizontalButtons
                    color="green"
                    buttonTexts={item.answerTexts}
                    selectedButtonIndexes={item.selectedChoiceIndexes}
                    onSelect={selectAnswer}
                />

                <Collapse in={Boolean(item.dirty && error)} timeout={validationAnimationDuration}>
                    <AlternativeHint message={error} />
                </Collapse>
            </Group>
        </div>
    );
};

interface VerticalButtonsMultiChoiceFormItemComponentProps<NextItem extends (FormItem | null)[], AfterItem extends FormItem> {
    item: VerticalButtonsMultiChoiceFormItem<NextItem, AfterItem>;
}

export const VerticalButtonsMultiChoiceFormItemComponent = <NextItem extends (FormItem | null)[], AfterItem extends FormItem>(props: VerticalButtonsMultiChoiceFormItemComponentProps<NextItem, AfterItem>) => {
    const { item } = props;
    const { validationAnimationDuration, updateItem } = useForm();

    const selectAnswer = useCallback((selectedChoiceIndexesAsStrings: string[]) => {
        const selectedChoiceIndexes = selectedChoiceIndexesAsStrings.map(x => parseInt(x, 10));

        updateItem(item.id, (state: VerticalButtonsMultiChoiceFormItem<NextItem, AfterItem>) => ({
            ...state,
            selectedChoiceIndexes: selectedChoiceIndexes.sort((a, b) => a - b),
            dirty: true,
        }));
    }, [updateItem, item.id]);

    const error = useMemo(() => validate(item), [item]);

    const selectedChoiceIndexesAsStrings = item.selectedChoiceIndexes.map(x => x.toString());

    const options = useMemo<Array<{ id: string, text: string}>>(() => {
        return item.answerTexts.map((answerText, index) => ({ id: index.toString(), text: answerText }));
    }, [item.answerTexts]);

    return (
        <div id={`${item.id}-section`}>
            {item.questionText ? <Label style={{ marginBottom: "8px" }}>{item.questionText}</Label> : null}
            <Group error={Boolean(item.dirty && error)}>

                <MultipleChoiceGroup
                    options={options}
                    value={selectedChoiceIndexesAsStrings}
                    onChange={selectAnswer}
                />

                <Collapse in={Boolean(item.dirty && error)} timeout={validationAnimationDuration}>
                    <AlternativeHint message={error} />
                </Collapse>
            </Group>
        </div>
    );
};

type MultiChoiceFormItemType = MultiChoiceFormItem<(FormItem | null)[], FormItem>;
export class MultiChoiceFormItemSupport implements FormItemSupport<MultiChoiceFormItemType> {

    supportedType = "multi-choice" as const;

    updateChildren = (item: MultiChoiceFormItemType, updateChildItem: (item: FormItem) => FormItem): MultiChoiceFormItemType => {
        return {
            ...item,
            answerItems: item.answerItems.map(childItem => {
                if ( !childItem ) return null;

                return updateChildItem(childItem);
            }),
            afterItem: updateChildItem(item.afterItem),
        };
    };

    markDirty(item: MultiChoiceFormItemType): MultiChoiceFormItemType {
        if ( !("dirty" in item) ) return item;
        
        return { ...item, dirty: true };
    }

    getAllChildren(controller: FormController, item: MultiChoiceFormItemType) {
        const answerItems = item.answerItems.filter(Boolean) as FormItem[];

        return [...answerItems, item.afterItem];
    }

    getActiveChildren(controller: FormController, item: MultiChoiceFormItemType) {
        const answerItems = item.selectedChoiceIndexes.map(index => item.answerItems[index]).filter(Boolean) as FormItem[];

        return [...answerItems, item.afterItem];
    }

    blocksParent(item: MultiChoiceFormItemType): boolean {
        return Boolean(validate(item));
    }
}

const validate = (item: MultiChoiceFormItem<(FormItem | null)[], FormItem>) => {
    if ( item.selectedChoiceIndexes.length === 0 ) return "Vælg mindst ét svar";

    return undefined;
};
