import { useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { CurrentTasksTab } from "./CurrentTasksTab";
import { useTasks } from "../../task/TaskProvider";

export const IntegratedCurrentTasksTab = () => {
    const history = useHistory();
    const goToTask = useCallback((task: { id: string }) => {
        history.push(`/overview/current/${task.id}`);
    }, [history]);
    
    const now = useRef(moment().toISOString(true)).current;
    const tomorrow = useRef(moment(now).add(1, "day").toISOString(true)).current;

    const tasks = useTasks();
    const relevantTasks = tasks.filter(task => task.type === "offer-response" || moment(task.performAfterDate).isSameOrBefore(tomorrow, "day"));

    return (
        <CurrentTasksTab
            goToTask={goToTask}
            tasks={relevantTasks}
            now={now}
        />
    );
};
