import { ButtonProps, makeStyles } from "@material-ui/core";

import { ButtonBase } from "./ButtonBase";

const useStyles = makeStyles({
    root: {
        backgroundColor: "#A259FF",
        color: "#FFFFFF",

        "&:hover": {
            backgroundColor: "#7A14FF",
        },
        
        "&:disabled": {
            backgroundColor: "#818181",
            color: "#FFFFFF",
        },
    },
});

export const PurpleButton = (props: ButtonProps) => {
    const classes = useStyles();

    const { className, ...otherProps } = props;

    return (
        <ButtonBase
            className={className ? `${classes.root} ${className}` : classes.root}
            variant="contained"
            {...otherProps}
        />
    );
};
