import { Children, cloneElement, isValidElement, useCallback, useRef, useState } from "react";

import { IconButton, ButtonProps, ClickAwayListener, Grow, MenuList, Paper, Popper, makeStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/MoreHoriz";

const useStyles = makeStyles({
    menuIcon: {
        fontSize: "24px",
        color: "#297A1D",
    },
    list: {
        minWidth: "224px",
    },
})

export const MenuButton = (props: Omit<ButtonProps, "onClick">) => {
    const classes = useStyles();

    const { id, children } = props;

    const [open, setOpen] = useState(false);
    const anchorReference = useRef<HTMLButtonElement>(null);

    const toggleMenu = useCallback(() => {
        setOpen(previouslyOpen => !previouslyOpen);
    }, []);

    const closeMenu = useCallback(() => {
        setOpen(false);
    }, []);

    const closeIfTab = useCallback((event: React.KeyboardEvent) => {
        if ( event.key === "Tab" ) {
            event.preventDefault();
            setOpen(false);
        }
    }, []);

    return (
        <>
            <IconButton
                ref={anchorReference}
                aria-controls={open ? id : undefined}
                aria-haspopup="true"
                aria-label="open menu"
                onClick={toggleMenu}
            >
                <MenuIcon className={classes.menuIcon} />
            </IconButton>

            <Popper open={open} anchorEl={anchorReference.current} role={undefined} placement="bottom-end" transition disablePortal>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={closeMenu}>
                                <MenuList className={classes.list} disablePadding autoFocusItem={open} id={id} onKeyDown={closeIfTab}>

                                    {Children.map(children, child => {
                                        if ( !isValidElement(child) ) return child;

                                        return cloneElement(child, { closeMenu } as any);
                                    })}

                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
