import { HTMLAttributes } from "react";

import { Collapse, Container, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import PlusIcon from "@material-ui/icons/AddOutlined";
import { useAccordionContext } from "./AccordionProvider";

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: "4px",

        "&:last-child": {
            marginBottom: 0,
        },
    },
    bar: {
        backgroundColor: "#FFFFFF",
        padding: "16px 24px",

        "&:hover": {
            backgroundColor: "#EBF3EA",
        },

        "& > div": {
            display: "flex",
            alignItems: "center",
        },

        "& > span": {
            display: "none",
        },
    },
    barText: {
        margin: 0,

        "& > span": {
            fontSize: "20px",
            fontWeight: 600,
            color: "#4A4A4A",

            [theme.breakpoints.down(500)]: {
                fontSize: "16px",
            },
        },
    },
    barIcon: {
        fontSize: "32px",
        color: "#4A4A4A",
    },
    content: {
        backgroundColor: "#FFFFFF",
        padding: "16px 24px",
        whiteSpace: "pre-wrap",
    },
    letterGroup: {
        marginBottom: "36px",

        "&:last-child": {
            marginBottom: 0,
        },
    },
    item: {
        marginTop: 0,
        marginBottom: "8px",
    },
}));

interface AccordionListItemProps extends HTMLAttributes<HTMLElement> {
    id: string;
    title: string;
}

export const AccordionListItem = (props: AccordionListItemProps) => {
    const classes = useStyles();

    const { id, title, className, children, ...otherProps } = props;

    const { openAccordion, toggleAccordion } = useAccordionContext();

    return (
        <div className={className ? `${classes.root} ${className}` : classes.root} {...otherProps}>
            <ListItem button id={`${id}-header`} aria-controls={`${id}-content`} className={classes.bar} onClick={toggleAccordion}>
                <Container maxWidth="xl" disableGutters>
                    <ListItemText primary={title} className={classes.barText} />
                    <PlusIcon className={classes.barIcon} />
                </Container>
            </ListItem>

            <Collapse in={openAccordion === id}>
                <div id={`${id}-content`} aria-labelledby={`${id}-header`} className={classes.content}>
                    {children}
                </div>
            </Collapse>
        </div>
    );
};
