import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: theme.zIndex.snackbar + 1,
    },
    progress: {
        color: "#2FA52B",
    },
}));

interface BlockingProgressProps {
    open: boolean;
}

export const BlockingProgress = (props: BlockingProgressProps) => {
    const classes = useStyles();

    const { open } = props;

    return (
        <Backdrop open={open} className={classes.root}>
            <CircularProgress className={classes.progress} />
        </Backdrop>
    );
};
