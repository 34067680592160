import { makeStyles } from "@material-ui/core";
import { AccordionList } from "../components/accordion/AccordionList";
import { AccordionListItem } from "../components/accordion/AccordionListItem";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
    link: {
        fontWeight: 600,
        color: "#297A1D",
        textDecoration: "none",

        "&:hover": {
            color: "#1C5B13",
            textDecoration: "underline",
        },
    }
});

export const NewTaskFaq = () => {
    const classes = useStyles();

    return (
        <AccordionList>

            <AccordionListItem id="new-task-question-1" title="Hvordan indstiller jeg, hvilke opgaver jeg tilbydes?">
                Under <Link to="/profile" className={classes.link}>din profil</Link> kan du indstille, hvilke opgaver du tilbydes.<br />
                <br />
                Du kan indstille den maskimale afstand til opgaver, hvilke typer opgaver du ønsker at udføre, samt hvilke redskaber du vil medbringe.<br />
                <br />
                Oplever du at blive tilbudt opgaver, som ikke er interessante for dig, kan du skrive til <a href="mailto:udvikling@gogogarden.dk" className={classes.link}>udvikling@gogogarden.dk</a> for at høre, om vi kan understøtte din parameter.
            </AccordionListItem>

            <AccordionListItem id="new-task-question-2" title="Behøver jeg afvise opgaver, hvis jeg ikke er interesseret?">
                Ja, det er vigtigt, at du besvarer nye opgaver hurtigst muligt, da nye opgaver kun tilbydes ud til én havemand af gangen og det ellers forsinker kunden unødigt.<br />
                <br />
                Sker det jævnligt, at du ikke svarer inden for 24 timer, kan det medføre eksklusion fra platformen.
            </AccordionListItem>

            <AccordionListItem id="new-task-question-3" title="Hvad forpligter jeg mig til, hvis jeg accepterer en opgave?">
                Du bestemmer selv, hvilke opgaver du vil acceptere, men accepterer du en opgave, så accepterer du også de forpligtelser, der følger med den.<br />
                <br />
                Dine forpligtelser består i at ringe til kunden, aftale besøg og udføre ordentligt havearbejde.<br />
                <br />
                I din oversigt kan du se de forpligtelser, som du behøver forholde dig til. Det er vigtigt, at du besvarer opgaverne inden for tidsfristen.
            </AccordionListItem>

            <AccordionListItem id="new-task-question-4" title="Hvordan kan jeg undgå at modtage nye opgaver i en periode?">
                Under <Link to="/profile" className={classes.link}>din profil</Link> kan du sætte dig selv på pause. Det kan f.eks. være nyttigt, hvis du skal på ferie eller ikke kan tage flere opgaver ind.<br />
                <br />
                Når du er på pause bliver du ikke tilbudt nye opgaver, men du forventes stadig at håndtere allerede accepterede opgaver.<br />
                <br />
                OBS: Husk at sætte dig selv aktiv, når du ønsker nye opgaver igen.
            </AccordionListItem>

        </AccordionList>
    );
};
