import { useEffect, useState } from "react";

import { makeStyles, FormHelperText, FormHelperTextProps } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        padding: "0 16px",
        fontSize: "14px",
        fontWeight: 500,
        position: "absolute",
        top: "100%",
    },
    alternative: {
        padding: "0 16px",
        fontSize: "14px",
        fontWeight: 500,
    },
    error: {
    },
    warning: {
        color: "#E37F09 !important",
    },
});

export const Hint = (props: FormHelperTextProps) => {
    const { className, ...otherProps } = props;

    const classes = useStyles();

    return (
        <FormHelperText
            className={className !== undefined ? `${className} ${classes.root}` : classes.root}
            {...otherProps}
        />
    );
};

export interface AlternativeHintProps extends Omit<FormHelperTextProps, "children">  {
    severity?: "error" | "warning";
    message: string | undefined;
}

export const AlternativeHint = (props: AlternativeHintProps) => {
    const { severity, message, className, ...otherProps } = props;
    

    const classes = useStyles();

    const [retainedInformation, setRetainedInformation] = useState({ severity, message });

    useEffect(() => {
        if ( !message ) return;

        setRetainedInformation({ severity, message });
    }, [severity, message]);

    const severityClass = (retainedInformation.severity ?? "error") === "error" ? classes.error : classes.warning;

    return (
        <FormHelperText error className={className !== undefined ? `${className} ${classes.alternative} ${severityClass}` : `${classes.alternative} ${severityClass}`} {...otherProps}>
            {retainedInformation.message}
        </FormHelperText>
    );
};
