import { PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    dangerStatus: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#F44336",
    },
    warningStatus: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#FF9800",
    },
    successStatus: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#2FA52B",
    },
    informationStatus: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#0076EB",
    },
    alternativeStatus: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#A259FF",
    },
    defaultStatus: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#818181",
    },
    statusTitle: {
        flexGrow: 1,
        padding: "4px 16px",
        fontSize: "16px",
        fontWeight: 600,
        fontVariant: "all-small-caps",
        letterSpacing: "0.75px",
        color: "#FFFFFF",
        margin: 0,

        textAlign: "center",
    },
}));

export interface CardStatusProps {
    status: string;
    color: "danger" | "warning" | "success" | "info" | "alt" | "default";
}

export const CardStatus = (props: PropsWithChildren<CardStatusProps>) => {
    const classes = useStyles();

    const { status } = props;

    const statusStyle = (() => {
        switch ( props.color ) {
            case "danger": return classes.dangerStatus;
            case "warning": return classes.warningStatus;
            case "success": return classes.successStatus;
            case "info": return classes.informationStatus;
            case "alt": return classes.alternativeStatus;
            case "default": return classes.defaultStatus;
        }
    })();

    return (
        <div className={statusStyle}>
            <h3 className={classes.statusTitle}>{status}</h3>
        </div>
    );
};
