import { PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        fontWeight: 500,
        color: "#F44336",
    },
});

export const Notice = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();
    const { children } = props;

    return <span className={classes.root}>{children}</span>
}
