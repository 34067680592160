import { forwardRef } from "react";

import { Paper, makeStyles } from "@material-ui/core";
import { gardenerPrices, gardenerPricesIfVatLiable } from "../constants";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "16px",

        "& h1": {
            fontSize: "32px",
            fontWeight: 600,
            color: "#1C5B13",
            textAlign: "center",
            marginTop: 0,
            marginBottom: "64px",
        
            [theme.breakpoints.down(500)]: {
                fontSize: "24px",
            },
        },

        "& h2": {
            fontSize: "20px",
            fontWeight: 600,
            color: "#1C5B13",
            marginTop: 0,
            marginBottom: "16px",

            [theme.breakpoints.down(500)]: {
                fontSize: "16px",
            },
        },

        "& h3": {
            fontSize: "16px",
            fontWeight: 600,
            color: "#4A4A4A",
            marginTop: 0,
            marginBottom: "16px",
        },

        "& p": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            marginTop: 0,
            marginBottom: "16px",

            "&:last-child": {
                marginBottom: 0,
            },
        },

        "& a": {
            fontWeight: 600,
            color: "#297A1D",
            textDecoration: "none",

            "&:hover": {
                color: "#1C5B13",
                textDecoration: "underline",
            },
        },
    },
    section: {
        marginBottom: "64px",

        "&:last-child": {
            marginBottom: 0,
        },
    },
    subSection: {
        marginBottom: "32px",

        "&:last-child": {
            marginBottom: 0,
        },
    },
}));

export const AgreementSection = forwardRef((_props: any, ref: any | null) => {
    const classes = useStyles();

    return (
        <Paper ref={ref} className={classes.root}>

            <h1>Samarbejdsaftale</h1>

            <div className={classes.section}>
                <h2>1. Koncept</h2>
                <p>1.1 Go Go Garden er et koncept, som giver kunderne mulighed for at bestille haveservice online, hvorefter en partner udfører ydelsen. Driften af platformen varetages af Go Go Garden ApS, CVR 41536942, Hindegade 6, 1303 København K, email: <a href="mailto:info@gogogarden.dk">info@gogogarden.dk</a>.</p>
                <p>1.2 Partneren er en havemand, som ønsker at tilbyde haveservice til kunderne via platformen. Go Go Garden stiller på den baggrund platformen til rådighed for partneren på de vilkår, som er beskrevet i denne samarbejdsaftale.</p>
                <p>1.3 Som modydelse for at stille platformen til rådighed modtager Go Go Garden et honorar fra kunden.</p>
            </div>

            <div className={classes.section}>
                <h2>2. Parternes samarbejde</h2>
                <p>2.1 Go Go Garden og partneren er selvstændige og uafhængige parter.</p>
                <p>2.2 Partneren skal godkendes af Go Go Garden forinden samarbejdet påbegyndes.</p>
                <p>2.3 Som led i ansøgningen om at samarbejde med Go Go Garden, skal partneren erklære, at denne har ren straffeattest.</p>
                <p>2.4 Hvor der er mere end én ansat ved partneren eller partneren medbringer andre medhjælpere ifm. en opgave, gælder alle krav i samarbejdsaftalen også for disse. Det er partnerens ansvar, at eventuelle ansatte eller medhjælpere stedse opfylder kravene i samarbejdsaftalen.</p>
            </div>

            <div className={classes.section}>
                <h2>3. Opgaver</h2>

                <div className={classes.subSection}>
                    <h3>3.1 Bestillings- og tilbudsproces</h3>
                    <p>3.1.1 Når en kunde bestiller en ydelse, herefter kaldet en “opgave”, tilbydes denne til en eller flere partnere.</p>
                    <p>3.1.2 I det tilfælde, at Go Go Garden vurderer, at en opgave ikke længere er relevant for en partner, f.eks. hvis kunden har afbestilt eller en anden havemand har accepteret opgaven, fjernes denne fra partnerens side på <a href="/" target="_blank" rel="noreferrer">web.gogogarden.dk</a>.</p>
                    <p>3.1.3 Partneren er ikke forpligtet til at påtage sig opgaver fra platformen, om end disse skal besvares. Alle opgaver der tilbydes til en partner skal besvares hurtigst muligt, men senest inden for 24 timer.</p>
                    <p>3.1.4 Ved accept af en opgave, skal partneren kontakte kunden inden for 24 timer med henblik på at aftale tidspunkt for udførsel af ydelsen samt at rådgive kunden om opgaven, brug af redskaber og pris.</p>
                    <p>3.1.5 Når kontakten mellem en partner og en kunde er formidlet, skal alle aftaler omkring en ydelse indgås direkte mellem partneren og kunden. Således er oplysningerne i kundens bestilling ikke bindende, men kan ændres efter aftale imellem partneren og kunden.</p>
                    <p>3.1.6 Go Go Garden forbeholder sig retten til diskretionært at fjerne tilbudte samt igangværende opgaver fra partnerens side på <a href="/" target="_blank" rel="noreferrer">web.gogogarden.dk</a>, f.eks. hvis kunden identificeres som dårlig betaler. I så fald informeres partneren herom og partneren må ikke længere levere ydelser til kunden.</p>
                </div>

                <div className={classes.subSection}>
                    <h3>3.2 Opgaveinformationer</h3>
                    <p>3.2.1 Partneren kan tilgå alle relevante informationer omkring en aktuel opgave via platformen.</p>
                    <p>3.2.2 Ydelser skal udføres i henhold til de instrukser kunden giver, idet partneren dog skal rådgive kunden hvis ydelsen rent fagligt kan udføres bedre.</p>
                    <p>3.2.3 Partneren må alene acceptere en opgave hvis denne har de fornødne kompetencer samt eventuelle tilladelser til at levere en håndværksmæssig korrekt ydelse.</p>
                    <p>3.2.4 Af hensyn til ansvar og kompleksiteten forbundet hermed må der ikke udføres træfældningsopgaver for kunderne.</p>
                </div>

                <div className={classes.subSection}>
                    <h3>3.3 Anvendelse af eget udstyr og kundernes udstyr</h3>
                    <p>3.3.1 Ved første kontakt til en kunde skal partneren aftale med kunden om kunden eller partneren skal stille udstyr til rådighed og i hvilket omfang. Skal partneren stille udstyr til rådighed skal kunden informeres om at dette medfører forhøjet pris for ydelsen.</p>
                    <p>3.3.2 Stiller kunden selv det fornødne udstyr til rådighed for partneren skal udstyret behandles ordentligt og tilbageleveres i samme stand som det er udleveret i. Ansvaret for udleveret udstyr påhviler partneren.</p>
                    <p>3.3.3 Kunderne kan, mod en merpris, bestille ydelser som kræver, at partneren medbringer udstyr. Opgaver, hvor det fremgår af kundens bestilling, at de kræver særligt udstyr, må alene accepteres hvis partneren er i besiddelse af det påkrævede udstyr samt eventuelle tilladelser til anvendelse heraf. Partneren er selv ansvarlig for eventuel skade påført eget udstyr.</p>
                    <p>3.3.4 Der kan kun kræves forhøjet pris for den faktiske tid eget udstyr anvendes. Der må ikke opkræves forbrugsudgifter (slid, benzin, klinger og lign.) for anvendelse af eget udstyr, da dette er dækket af den forhøjede pris, medmindre andet er aftalt med kunden.</p>
                </div>

                <div className={classes.subSection}>
                    <h3>3.4 Afrapportering</h3>
                    <p>3.4.1 Når en partner og en kunde har indgået en aftale om udførsel af ydelsen, skal denne aftale hurtigst muligt registreres på <a href="/" target="_blank" rel="noreferrer">web.gogogarden.dk</a>, herunder den aftalte dato for udførsel af ydelsen og øvrige relevante oplysninger.</p>
                    <p>3.4.2 Partneren skal hurtigst muligt efter afslutningen af en ydelse registrere tidsforbrug og øvrige relevante oplysninger vedr. ydelsen på <a href="/" target="_blank" rel="noreferrer">web.gogogarden.dk</a>. På baggrund af informationerne i denne tidsregistrering udstedes en faktura til kunden på vegne af partneren.</p>
                    <p>3.4.3 Partneren må ikke omgå de på platformen fastsatte timepriser ved aftale med kunden. Eventuelle aftaler med kunden om en fast pris skal således ske ud fra de for kunden fastsatte timepriser. Overtrædelse medfører eksklusion fra platformen.</p>
                </div>

            </div>

            <div className={classes.section}>
                <h2>4. Profilsiden</h2>

                <div className={classes.subSection}>
                    <h3>4.1 Geografisk område</h3>
                    <p>4.1.1 Partneren skal på profilsiden på <a href="/profile" target="_blank" rel="noreferrer">web.gogogarden.dk</a> angive i hvor stor radius fra partnerens angivne adresse denne tilbyder sine ydelser.</p>
                    <p>4.1.2 Alle ydelser udføres på en adresse anvist af kunden, typisk kundens hjem, fritidshus eller erhvervsejendom. Partneren vil udelukkende blive tilbudt relevante opgaver inden for den angivne radius.</p>
                </div>

                <div className={classes.subSection}>
                    <h3>4.2 Ydelser og redskaber</h3>
                    <p>4.2.1 Partneren skal på profilsiden angive hvilke typer ydelser vedkommende ønsker at tilbyde og hvilke redskaber vedkommende kan medbringe.</p>
                    <p>4.2.2 På baggrund af de oplysninger partneren angiver på profilsiden, vil partneren blive tilbudt opgaver, som det ud fra kundens bestilling vurderes, at vedkommende er i stand til at udføre.</p>
                </div>

                <div className={classes.subSection}>
                    <h3>4.3 Status</h3>
                    <p>4.3.1 Partneren skal på profilsiden angive sin status som værende “på pause”, hvis partneren i en periode ikke er i stand til at udføre ydelser. Partneren vil ikke blive tilbudt opgaver, når denne status er slået til.</p>
                    <p>4.3.2 Det er partnerens eget ansvar at ændre sin status til “aktiv”, når partneren gerne vil modtage opgaver igen.</p>
                </div>
            </div>

            <div className={classes.section}>
                <h2>5. Aflysning og ændring af aftaler</h2>

                <div className={classes.subSection}>
                    <h3>5.1 Partnerens aflysning</h3>
                    <p>5.1.1 Aflysning eller ændring af en aftale med kunden skal ske straks det står partneren klart, at denne ikke kan levere ydelsen på det aftalte tidspunkt.</p>
                    <p>5.1.2 Partneren skal selv kontakte kunden og evt. ombooke med kunden til et andet tidspunkt. Ændringen skal af partneren registreres på platformen.</p>
                    <p>5.1.3 Ved egen aflysning, udeblivelse eller betydelig forsinkelse modtager partneren intet honorar for den aflyste ydelse. Det anses som udeblivelse hvis en ydelse ikke færdiggøres.</p>
                </div>

                <div className={classes.subSection}>
                    <h3>5.2 Kundens aflysning</h3>
                    <p>5.2.1  Aflyser kunden en opgave i henhold til Go Go Gardens handelsbetingelser, har partneren ikke krav på honorar for den aflyste ydelse.</p>
                </div>

            </div>

            <div className={classes.section}>
                <h2>6. Prissætning</h2>
                <p>6.1 Ydelser leveret gennem platformen afregnes i henhold til Go Go Gardens enhver tid gældende prispolitik, der vedlægges som bilag 1. Partneren accepterer at levere ydelser i henhold til den fastsatte prispolitik. Det er partnerens ansvar at holde sig ajourført med de gældende timesatser for kunder.</p>
                <p>6.2 Partneren må alene registrere effektiv arbejdstid. Transport til og fra kunder og mundtlig samt skriftlig kontakt med kunder anses ikke som effektiv arbejdstid. Medmindre det er aftalt med kunden må der ikke opkræves betaling for besigtigelse af opgaver.</p>
                <p>6.3 Prisændringer i partnerens favør træder i kraft øjeblikkeligt og for alle fremtidige tidsregistreringer. Alle andre prisændringer er reguleret af punkt 19.</p>
            </div>

            <div className={classes.section}>
                <h2>7. Honorar til Go Go Garden</h2>
                <p>7.1 Honorar til Go Go Garden betales af kunden.</p>
                <p>7.2 Hvis Go Go Garden kører markedsføringskampagner eller sælger klippekort, kan en kunde modtage rabat ift. den fastsatte pris. En sådan rabat afholdes altid af Go Go Garden og vil ikke have indflydelse på partnerens honorar.</p>
            </div>

            <div className={classes.section}>
                <h2>8. Udgifter</h2>
                <p>8.1 Partneren bærer selv alle omkostninger forbundet med ydelserne, herunder transport, forbrugsudgifter og slitage på eget udstyr.</p>
            </div>

            <div className={classes.section}>
                <h2>9. Sygdom og ferie</h2>
                <p>9.1 Partneren afholder selv udgifter til dennes ferie, barsel, sygdom eller andet fravær.</p>
                <p>9.2 Går partneren på ferie, er det dennes ansvar at håndtere sine aktuelle kunder og opgaver hensigtsmæssigt. Partneren skal stadig leve op til sine forpligtelser over for sine kunder. Partneren kan derfor med fordel sætte sig selv på pause, så denne ikke tilbydes nye kunder under ferien.</p>
            </div>

            <div className={classes.section}>
                <h2>10. Betalinger</h2>
                <p>10.1 Go Go Garden er ansvarlige for opkrævning og modtager betaling på vegne af partneren. Partneren må ikke modtage betaling direkte fra kunderne.</p>
                <p>10.2 Partneren accepterer, at Go Go Garden udsender faktura til kunden på vegne af partneren. Partneren og Go Go Garden fakturerer kunden samlet under én faktura til kunden.</p>
                <p>10.3 Partneren skal selv afregne udlæg direkte over for kunden. Partneren må ikke have fortjeneste på udlæg.</p>

                <div className={classes.subSection}>
                    <h3>10.4 Optjening og udbetaling af honorar</h3>
                    <p>10.4.1 Partnerens honorar optjenes, når Go Go Garden modtager betaling fra kunden. Udbetaling af honorar for udført arbejde er således betinget af, at kunden har betalt den faktura, som er udstedt på baggrund af partnerens tidsregistrering.</p>
                    <p>10.4.2 Partneren kan til enhver tid finde en saldo på sit optjente honorar på profilsiden. Saldoen opdateres dagligt.</p>
                    <p>10.4.3 Partnerens honorar udbetales på partnerens foranledning til en dansk bankkonto anvist af partneren. Partneren anmoder om udbetaling af honorar via profilsiden. Honoraret udbetales senest inden for 5 bankdage.</p>
                </div>

                <div className={classes.subSection}>
                    <h3>10.5 Betalingsrestancer</h3>
                    <p>10.5.1 Ved delvis betaling af en faktura fordeles betalingen først til partneren og herefter til Go Go Garden. Partneren vil således få mulighed for at få udbetalt optjent honorar for udført arbejde, selvom fakturaen ikke er fuldt betalt.</p>
                    <p>10.5.2 I tilfælde af, at en faktura udstedt på partnerens vegne ikke betales rettidigt, underretter Go Go Garden partneren herom.</p>
                    <p>10.5.3 Partneren kan til enhver tid tjekke betalingsstatus på en faktura via platformen.</p>
                    <p>10.5.4 Opnås der ikke betaling fra kunden, sikrer Go Go Garden at der bliver ført udenretslig inkassoproces for den pågældende faktura. Dette sker uden ekstra omkostninger for partneren, idet gebyrer og renter, der pålægges kunden, tilfalder Go Go Garden for at dække inkassoomkostningerne.</p>
                </div>

            </div>

            <div className={classes.section}>
                <h2>11. Skat og afgifter</h2>
                <p>11.1 Alle priser som oplyses til kunderne er inklusive moms.</p>
                <p>11.2 Hvis partneren har tjent mere end 50.000 kr. på 12 fortløbende måneder og/eller partnerens ydelser er momspligtige er partneren forpligtet til at lade sig momsregistrere og orientere Go Go Garden herom.</p>
                <p>11.3 Go Go Garden er forpligtet til at indsamle, verificere og rapportere relevante skatteoplysninger om partneren og dennes indtægter på platformen til SKAT, jf. DAC7-direktivet.</p>
                <p>11.4 Hvis partneren ikke har CVR-nr, gives der ved accept af samarbejdsaftalen samtykke til, at Go Go Garden videregiver partnerens CPR-nr. til kunder i forbindelse med udstedelse af fakturaer, samt til offentlige myndigheder hvor dette følger af præceptiv lovgivning.</p>
            </div>

            <div className={classes.section}>
                <h2>12. Reklamationer/klager</h2>
                <p>12.1 Reklamationer vedrørende ydelsen videresendes til partneren, som er ansvarlig for at håndtere sagen. Reklamationer vedrørende administrationen håndteres af Go Go Garden.</p>

                <div className={classes.subSection}>
                    <h3>12.2 Rabat til kunder</h3>
                    <p>12.2.1 Det afgøres diskretionært af partneren om en utilfreds kunde har krav på hel eller delvis rabat på den samlede pris for den udførte ydelse. En sådan rabat skal gives ud fra de fastsatte timepriser, dvs. ved at revidere den oprindelige tidsregistrering.</p>
                    <p>12.2.2 Go Go Garden kan dog diskretionært og for egen regning vælge at kompensere kunden, hvis Go Go Garden vurderer, at dette er hensigtsmæssigt.</p>
                </div>
            </div>

            <div className={classes.section}>
                <h2>13. Immaterielle rettigheder</h2>
                <p>13.1 Alle rettigheder til konceptet tilhører Go Go Garden, og partneren må ikke markedsføre sig som værende en del af konceptet i videre omfang end hvad der følger af samarbejdsaftalen.</p>
                <p>13.2 Partneren er ikke berettiget til at anvende varemærket ”Go Go Garden” i sin markedsføring af partneren.</p>
                <p>13.3 Platformen må alene anvendes i forbindelse med brug af Go Go Garden-konceptet. Enhver brug af platformen til egne, ikke Go Go Garden-relaterede formål medfører eksklusion fra platformen.</p>
            </div>

            <div className={classes.section}>
                <h2>14. Handelsbetingelser</h2>
                <p>14.1 Partneren erklærer at være bekendt med Go Go Gardens almindelige <a href="https://gogogarden.dk/handelsbetingelser" target="_blank" rel="noreferrer">handelsbetingelser</a>, og erkender ikke at kunne få bedre ret over for Go Go Garden end Go Go Garden har over for kunderne.</p>
                <p>14.2 Partneren er løbende forpligtet til at gøre sig bekendt med senere opdatering af handelsbetingelserne, som altid vil fremgå af platformen. Go Go Garden vil gøre partneren bekendt med ændringer i handelsbetingelser pr. email eller på platformen.</p>
            </div>

            <div className={classes.section}>
                <h2>15. Ansvar</h2>
                <p>15.1 Partneren er i enhver henseende, herunder økonomisk, ansvarlig over for kunderne for den udførte ydelse. Partneren skal friholde Go Go Garden for ethvert krav denne måtte blive mødt med som følge af partnerens skadevoldende adfærd.</p>
            </div>

            <div className={classes.section}>
                <h2>16. Forsikring</h2>
                <p>16.1 Go Go Garden er ikke ansvarlig for eventuelle skader eller tab, partneren måtte blive påført i forbindelse med samarbejdet, når sådanne skader kan henføres til udførelsen af opgaver.</p>
                <p>16.2 Det anbefales, at partneren tegner forsikring, med passende dækning, der dækker udførsel af ydelserne.</p>
            </div>

            <div className={classes.section}>
                <h2>17. Loyalitetsforpligtelse</h2>
                <p>17.1 Parterne skal gensidigt informere hinanden om forhold af betydning for samarbejdet, herunder forhold vedrørende kunden eller anden tredjemand.</p>
                <p>17.2 Over for kunderne skal partneren optræde loyalt over for Go Go Garden og undlade at markedsføre egen virksomhed eller tredjemand.</p>
                <p>17.3 Rettigheder og pligter i medfør af denne aftale kan ikke overdrages til tredjemand uden den anden parts samtykke. Go Go Garden kan dog overdrage dennes rettigheder efter samarbejdsaftalen til tredjemand ved salg af Go Go Gardens væsentligste aktiver til tredjemand.</p>
            </div>

            <div className={classes.section}>
                <h2>18. Fortrolighed</h2>
                <p>18.1 Partneren må ikke viderebringe, benytte eller sætte andre i stand til at benytte Go Go Gardens erhvervshemmeligheder eller enhver anden oplysning omkring Go Go Garden uanset om disse er offentligt kendte eller ej. Partneren må tilsvarende heller ikke forsøge at skaffe sig adgang til Go Go Gardens erhvervshemmeligheder.</p>
                <p>18.2 Det præciseres at samarbejdsaftalen med bilag, interne instrukser og enhver korrespondance mellem Go Go Garden og partneren samt mellem partneren og dennes kunder anses som fortrolig.</p>
            </div>

            <div className={classes.section}>
                <h2>19. Ændringer af samarbejdsaftalen</h2>
                <p>19.1 Go Go Garden kan ændre samarbejdsaftalen med 7 dages skriftligt varsel, med undtagelse af ændringer af partnerens honorar, jf. punkt 6.3.</p>
                <p>19.2 Partneren vil blive informeret om ændringer via e-mail. Fortsat anvendelse af platformen anses også at udgøre accept af den ændrede udgave af samarbejdsaftalen. Kan partneren ikke acceptere ændringerne må denne opsige samarbejdsaftalen, jf. punkt 20.</p>
            </div>

            <div className={classes.section}>
                <h2>20. Opsigelse</h2>
                <p>20.1 Samarbejdsaftalen kan opsiges af parterne med øjeblikkelig varsel. Opsigelse af samarbejdsaftalen påvirker dog ikke de bestemmelser, som efter deres natur har virkning efter samarbejdsaftalens opsigelse.</p>
                <p>20.2 Ved opsigelse udbetaler Go Go Garden optjent honorar efter sædvanlig praksis, jf. punkt 10.3. Reklamationer vedrørende administrationen håndteres fortsat af Go Go Garden, jf. punkt 12.1.</p>
                <p>20.3 Ved opsigelse forventes det at partneren håndterer sine kunder og afslutter aktuelle opgaver hensigtsmæssigt.</p>
            </div>

            <div className={classes.section}>
                <h2>21. Forholdet efter ophør</h2>
                <p>21.1 Umiddelbart efter ophør af samarbejdsaftalen skal partneren ophøre med at anvende konceptet, herunder forretningskendetegn som tilhører Go Go Garden.</p>
                <p>21.2 Umiddelbart efter ophør af samarbejdsaftalen slettes partnerens profil på platformen, med mindre der er et udestående mellem partneren og Go Go Garden, f.eks. et udestående honorar. I så fald slettes partnerens profil efter endt udestående.</p>
            </div>

            <div className={classes.section}>
                <h2>22. Lovvalg og Værneting</h2>
                <p>22.1 Parterne er enige om, at eventuelle tvister skal forsøges løst i mindelighed. Såfremt en mindelig løsning ikke kan findes, skal tvisten afgøres efter dansk ret og med retten i Aarhus som rette værneting.</p>
            </div>

            <div className={classes.section}>
                <h2>23. Accept af samarbejdsaftalen</h2>
                <p>23.1 Samarbejdsaftalen accepteres ved at trykke på knappen “Accepter samarbejdsaftale”. Fortsat anvendelse af platformen anses også at udgøre accept af samarbejdsaftalen.</p>
            </div>

            <div className={classes.section}>
                <h2>Bilag 1 - Prispolitik</h2>

                <div className={classes.subSection}>
                    <h3>Partnerens timesatser (ikke momsregistreret)</h3>

                    <p>
                        Honorar for haveservice med kundens haveredskaber:<br />
                        {formatPrice(gardenerPrices.customerTools)} pr. time
                    </p>

                    <p>
                        Honorar for haveservice med partnerens egne haveredskaber:<br />
                        {formatPrice(gardenerPrices.gardenerTools)} pr. time
                    </p>

                    <p>
                        Honorar for haveservice med partnerens egne store haveredskaber:<br />
                        {formatPrice(gardenerPrices.gardenerLargeTools)} pr. time
                    </p>

                </div>

                <div className={classes.subSection}>
                    <h3>Partnerens timesatser (momsregistreret)</h3>

                    <p>
                        Honorar for haveservice med kundens haveredskaber:<br />
                        {formatPrice(gardenerPricesIfVatLiable.customerTools)} pr. time inkl. moms
                    </p>

                    <p>
                        Honorar for haveservice med partnerens egne haveredskaber:<br />
                        {formatPrice(gardenerPricesIfVatLiable.gardenerTools)} pr. time inkl. moms
                    </p>

                    <p>
                        Honorar for haveservice med partnerens egne store haveredskaber:<br />
                        {formatPrice(gardenerPricesIfVatLiable.gardenerLargeTools)} pr. time inkl. moms
                    </p>

                </div>

                <div className={classes.subSection}>
                    <h3>Kundens timesatser</h3>
                    <p>Kundens aktuelle timesatser kan til enhver tid findes på <a href="https://gogogarden.dk" target="_blank" rel="noreferrer">www.gogogarden.dk</a>. Vær opmærksom på, at der kan forekomme rabatter for den enkelte kunde.</p>
                </div>

            </div>

        </Paper>
    );
});

const formatPrice = (price: number): string => {
    if ( price % 1 === 0 ) return `${price} kr.`;

    const value = price.toFixed(2)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    return `${value} kr.`;
}
