import { PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: "256px",
        flexShrink: 0,

        [theme.breakpoints.down(925)]: {
            display: "none",
        },
    },
    sidebar: {
        position: "fixed",
        width: "inherit",
        top: 0,
        left: 0,
        bottom: 0,
    },
    separator: theme.mixins.toolbar,
}));

export const Sidebar = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.sidebar}>
                <div className={classes.separator} />

                {props.children}

            </div>
        </div>
    );
};
