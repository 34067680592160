import firebase from "firebase/app";
import { useEffect, useState } from "react";

import { AgreementDocument } from "./agreement.model";

export const useLatestAgreement = (gardenerId: string | null | undefined) => {
    const [state, setState] = useState<AgreementDocument | null>();
  
    useEffect(() => {
        if ( gardenerId === undefined ) return setState(undefined);
        if ( gardenerId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore()
            .collection("agreements")
            .where("helperId", "==", gardenerId)
            .orderBy("version", "desc")
            .limit(1)
            .onSnapshot(snapshot => {
                if ( snapshot.empty ) return setState(null);

                const document = snapshot.docs[0];
                const agreement = { id: document.id, ...document.data() } as AgreementDocument;

                setState(agreement);
            });

    }, [gardenerId]);
  
    return state;
};
