import { makeStyles } from "@material-ui/core";

import { PropsWithChildren } from "react";

const useStyles = makeStyles({
    root: {
        display: "flex",
        justifyContent: "center",
    },
    container: {
        display: "grid",
        gridAutoFlow: "column",
        gridAutoColumns: "1fr",
        backgroundColor: "#FFFFFF",
        padding: "4px",
        borderRadius: "24px",
    },
    disabled: {
        "& > *": {
            cursor: "not-allowed !important",
            opacity: 0.5,
        }
    },
    active: {
    }
});

interface SwitchProps {
    disabled?: boolean;
}

export const Switch = (props: PropsWithChildren<SwitchProps>) => {
    const classes = useStyles();

    const stateClass = props.disabled ? classes.disabled : classes.active;

    return (
        <div className={classes.root}>

            <div className={`${classes.container} ${stateClass}`}>
                {props.children}
            </div>

        </div>
    );
};
