import { useEffect, useState } from "react";

import { makeStyles, FormHelperText, Collapse } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        padding: "0 16px",
        fontSize: "14px",
        fontWeight: 500,
    },
});

interface ErrorProps {
    message: string | undefined;
    animate: boolean;
}

export const Error = (props: ErrorProps) => {
    const { message, animate } = props;

    const classes = useStyles();

    const [retainedMessage, setRetainedMessage] = useState(message);

    useEffect(() => {
        if ( !message ) return;

        setRetainedMessage(message);
    }, [message]);

    return (
        <Collapse in={Boolean(message)} timeout={animate ? 200 : 0}>
            <FormHelperText error className={classes.root}>
                {retainedMessage}
            </FormHelperText>
        </Collapse>
    );
};
