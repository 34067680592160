import React, { useCallback } from "react";

import { makeStyles, Slider as MaterialSlider, SliderProps as MaterialSliderProps, ValueLabelProps } from "@material-ui/core";

const useLabelStyles = makeStyles({
    active: {
        color: "#1C5B13",
    },
    disabled: {},
});

const Label = (props: ValueLabelProps) => {
    const classes = useLabelStyles();

    const { children, value, className } = props;

    const disabled = Boolean((props as any).disabled);
    const stateClass = disabled ? classes.disabled : classes.active;

    return React.cloneElement(children, {
        children: <span className={`${stateClass} ${className}`}>{value}</span>
    });
}

const useStyles = makeStyles({
    containerClass: {
        padding: "32px 12px 16px",
    },

    root: {
        height: 8,
        padding: 0,
        color: "#2FA52B",

        "&$disabled": {
            color: "#818181",
        },
    },
    thumb: {
        "&, &$disabled": {
            height: 24,
            width: 24,
            backgroundColor: "#fff",
            border: "2px solid currentColor",
            marginTop: -8,
            marginLeft: -12,
        },

        "&:focus, &:hover, &$active, &$disabled": {
            boxShadow: "inherit",
        },
    },
    track: {
        height: 8,
        borderRadius: 4,
        paddingLeft: "12px",
        marginLeft: "-12px",
    },
    rail: {
        height: 8,
        borderRadius: 4,
        paddingLeft: "12px",
        marginLeft: "-12px",
        paddingRight: "12px",
        marginRight: "-12px",
    },
    valueLabel: {
        position: "absolute",
        bottom: "100%",
        left: "50%",
        transform: "translateX(-50%)",
        fontSize: "14px",
        fontWeight: 600,
        whiteSpace: "nowrap",
        backgroundColor: "transparent",
        padding: "4px 8px",
        margin: "4px 0",
    },
    active: {},
    disabled: {},
});

interface SliderProps extends Omit<MaterialSliderProps, "value" | "onChange"> {
    value: number;
    onChange?: (value: number) => void;
}

export const Slider = (props: SliderProps) => {
    const { containerClass, ...classes } = useStyles();

    const { value, onChange, ...otherProps } = props;

    const changeValue = useCallback((_: any, newValue: number | number[]) => {
        onChange!(newValue as number);
    }, [onChange]);

    return (
        <span className={containerClass}>
            <MaterialSlider
                ValueLabelComponent={Label}
                value={value}
                onChange={onChange ? changeValue : undefined}
                valueLabelDisplay="on"
                classes={classes}
                {...otherProps}
            />
        </span>
    );
};
