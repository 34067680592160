import { useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { LinearProgress } from "../../application/LinearProgress";
import { AppointmentFollowUpTaskDocument } from "../../task/task.model";
import { ErrorPage } from "../../application/ErrorPage";
import { useTranslation } from "../../internationalisation/translation.hook";
import { AppointmentTaskDetailPage } from "./AppointmentTaskDetailPage";
import { TaskResponseData, useTaskResponseAction } from "../../task/task-response-action.hook";
import { useRegistrationHistory } from "../../registration/overview/registrations.hook";
import { useGardeningTask } from "../../gardening-task/gardener-view/gardening-tasks-gardener-view.hook";

export interface IntegratedAppointmentTaskDetailPageProps {
    title: JSX.Element;
    task: AppointmentFollowUpTaskDocument;
}

export const IntegratedAppointmentTaskDetailPage = (props: IntegratedAppointmentTaskDetailPageProps) => {
    const { title, task } = props;
    const { t } = useTranslation();

    const gardeningTask = useGardeningTask(task.gardenerId, task.gardeningTaskId);

    const history = useHistory();
    const redirect = useCallback((action: TaskResponseData["action"]) => {
        switch ( action ) {
            case "create appointment": return history.push(`/overview/redirects/appointment-task/${task.customerId}?status=performed`);
            case "reject task because gardener could not find date with customer": return history.push(`/overview/redirects/appointment-task/${task.customerId}?status=deleted`);
            case "reject task because gardener says customer does not need help": return history.push(`/overview/redirects/appointment-task/${task.customerId}?status=deleted`);
            case "reject task because gardener says customer might need help": return history.push(`/overview/redirects/appointment-task/${task.customerId}?status=deleted`);
            case "reject task because gardener could not help customer": return history.push(`/overview/redirects/appointment-task/${task.customerId}?status=deleted`);
            case "register contact attempt": return history.push(`/overview/redirects/appointment-task/${task.customerId}?status=deleted&date=future`);
            case "postpone contact": return history.push(`/overview/redirects/appointment-task/${task.customerId}?date=future`);
        }
    }, [history, task]);
    const taskResponseAction = useTaskResponseAction(redirect);

    const now = useRef(moment().toISOString(true)).current;

    const registrationHistory = useRegistrationHistory(task.gardenerId, task.customerId, now);

    if ( gardeningTask === null || registrationHistory === null ) return <ErrorPage message={t("OverviewTaskDetailPage: task-could-not-be-found")} />;
    if ( gardeningTask === undefined || registrationHistory === undefined ) return <LinearProgress />;

    return (
        <>
            {title}

            <AppointmentTaskDetailPage
                task={task}
                gardeningTask={gardeningTask}
                hasHistory={registrationHistory.hasHistory}
                hasRecentHistory={registrationHistory.hasRecentHistory}
                now={now}
                taskResponseAction={taskResponseAction}
            />
        </>
    );
};
