import { PersonalInformationDocument } from "../personal-information.model";
import { GardenerBalanceDocument } from "./gardener-balance.model";
import { useRequestPayoutAction } from "./request-payout.hook";
import { CommissionPayoutCard } from "./CommissionPayoutCard";
import { useRequestPayoutDialog } from "./RequestPayoutDialog";
import { GardenerPayoutDocument } from "./gardener-payout.model";

interface CommissionPayoutCardWrapperProps {
    gardenerBalance: GardenerBalanceDocument;
    payout: GardenerPayoutDocument | null;
    personalInformation: PersonalInformationDocument;
}

export const CommissionPayoutCardWrapper = (props: CommissionPayoutCardWrapperProps) => {
    const { gardenerBalance, payout, personalInformation } = props;
    const requestPayoutAction = useRequestPayoutAction();
    const openRequestPayoutDialog = useRequestPayoutDialog(requestPayoutAction);

    return (
       <CommissionPayoutCard
            payoutInProgress={gardenerBalance.inProgress}
            amountScheduled={payout?.amount ?? null}
            balance={gardenerBalance.balance}
            hasBankAccount={Boolean(personalInformation.bankAccount)}
            onRequestPayout={openRequestPayoutDialog}
        />
    );
};
