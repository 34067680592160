import { makeStyles } from "@material-ui/core";

import { GreenButton, WhiteButton } from "../../new-button";
import { PurpleButton } from "../../button/PurpleButton";
import { MouseEventHandler, useCallback } from "react";

const useStyles = makeStyles({
    root: {
        display: "flex",
        justifyContent: "center",
    },
    wrapper: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        padding: "8px 0",

        "& > *": {
            flexBasis: "50%",
            marginRight: "24px",
        },

        "& > *:last-child": {
            marginRight: 0,
        },
    },
});

interface HorizontalButtonsProps {
    color: "green" | "purple";
    buttonTexts: string[];
    selectedButtonIndexes: number[];
    onSelect: (selectedButtonIndex: number | null) => void;
}

export const HorizontalButtons = (props: HorizontalButtonsProps) => {
    const classes = useStyles();
    const { color, buttonTexts, selectedButtonIndexes, onSelect } = props;

    const selectButton = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
        const buttonText = event.currentTarget.textContent ?? "";
        const newSelectedButtonIndex = buttonTexts.indexOf(buttonText) !== -1 ? buttonTexts.indexOf(buttonText) : null;

        onSelect(newSelectedButtonIndex);
    }, [onSelect, buttonTexts]);

    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>

                {buttonTexts.map((buttonText, index) => {
                    if ( selectedButtonIndexes.includes(index) && color === "green" ) return <GreenButton key={index} onClick={selectButton}>{buttonText}</GreenButton>;
                    if ( selectedButtonIndexes.includes(index) && color === "purple" ) return <PurpleButton key={index} onClick={selectButton}>{buttonText}</PurpleButton>;

                    return <WhiteButton key={index} onClick={selectButton}>{buttonText}</WhiteButton>;
                })}

            </div>
        </div>
    );
};
