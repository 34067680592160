import { useCallback } from "react";

import firebase from "firebase/app";

import { useBlockingProgress } from "../components/blocking-progress";
import { useSnackbar } from "../components/snackbar";
import { useTranslation } from "../internationalisation/translation.hook";

export type NewTaskResponseData = AcceptTaskData | DeclineTaskData;

interface AcceptTaskData {
    action: "accept";
    gardeningTaskId: string;
}

interface DeclineTaskData {
    action: "decline";
    gardeningTaskId: string;
    reasons: DeclineTaskReason[];
    comment: string | null;
}

export type DeclineTaskReason = "do-not-understand" | "do-not-have-time" | "cannot-perform" | "too-far-away-or-too-small" | "other";

const newTaskResponse: (data: NewTaskResponseData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Match-newTaskResponse");

export const useNewTaskResponseAction = (callback?: () => void) => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return useCallback((data: NewTaskResponseData) => {
        block();

        newTaskResponse(data)
            .then(() => {
                openSnackbar("success", t("CurrentTasksTab: task-response-success-message"));
                unblock();
                if ( callback ) callback();
            })
            .catch(() => {
                openSnackbar("error", t("CurrentTasksTab: task-response-error-message"));
                unblock();
            });

    }, [block, unblock, openSnackbar, t, callback]);
};
