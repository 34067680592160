import { useEffect, useRef, useState } from "react";

import firebase from "firebase/app";

import { InvoiceDocument } from "./invoice.model";

export const useInvoices = (gardenerId: string, customerIds: string[] | undefined): InvoiceDocument[] | undefined => {
    const [state, setState] = useState<InvoiceDocument[]>();

    const lastId = useRef("initiated");

    useEffect(() => {
        const callId = firebase.firestore().collection("x").doc().id;
        lastId.current = callId;

        if ( customerIds === undefined ) return setState(undefined);

        setState(undefined);

        Promise.all(customerIds.map(async customerId => {
            const snapshot = await firebase.firestore()
                .collection("invoices")
                    .where("gardener.id", "==", gardenerId)
                    .where("customer.id", "==", customerId)
                .get();

            if ( snapshot.docs.length === 0 ) return null;

            const document = snapshot.docs[0];

            return { id: document.id, ...document.data() } as InvoiceDocument;
        })).then(possiblyMissingInvoices => {
            if ( lastId.current !== callId ) return;

            const invoices = possiblyMissingInvoices.filter(invoice => invoice) as InvoiceDocument[];

            setState(invoices);
        });

        return () => {
            lastId.current = "terminated";
        };
    }, [lastId, gardenerId, customerIds]);

    return state;
};
