import { ButtonProps, makeStyles } from "@material-ui/core";

import { ButtonBase } from "./ButtonBase";

const useStyles = makeStyles({
    root: {
        backgroundColor: "#FFFFFF",
        color: "#4A4A4A",

        "&:hover": {
            backgroundColor: "#EEF4ED",
        },
    },
});

export const LightAndGrayButton = (props: ButtonProps) => {
    const classes = useStyles();

    const { className, ...otherProps } = props;

    return (
        <ButtonBase
            className={className ? `${classes.root} ${className}` : classes.root}
            variant="contained"
            {...otherProps}
        />
    );
};
