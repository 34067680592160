import { Typography, Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    paper: {
        padding: "24px",
        textAlign: "center",
    },
});

interface MessageProps {
    text: string;
}

export const Message = (props: MessageProps) => {
    const classes = useStyles();

    const { text } = props;

    return (
        <Paper className={classes.paper}>
            <Typography>{text}</Typography>
        </Paper>
    );
};
