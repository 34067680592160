import { Button as MaterialButton, ButtonProps, makeStyles } from "@material-ui/core";

const useButtonStyles = makeStyles({
    root: {
        padding: "6px 40px",
        borderRadius: "24px",
        fontSize: "16px",
        fontWeight: 500,
        textTransform: "none",
        minHeight: "44px",
    },
});

export const ButtonBase = (props: ButtonProps) => {
    const classes = useButtonStyles();

    const { className, ...otherProps } = props;

    return (
        <MaterialButton
            className={className ? `${classes.root} ${className}` : classes.root}
            {...otherProps}
        />
    );
};
