import { useCallback } from "react";

import { makeStyles, Snackbar as MaterialSnackbar } from "@material-ui/core";

import { useSnackbar } from "./SnackbarProvider";

const useStyles = makeStyles(theme => ({
    anchorOriginBottomCenter: {
        [theme.breakpoints.up(925)]: {
            left: "calc(50% + 256px)",
            right: "auto",
            bottom: "24px",
            transform: "translateX(calc(-50% - 128px))",
        },
        [theme.breakpoints.up(560)]: {
            minWidth: "544px",
        },
    },
}));

interface SnackbarProps {
    children: React.ReactElement;
}

export const Snackbar = (props: SnackbarProps) => {
    const classes = useStyles();

    const { children } = props;
    const { open, closeSnackbar } = useSnackbar();

    const close = useCallback((_: any, reason?: string) => {
        if ( reason === "clickaway" ) return;

        closeSnackbar();
    }, [closeSnackbar]);

    return (
        <MaterialSnackbar open={open} autoHideDuration={3000} onClose={close} classes={classes}>
            {children}
        </MaterialSnackbar>
    );
}
