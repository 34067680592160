import { AccordionList } from "../components/accordion/AccordionList";
import { AccordionListItem } from "../components/accordion/AccordionListItem";
import { UnorderedList } from "../components/unordered-list/UnorderedList";
import { useTranslation } from "../internationalisation/translation.hook";

export const AppointmentTaskFaq = () => {
    const { t } = useTranslation();

    return (
        <AccordionList>

            <AccordionListItem id="appointment-task-question-1" title={t("AppointmentTaskFaq: question-1")}>
                {t("AppointmentTaskFaq: answer-1")}
            </AccordionListItem>

            <AccordionListItem id="appointment-task-question-2" title={t("AppointmentTaskFaq: question-2")}>
                {t("AppointmentTaskFaq: answer-2")}
            </AccordionListItem>

            <AccordionListItem id="appointment-task-question-3" title={t("AppointmentTaskFaq: question-3")}>
                {t("AppointmentTaskFaq: answer-3")}
            </AccordionListItem>

            <AccordionListItem id="appointment-task-question-4" title={t("AppointmentTaskFaq: question-4")}>
                Det er en del af konceptet, at I kan aftale brugen af taksten “havemandens store redskaber” i særlige tilfælde.<br />
                <br />
                Vi stiller imidlertid nogle krav, da det ofte resulterer i klager:
                <UnorderedList>
                    <li>Kunden skal informeres om sin timepris ved brugen af taksten</li>
                    <li>Du må ikke bruge taksten, hvis ikke det er aftalt på forhånd</li>
                    <li>Brugen af taksten skal begrænses til den tid, hvor det har været nødvendigt</li>
                    <li>Taksten kan selvfølgelig kun anvendes ved brug af eget udstyr</li>
                    <li>Taksten kan selvfølgelig kun anvendes ved brug af relevant udstyr</li>
                </UnorderedList>
                <br />
                Her er nogle retningslinjer i forhold til brugen af taksten:
                <UnorderedList>
                    <li>Brug af græsslåmaskine takseres kun til “havemandens redskaber”.</li>
                    <li>Brug af almindelig trailer takseres kun til “havemandens redskaber”</li>
                    <li>Brug af havetraktor kan takseres til “havemandens store redskaber”</li>
                    <li>Brug af ekstra stor trailer kan takseres til “havemandens store redskaber”</li>
                    <li>Opgaver med ekstrem slitage kan takseres til “havemandens store redskaber”</li>
                    <li>Der kan være flere situationer, hvor det er passende at bruge taksten</li>
                </UnorderedList>
                <br />
                Vi anbefaler, at taksten anvendes, når det giver kunden en økonomisk besparelse eller når du har en ekstra stor udgift.
                
            </AccordionListItem>

            <AccordionListItem id="appointment-task-question-5" title={t("AppointmentTaskFaq: question-5")}>
                Det er en del af konceptet, at du kan medbringe en medhjælper.<br />
                <br />
                Vi stiller imidlertid nogle krav, da det ofte resulterer i klager:
                <UnorderedList>
                    <li>Kunden skal informeres om, at de kan frabede sig det</li>
                    <li>Kunden skal informeres om, at kunden vil blive takseret dobbelt</li>
                    <li>Du må ikke medbringe en medhjælper, hvis ikke det er aftalt på forhånd</li>
                </UnorderedList>
                <br />
                 Vi anbefaler, at du overvejer en ekstra gang, om det er en god idé at medbringe en medhjælper, da det sjældent er i kundens interesse økonomisk.
            </AccordionListItem>

        </AccordionList>
    );
};
