import moment from "moment";

import { NewTaskDetailPage } from "./NewTaskDetailPage";
import { OfferResponseTaskDocument } from "../../task/task.model";
import { useNewTaskResponseAction } from "../../task/new-task-response-action.hook";

export interface IntegratedNewTaskDetailPageProps {
    title: JSX.Element;
    task: OfferResponseTaskDocument;
    goBack: () => void;
}

export const IntegratedNewTaskDetailPage = (props: IntegratedNewTaskDetailPageProps) => {
    const { title, task, goBack } = props;

    const newTaskResponseAction = useNewTaskResponseAction(goBack);
    
    const now = moment().toISOString(true);

    return (
        <>
            {title}

            <NewTaskDetailPage
                task={task}
                now={now}
                newTaskResponseAction={newTaskResponseAction}
            />

        </>
    );
};
