import { HTMLAttributes, PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        marginTop: 0,
        whiteSpace: "pre-wrap",
    },
});

export const DialogParagraph = (props: PropsWithChildren<Pick<HTMLAttributes<HTMLParagraphElement>, "className">>) => {
    const classes = useStyles();

    const { className } = props;

    return (
        <p className={className ? `${classes.root} ${className}` : classes.root}>{props.children}</p>
    );
};
