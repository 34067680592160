import { useMemo } from "react";

import { FormProps } from "../../framework/react/FormProvider";
import { useFormDialog } from "../components/FormDialog";
import { PageFormItem, GroupFormItem, InfoFormItem, TextAreaFormItem } from "../components";

export enum AskForHelpFormIds {
    TextAreaMessage = "text-area-message",
}

export type AskForHelpForm =
    GroupFormItem<[
        InteractionPage,
        ConfirmPage
    ]>

type InteractionPage =
    PageFormItem<GroupFormItem<[
        InfoFormItem,
        TextAreaFormItem,
    ]>>

type ConfirmPage = PageFormItem<InfoFormItem>

export const useAskForHelpDialog = (onConfirm: FormProps<AskForHelpForm>["onSubmit"]) => {

    const form: AskForHelpForm = useMemo(() => {
        const interactionPage: InteractionPage = {
            type: "page",
            title: "Bed om hjælp",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: `
                            Er du i tvivl om noget, vil vi hjertens gerne hjælpe dig med det. Du kan altid komme i kontakt med os via “Bed om hjælp”.<br />
                            <br />
                            Skriv dit spørgsmål her og fortæl os hvad det drejer sig om.
                        `,
                    },
                    {
                        id: AskForHelpFormIds.TextAreaMessage,
                        type: "text-area",
                        label: "Spørgsmål",
                        placeholder: "Gør dit spørgsmål så tydeligt som muligt, så vi kan hjælpe dig bedst muligt.",
                        value: "",
                        dirty: false,
                    }
                ],
            },
        }
    
        const confirmPage: PageFormItem<InfoFormItem> = {
            type: "page",
            title: "Bed om hjælp",
            item: {
                type: "info",
                render: "default",
                text: `
                    Hvis du har bedt om hjælp for at kunne udføre dine opgaver, og du ikke hører fra os med det samme, skal du ikke bekymre dig.<br />
                    <br />
                    Vi glemmer dig ikke og vil vende tilbage til dig hurtigst muligt, senest inden for 2 arbejdsdage.
                `,
            },
        };

        return {
            type: "group",
            render: "all at once",
            items: [interactionPage, confirmPage],
        };
    }, []);

    return useFormDialog(form, onConfirm);
};
