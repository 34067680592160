import { useCallback, useMemo, useState } from "react";

import { Container } from "@material-ui/core"
import moment from "moment";

import { ContentSection } from "../../application/ContentSection"
import { LinearProgress } from "../../application/LinearProgress";
import { useAllMatches } from "../../application/matches.hook";
import { useRegistrations } from "./registrations.hook";
import { SectionHeader } from "../../components/section/SectionHeader";
import { TitleHeader } from "../../components/page/TitleHeader"
import { GardenerDocument } from "../../gardener/gardener.model";
import { RegistrationDocument } from "../registration.model";
import { useTranslation } from "../../internationalisation/translation.hook";
import { VerticalButtons, WhiteButton } from "../../components/new-button";
import { RegistrationCard } from "./RegistrationCard";
import { useInvoices } from "../../invoice/invoices.hook";
import { Message } from "../../components/Message";
import { Section } from "../../components/section/Section";
import { FloatingButton } from "../../components/floating-button/FloatingButton";

const useRegistrationsGroupedByDate = (registrations: RegistrationDocument[] | undefined) => {
    return useMemo(() => {
        if ( registrations === undefined ) return undefined;

        const map: { [date: string]: RegistrationDocument[] } = {};
        registrations.forEach(registration => {
            const list = map[registration.date] || [];
            list.push(registration);
            map[registration.date] = list;
        });

        return Object
            .keys(map)
            .map(date => ({
                date,
                registrations: map[date].sort((a, b) => {
                    if ( a.creationDate < b.creationDate ) return -1;
                    if ( a.creationDate > b.creationDate ) return 1;
                    return 0;
                }).reverse(),
            }))
            .sort((a, b) => {
                if ( a.date < b.date ) return -1;
                if ( a.date > b.date ) return 1;
                return 0;
            })
            .reverse();

    }, [registrations]);
};

const useCustomerNameMap = (gardenerId: string, registrations: RegistrationDocument[] | undefined) => {
    const matches = useAllMatches(gardenerId);

    const customerIdsInNeedOfName = useMemo(() => {
        if ( registrations === undefined || matches === undefined ) return undefined;

        const matchCustomerIdSet = new Set(matches.map(match => match.customer.id));
        const uniqueRegistrationCustomerIds = Array.from(new Set(registrations.map(registration => registration.customerId)));

        return uniqueRegistrationCustomerIds.filter(x => !matchCustomerIdSet.has(x));
    }, [registrations, matches]);

    const invoices = useInvoices(gardenerId, customerIdsInNeedOfName);

    return useMemo(() => {
        if ( matches === undefined || invoices === undefined ) return undefined;

        const map: { [customerId: string]: string } = {};
        matches.forEach(match => map[match.customer.id] = match.customer.name);
        invoices.forEach(invoice => map[invoice.customer.id] = invoice.customer.type === "person" ? invoice.customer.name : invoice.customer.companyName);

        return map;
    }, [matches, invoices]);
};

interface RegistrationOverviewPageProps {
    gardener: GardenerDocument;
}

export const RegistrationOverviewPage = (props: RegistrationOverviewPageProps) => {
    const { t } = useTranslation();

    const { gardener } = props;

    const [fromDate, setFromDate] = useState<string | null>(() => {
        return moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
    });

    const registrations = useRegistrations(gardener.id, fromDate, null);
    const registrationGroups = useRegistrationsGroupedByDate(registrations);
    const customerNameMap = useCustomerNameMap(gardener.id, registrations);

    const hasClickedViewAllRegistrations = fromDate === null;
    const viewAllRegistrations = useCallback(() => {
        setFromDate(null);
    }, []);

    const now = useMemo(() => {
        return moment().toISOString(true);
    }, []);

    if ( registrationGroups === undefined || customerNameMap === undefined ) return <LinearProgress />;

    return (
        <>
            <TitleHeader>{t("LeftTabBar: registrations-tab")}</TitleHeader>

            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    {registrationGroups.length === 0 ? (
                        <Section>
                            <Message text={t("RegistrationOverviewPage: no-registrations")} />
                        </Section>
                    ) : null}

                    {registrationGroups.map(group => (
                        <Section key={group.date}>
                            <SectionHeader>{formatDate(group.date)}</SectionHeader>

                            {group.registrations.map(registration => (
                                <RegistrationCard
                                    key={registration.id}
                                    customerName={customerNameMap[registration.customerId] || t("Shared: no-longer-connected")}
                                    registration={registration}
                                    now={now}
                                />
                            ))}
                        </Section>
                    ))}

                    {!hasClickedViewAllRegistrations ? (
                        <FloatingButton>
                            <VerticalButtons>
                                <WhiteButton onClick={viewAllRegistrations}>{t("RegistrationOverviewPage: view-all")}</WhiteButton>
                            </VerticalButtons>
                        </FloatingButton>
                    ) : null}

                </Container>
            </ContentSection>
        </>
    )
};

function capitalise(text: string): string {
    return text.slice(0, 1).toUpperCase() + text.slice(1);
}

function formatDate(date: string): string {
    const formattedDate = moment(date, "YYYY-MM-DD").format("dddd DD MMMM YYYY");

    return capitalise(formattedDate);
}
