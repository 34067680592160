import { makeStyles } from "@material-ui/core";
import { IconButtonBase, IconButtonBaseProps } from "./IconButtonBase";

const useStyles = makeStyles({
    root: {
        color: "#297A1D",
    },
});

export const GreenIconButton = (props: IconButtonBaseProps) => {
    const classes = useStyles();

    const { className, ...otherProps } = props;

    return <IconButtonBase className={className ? `${classes.root} ${className}` : classes.root} {...otherProps} />;
};
