import { PropsWithChildren } from "react";

import { Container, makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles({
    paper: {
        overflow: "hidden",
    },
});

export const Card = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    const { children } = props;

    return (
        <Container maxWidth="sm" disableGutters>
            <Paper className={classes.paper}>

                {children}

            </Paper>
        </Container>
    );
};
