import { useCallback } from "react";

import firebase from "firebase/app";

import { useBlockingProgress } from "../../components/blocking-progress";
import { useSnackbar } from "../../components/snackbar";
import { CreateRegistrationDialogProps } from "./CreateRegistrationDialog";
import { Task } from "../registration.model";
import { useTranslation } from "../../internationalisation/translation.hook";

interface CreateRegistrationWithoutAppointmentData {
    customerId: string;
    serviceDate: string;
    tasks: Task[];
}

const createRegistrationWithoutAppointmentFunction: (data: CreateRegistrationWithoutAppointmentData) => Promise<firebase.functions.HttpsCallableResult>
    = firebase.app().functions("europe-west3").httpsCallable("Registration-createWithoutAppointment");

export const useCreateRegistrationWithoutAppointmentAction = () => {
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const action: CreateRegistrationDialogProps["onConfirm"] = useCallback((viewModel) => {
        block();

        const data: CreateRegistrationWithoutAppointmentData = {
            customerId: viewModel.customer.id,
            serviceDate: viewModel.appointmentDate,
            tasks: viewModel.tasks.map(task => ({
                description: task.description,
                durationInMinutes: task.duration!,
                tools: task.tools!,
            })),
        };

        createRegistrationWithoutAppointmentFunction(data)
            .then(response => {
                if ( response.data === "has-already-scheduled-appointment" ) {
                    openSnackbar("warning", t("CustomerDetailPage: has-already-scheduled-appointment-warning-message"));
                } else {
                    openSnackbar("success", t("CreateRegistrationDialog: create-registration-success-message"));
                }
                unblock();
            })
            .catch(() => {
                openSnackbar("error", t("CreateRegistrationDialog: create-registration-error-message"));
                unblock();
            });

    }, [block, unblock, openSnackbar, t]);

    return action;
};
