import { useCallback, useEffect, useState } from "react";

export const useAnimation = () => {
    const [state, setState] = useState(200);

    useEffect(() => {
        if ( state === 200 ) return;

        setState(200);
    }, [state]);

    const skipAnimation = useCallback(() => {
        setState(0);
    }, []);

    return [state, skipAnimation] as [typeof state, typeof skipAnimation];
};
