import { List, ListProps } from "@material-ui/core";

import { AccordionProvider } from "./AccordionProvider";

export const AccordionList = (props: Omit<ListProps, "disablePadding">) => {
    return (
        <AccordionProvider>
            <List disablePadding {...props} />
        </AccordionProvider>
    );
};
