import firebase from "firebase/app";

const acceptAgreement = firebase.app().functions("europe-west3").httpsCallable("Agreement-accept5");

class AgreementClient {

    public async acceptAgreement(data: { agreementText: string, version: number }): Promise<void> {
        await acceptAgreement(data);
    }

}

const agreementClient = new AgreementClient();

export const useAgreementClient = () => {
    return agreementClient;
};
