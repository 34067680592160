import { MouseEvent, PropsWithChildren, useRef } from "react";

import { makeStyles } from "@material-ui/core";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";

const useStyles = makeStyles({
    root: {
        position: "relative",
        padding: "6px 24px",
        borderRadius: "24px",
        fontSize: "16px",
        fontWeight: 500,
        textAlign: "center",
        cursor: "pointer",

        "&.inactive": {
            backgroundColor: "transparent",
            color: "#818181",
        },

        "&.active": {
            backgroundColor: "#2FA52B",
            color: "#FFFFFF",
        },
    },
});

interface OnButtonProps {
    active: boolean;
    onClick: () => void;
}

export const OnButton = (props: PropsWithChildren<OnButtonProps>) => {
    const classes = useStyles();

    const { active, onClick, children } = props;

    const state = active ? "active" : "inactive";

    const rippleReference = useRef<any>(null);

    const onRippleStart = (event: MouseEvent<HTMLElement>) => {
        if ( event.button === 2 ) return;
        if ( rippleReference.current ) rippleReference.current.start(event);
    };
    const onRippleStop = (event: MouseEvent<HTMLElement>) => {
        if ( rippleReference.current ) rippleReference.current.stop(event);
    };

    return (
        <div className={`${classes.root} ${state}`} onMouseDown={onRippleStart} onMouseUp={onRippleStop} onClick={onClick}>
            {children}
            <TouchRipple ref={rippleReference} center={false} />
        </div>
    );
};
