import { useCallback } from "react";

import AddIcon from "@material-ui/icons/AddCircleOutline";
import Big from "big.js";
import moment from "moment";

import { Group, Label, TextField } from "../../../form";
import { FormItem } from "../../framework/core/form-item.model";
import { useForm } from "../../framework/react/FormProvider";
import { FormItemSupport } from "../../framework/core/form-item.support";
import { Task } from "../../../../registration/registration.model";
import { useTranslation } from "../../../../internationalisation/translation.hook";
import { GreenTextButton } from "../../../text-button";
import { PageFormItem } from "./PageFormItemComponent";
import { DialogSection } from "../../../dialog";
import { AdaptedTaskSection, validateTaskDescription, validateTaskDuration, validateTaskTools } from "../../../../registration/create-registration-dialog/AdaptedTaskSection";
import { InfoFormItem } from "./InfoFormItemComponent";
import { GroupFormItem } from "./GroupFormItemComponent";
import { HorizontalButtonsFormItem } from "./HorizontalButtonsFormItemComponent";
import { FormController } from "../../framework/core/form.controller";
import { OrderDocument } from "../../../../customer/match.model";

export interface RegistrationFormItem {
    id: string;
    type: "registration",

    customerName: string;
    appointmentDate: string;
    tasks: Array<{
        description: string;
        descriptionDirty: boolean;
        duration: number | undefined;
        durationDirty: boolean;
        tools: Task["tools"] | undefined;
        toolsDirty: boolean;
    }>,

    
    clearLargeToolsId: string;
    clearLargeToolsPage: PageFormItem<GroupFormItem<[InfoFormItem, HorizontalButtonsFormItem]>>;
    
    orderedHours: OrderDocument["hourInterval"] | null;
    clearManyHoursId: string;
    clearManyHoursPage: PageFormItem<GroupFormItem<[InfoFormItem, HorizontalButtonsFormItem]>>;
}

interface RegistrationFormItemComponentProps {
    item: RegistrationFormItem;
}

export const RegistrationFormItemComponent = (props: RegistrationFormItemComponentProps) => {

    const { item } = props;
    const { updateItem } = useForm();

    const { t } = useTranslation();

    const addTask = useCallback(() => {
        updateItem(item.id, (state: RegistrationFormItem) => ({
            ...state,
            tasks: [
                ...state.tasks,
                {
                    description: "",
                    descriptionDirty: false,
                    duration: undefined,
                    durationDirty: false,
                    tools: undefined,
                    toolsDirty: false,
                },
            ],
        }));
    }, [updateItem, item.id]);

    return (
        <div id={`${item.id}-section`}>

            <DialogSection>
                <div id="customer-section">
                    <Label htmlFor="customer" style={{ marginBottom: "8px" }}>{t("CreateRegistrationDialog: customer-label")}</Label>
                    <Group>
                        <TextField id="customer" value={item.customerName} disabled />
                    </Group>
                </div>

                <div id="appointment-date-section">
                    <Label htmlFor="appointment-date" style={{ marginBottom: "8px" }}>{t("CreateRegistrationDialog: appointment-date-label")}</Label>

                    <Group>
                        <TextField id="appointment-date" value={formatAppointmentDate(item.appointmentDate)} disabled />
                    </Group>

                </div>
            </DialogSection>


            {item.tasks.map((_task, index) => (
                <AdaptedTaskSection
                    key={index}
                    item={item}
                    index={index}
                />
            ))}

            <GreenTextButton icon={<AddIcon />} onClick={addTask}>{t("CreateRegistrationDialog: add-task-button-text")}</GreenTextButton>
        </div>
    );
};

export class RegistrationFormItemSupport implements FormItemSupport<RegistrationFormItem> {

    supportedType = "registration" as const;

    updateChildren = (item: RegistrationFormItem, updateChildItem: (item: FormItem) => FormItem): RegistrationFormItem => {
        return {
            ...item,
            clearLargeToolsPage: updateChildItem(item.clearLargeToolsPage) as typeof item.clearLargeToolsPage,
            clearManyHoursPage: updateChildItem(item.clearManyHoursPage) as typeof item.clearManyHoursPage,
        };
    };

    markDirty(item: RegistrationFormItem): RegistrationFormItem {
        return {
            ...item,
            tasks: item.tasks.map(task => ({
                ...task,
                descriptionDirty: true,
                durationDirty: true,
                toolsDirty: true,
            })),
        };
    }

    getAllChildren(controller: FormController, item: RegistrationFormItem) {
        const clearedLargeTools = hasClearedLargeTools(controller, item);
        const largeToolsViolation = hasLargeToolsViolation(clearedLargeTools, item.tasks);

        const clearedManyHours = hasClearedManyHours(controller, item);
        const manyHoursViolation = hasManyHoursViolation(clearedManyHours, item.tasks, item.orderedHours);

        const confirmationPage = createConfirmationPage(largeToolsViolation, manyHoursViolation);

        return [item.clearLargeToolsPage, item.clearManyHoursPage, confirmationPage];
    }

    getActiveChildren(controller: FormController, item: RegistrationFormItem) {
        const clearedLargeTools = hasClearedLargeTools(controller, item);
        const largeToolsViolation = hasLargeToolsViolation(clearedLargeTools, item.tasks);

        const clearedManyHours = hasClearedManyHours(controller, item);
        const manyHoursViolation = hasManyHoursViolation(clearedManyHours, item.tasks, item.orderedHours);

        const results: PageFormItem<FormItem>[] = [];
        if ( hasLargeTools(item.tasks) ) results.push(item.clearLargeToolsPage);
        if ( hasManyHours(item.tasks, item.orderedHours) ) results.push(item.clearManyHoursPage);
        results.push(createConfirmationPage(largeToolsViolation, manyHoursViolation));

        return results;
    }

    blocksParent(item: RegistrationFormItem): boolean {
        return Boolean(validate(item));
    }
}

const hasClearedLargeTools = (controller: FormController, item: RegistrationFormItem): boolean => {
    return (controller.getItem(item.clearLargeToolsId, item.clearLargeToolsPage) as HorizontalButtonsFormItem).selectedChoiceIndex === 1;
}

const hasLargeTools = (tasks: RegistrationFormItem["tasks"]): boolean => {
    return tasks.some(task => task.tools === "large-tools");
}

const hasLargeToolsViolation = (customerExpectsLargeTools: boolean, tasks: RegistrationFormItem["tasks"]): boolean => {
    if ( customerExpectsLargeTools ) return false;

    return hasLargeTools(tasks);
}

const hasClearedManyHours = (controller: FormController, item: RegistrationFormItem): boolean => {
    return (controller.getItem(item.clearManyHoursId, item.clearManyHoursPage) as HorizontalButtonsFormItem).selectedChoiceIndex === 1;
}

const hasManyHours = (tasks: RegistrationFormItem["tasks"], orderedHours: OrderDocument["hourInterval"] | null): boolean => {
    const registrationTotalInMinutes = tasks.reduce((total, task) => total + (task.duration ?? 0), 0);
    const registrationTotalInHours = Big(registrationTotalInMinutes).div(60).round(2).toNumber();

    if ( orderedHours === "1-2 hours" && registrationTotalInHours > 2.5 ) return true;
    if ( orderedHours === "3-5 hours" && registrationTotalInHours > 6 ) return true;
    if ( orderedHours === "more-than-6-hours" && registrationTotalInHours > 8 ) return true;

    return false;
}

const hasManyHoursViolation = (customerExpectsManyHours: boolean, tasks: RegistrationFormItem["tasks"], orderedHours: OrderDocument["hourInterval"] | null): boolean => {
    if ( customerExpectsManyHours ) return false;

    return hasManyHours(tasks, orderedHours);
}

const createConfirmationPage = (largeToolsViolation: boolean, manyHoursViolation: boolean): PageFormItem<InfoFormItem> => {
    const lines: string[] = [];

    lines.push("Vi fakturerer kunden på dine vegne.");

    if ( largeToolsViolation && manyHoursViolation ) lines.push("Vi anbefaler du afklarer brugen af store redskaber og det registrerede antal timer med dine kunder fremover.");
    if ( largeToolsViolation && !manyHoursViolation ) lines.push("Vi anbefaler du afklarer brugen af store redskaber med dine kunder fremover.");
    if ( !largeToolsViolation && manyHoursViolation ) lines.push("Vi anbefaler du afklarer det registrerede antal timer med dine kunder fremover.");

    lines.push("Vi står for opkrævning af betaling fra kunden og fører udenretslig inkasso om nødvendigt.");
    lines.push("Du kan sætte dit honorar til udbetaling fra din profil, når vi har modtaget betaling fra kunden.");

    return {
        type: "page",
        title: "Registrer tid",
        item: {
            type: "info",
            render: "default",
            text: lines.join("<br /><br />"),
        },
    };
};

const validate = (item: RegistrationFormItem) => {
    return item.tasks.some(task => {
        return validateTaskDescription(task.description) || validateTaskDuration(task.duration) || validateTaskTools(task.tools, item.customerName)?.severity === "error";
    });
};

function formatAppointmentDate(appointmentDate: string): string {
    const day = moment(appointmentDate).format("dddd");
    const dayTitleCased = day.slice(0, 1).toUpperCase() + day.slice(1);

    const date = moment(appointmentDate).format("D MMMM YYYY");

    return `${dayTitleCased} d. ${date}`;
}
