import { makeStyles, Select, SelectProps } from "@material-ui/core";

import { TextField } from "./InputField";

const useStyles = makeStyles({
    select: {
        "&:focus": {
            backgroundColor: "transparent",
        },
    },
    list: {
        padding: 0,

        "& > *": {
            fontSize: "16px",
            padding: "12px 16px",
        },
    },
});

interface DropdownProps<T> extends Omit<SelectProps, "onChange"> {
    onChange?: (value: T) => void
}

export const Dropdown = <T extends any>(props: DropdownProps<T>) => {
    const customClasses = useStyles();

    const { placeholder, classes, ...otherProps } = props;

    return (
        <Select
            classes={{
                select: customClasses.select,
                ...classes,
            }}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                getContentAnchorEl: undefined,
                classes: {
                    list: customClasses.list,
                },
            }}
            input={<TextField />}
            {...otherProps as SelectProps}
        />
    );
};
