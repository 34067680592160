import { PropsWithChildren } from "react";

import { Collapse, makeStyles } from "@material-ui/core";

import { Topbar } from "./Topbar";
import { SideNavigation } from "./SideNavigation";
import { useUser } from "../user/UserProvider";
import { StatusBanner } from "../gardener/StatusBanner";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    },

    container: {
        flexGrow: 1,
        display: "flex",
    },

    main: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
    },
});

export const Layout = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    const gardener = useUser();

    const paused = gardener.protections.includes("manual-gardener") || gardener.protections.includes("no-reply") || gardener.protections.includes("picky-gardener") || gardener.protections.includes("process");
    const shouldShowStatusBanner = gardener.status === "terminated" || paused;

    return (
        <div className={classes.root}>
            <header>
                <Topbar />
            </header>

            <div className={classes.container}>
                <SideNavigation />

                <main className={classes.main}>

                    <Collapse appear={false} in={shouldShowStatusBanner}>
                        <StatusBanner enabled={shouldShowStatusBanner} />
                    </Collapse>

                    {props.children}
                </main>
            </div>
        </div>
    );
};
