import { useCallback } from "react";

import { Chip, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
    root: {
        padding: "8px 24px",
        borderRadius: "24px",
        height: "auto",

        "& .MuiChip-label": {
            padding: 0,
            whiteSpace: "normal",
        },
    },
    offNeutral: {
        backgroundColor: "transparent",
        color: "#1C5B13",
        border: "1px solid #1C5B13",

        "&.disabled": {
            backgroundColor: "transparent",
            color: "#818181",
            border: "1px solid #818181",
        },
        
        "&:focus": {
            backgroundColor: "transparent",
        },

        "&:hover": {
            backgroundColor: "rgba(29, 93, 20, 0.08) !important",
        },
    },
    onNeutral: {
        backgroundColor: "#1C5B13",
        color: "#FFFFFF",
        border: "1px solid #1C5B13",

        "&.disabled": {
            backgroundColor: "#818181",
            color: "#FFFFFF",
            border: "1px solid #818181",
        },

        "&:focus": {
            backgroundColor: "#1C5B13",
        },

        "&:hover": {
            backgroundColor: "#2f931f",
        },
    },
    destructive: {
        backgroundColor: "#F44336",
        color: "#FFFFFF",
        border: "1px solid #F44336",

        "&.disabled": {
            backgroundColor: "#818181",
            color: "#FFFFFF",
            border: "1px solid #818181",
        },

        "&:focus": {
            backgroundColor: "#F44336",
        },

        "&:hover": {
            backgroundColor: "#da190b",
        },
    },
});

interface MultipleChoiceOptionProps<T> {
    id: T;
    text: string;
    on: boolean;
    onClick: (id: T) => void;
    disabled?: boolean;
    destructive?: boolean;
}

export const MultipleChoiceOption = <T extends unknown>(props: MultipleChoiceOptionProps<T>) => {
    const classes = useStyles();

    const { id, text, on, onClick, disabled, destructive } = props;

    const onClasses = destructive ? classes.destructive : classes.onNeutral;
    const offClasses = destructive ? classes.destructive : classes.offNeutral;
    const stateClass = on ? onClasses : offClasses;
    const variant = on || destructive ? "default" : "outlined";

    const disabledClass = disabled ? "disabled" : "active";

    const click = useCallback(() => {
        onClick(id);
    }, [onClick, id]);

    return (
        <Chip
            className={`${classes.root} ${stateClass} ${disabledClass}`}
            variant={variant}
            label={text}
            onClick={click}
            disabled={disabled}
        />
    );
}
