import { useCallback, useEffect, useMemo, useRef } from "react";

import { Collapse, MenuItem } from "@material-ui/core";

import { AlternativeHint, Dropdown, Group, Label } from "../../../form";
import { FormItem } from "../../framework/core/form-item.model";
import { FormItemComponent } from "../components/FormItemComponent";
import { useForm } from "../../framework/react/FormProvider";
import { validateMandatory } from "../../../validation/validation";
import { HorizontalButtons } from "../../../form/horizontal-buttons/HorizontalButtons";
import { MultipleChoiceGroup } from "../../../form/multiple-choice";
import { FormItemSupport } from "../../framework/core/form-item.support";
import { FormController } from "../../framework/core/form.controller";

export type SingleChoiceFormItem<NextItem extends FormItem[]> = VoidSingleChoiceFormItem<NextItem> | HorizontalButtonsSingleChoiceFormItem<NextItem> | VerticalButtonsSingleChoiceFormItem<NextItem> | DropdownSingleChoiceFormItem<NextItem>;

export interface VoidSingleChoiceFormItem<NextItem extends FormItem[]> {
    id?: string;
    type: "single-choice",
    mode: "regular";
    render: "void";
    questionText: string;
    answerTexts: string[];
    answerItems: NextItem;
    // TODO: I'd prefer this interface. But I need to figure out how to handle the typing
    /*choices: Array<{
        answerText: string;
        item: FormItem;
    }>*/
    selectedChoiceIndex: number;
}

export interface HorizontalButtonsSingleChoiceFormItem<NextItem extends FormItem[]> {
    id: string;
    type: "single-choice",
    mode: "regular" | "switch page";
    switchPageIndexes?: number[];
    render: "horizontal-buttons";
    questionText: string | null;
    answerTexts: string[];
    answerItems: NextItem;
    // TODO: I'd prefer this interface. But I need to figure out how to handle the typing
    /*choices: Array<{
        answerText: string;
        item: FormItem;
    }>*/
    selectedChoiceIndex: number | null;
    dirty: boolean;
}

export interface VerticalButtonsSingleChoiceFormItem<NextItem extends FormItem[]> {
    id: string;
    type: "single-choice",
    mode: "regular" | "switch page";
    render: "vertical-buttons";
    questionText: string | null;
    answerTexts: string[];
    answerItems: NextItem;
    // TODO: I'd prefer this interface. But I need to figure out how to handle the typing
    /*choices: Array<{
        answerText: string;
        item: FormItem;
    }>*/
    selectedChoiceIndex: number | null;
    dirty: boolean;
    destructive?: boolean;
}

export interface DropdownSingleChoiceFormItem<NextItem extends FormItem[]> {
    id: string;
    type: "single-choice",
    mode: "regular";
    render: "dropdown";
    questionText: string;
    answerTexts: string[];
    answerItems: NextItem;
    // TODO: I'd prefer this interface. But I need to figure out how to handle the typing
    /*choices: Array<{
        answerText: string;
        item: FormItem;
    }>*/
    selectedChoiceIndex: number | null;
    dirty: boolean;
    placeholder: string | null;
}


interface SingleChoiceFormItemComponentProps<NextItem extends FormItem[]> {
    item: SingleChoiceFormItem<NextItem>;
}

export const SingleChoiceFormItemComponent = <NextItem extends FormItem[]>(props: SingleChoiceFormItemComponentProps<NextItem>) => {
    const { item } = props;

    return (
        <>
            {(() => {
                switch ( item.render ) {
                    case "void": return null;
                    case "horizontal-buttons": return <HorizontalButtonsSingleChoiceFormItemComponent item={item} />;
                    case "vertical-buttons": return <VerticalButtonsSingleChoiceFormItemComponent item={item} />;
                    case "dropdown": return <DropdownSingleChoiceFormItemComponent item={item} />;
                }
            })()}

            {item.selectedChoiceIndex !== null ? <FormItemComponent item={item.answerItems[item.selectedChoiceIndex]} /> : null}
        </>
    );
};

interface HorizontalButtonsSingleChoiceFormItemComponentProps<NextItem extends FormItem[]> {
    item: HorizontalButtonsSingleChoiceFormItem<NextItem>;
}

export const HorizontalButtonsSingleChoiceFormItemComponent = <NextItem extends FormItem[]>(props: HorizontalButtonsSingleChoiceFormItemComponentProps<NextItem>) => {
    const { item } = props;
    const { validationAnimationDuration, updateItem, goForward } = useForm();

    const error = useMemo(() => validate(item), [item]);
    const completed = !error;

    const shouldGoForward = useRef(false);
    useEffect(() => {
        if ( completed && shouldGoForward.current ) {
            shouldGoForward.current = false;
            goForward();
        }
    }, [completed, goForward]);

    const selectAnswer = useCallback((selectedChoiceIndex: number | null) => {
        const switchPageIndexes = item.switchPageIndexes ?? item.answerItems.map((_item, index) => index);

        if ( item.mode === "switch page" && selectedChoiceIndex !== null && switchPageIndexes.includes(selectedChoiceIndex) ) {
            shouldGoForward.current = true;
        }

        updateItem(item.id, (state: HorizontalButtonsSingleChoiceFormItem<NextItem>) => ({
            ...state,
            selectedChoiceIndex,
            dirty: true,
        }));
    }, [updateItem, item.id, item.mode, item.switchPageIndexes, item.answerItems]);

    const selectedButtonIndexes = useMemo(() => item.selectedChoiceIndex !== null ? [item.selectedChoiceIndex] : [], [item.selectedChoiceIndex]);

    return (
        <div id={`${item.id}-section`}>
            {item.questionText ? <Label style={{ marginBottom: "8px" }}>{item.questionText}</Label> : null}

            <Group error={Boolean(item.dirty && error)}>

                <HorizontalButtons
                    color="green"
                    buttonTexts={item.answerTexts}
                    selectedButtonIndexes={selectedButtonIndexes}
                    onSelect={selectAnswer}
                />

                <Collapse in={Boolean(item.dirty && error)} timeout={validationAnimationDuration}>
                    <AlternativeHint message={error} />
                </Collapse>
            </Group>
        </div>
    );
};

interface VerticalButtonsSingleChoiceFormItemComponentProps<NextItem extends FormItem[]> {
    item: VerticalButtonsSingleChoiceFormItem<NextItem>;
}

export const VerticalButtonsSingleChoiceFormItemComponent = <NextItem extends FormItem[]>(props: VerticalButtonsSingleChoiceFormItemComponentProps<NextItem>) => {
    const { item } = props;
    const { validationAnimationDuration, updateItem, goForward } = useForm();

    const error = useMemo(() => validate(item), [item]);
    const completed = !error;

    const shouldGoForward = useRef(false);
    useEffect(() => {
        if ( completed && shouldGoForward.current ) {
            shouldGoForward.current = false;
            goForward();
        }
    }, [completed, goForward]);

    const selectAnswer = useCallback((selectedChoiceIndexesAsStrings: string[]) => {
        const selectedChoiceIndexes = selectedChoiceIndexesAsStrings.map(x => parseInt(x, 10)).filter(x => x !== item.selectedChoiceIndex);
        const selectedChoiceIndex = selectedChoiceIndexes.length === 1 ? selectedChoiceIndexes[0] : item.selectedChoiceIndex;

        if ( item.mode === "switch page" ) {
            shouldGoForward.current = true;
        }

        updateItem(item.id, (state: VerticalButtonsSingleChoiceFormItem<NextItem>) => ({
            ...state,
            selectedChoiceIndex,
        }));
    }, [updateItem, item.id, item.selectedChoiceIndex, item.mode]);

    const selectedChoiceIndexesAsStrings = useMemo(() => item.selectedChoiceIndex ? [item.selectedChoiceIndex.toString()] : [], [item.selectedChoiceIndex]);

    const options = useMemo<Array<{ id: string, text: string}>>(() => {
        return item.answerTexts.map((answerText, index) => ({ id: index.toString(), text: answerText }));
    }, [item.answerTexts]);

    return (
        <div id={`${item.id}-section`}>
            {item.questionText ? <Label style={{ marginBottom: "8px" }}>{item.questionText}</Label> : null}
            <Group error={Boolean(item.dirty && error)}>

                <MultipleChoiceGroup
                    options={options}
                    value={selectedChoiceIndexesAsStrings}
                    onChange={selectAnswer}
                    destructive={item.destructive}
                />

                <Collapse in={Boolean(item.dirty && error)} timeout={validationAnimationDuration}>
                    <AlternativeHint message={error} />
                </Collapse>
            </Group>
        </div>
    );
};

interface DropdownSingleChoiceFormItemComponentProps<NextItem extends FormItem[]> {
    item: DropdownSingleChoiceFormItem<NextItem>;
}

export const DropdownSingleChoiceFormItemComponent = <NextItem extends FormItem[]>(props: DropdownSingleChoiceFormItemComponentProps<NextItem>) => {
    const { item } = props;
    const { controller, validationAnimationDuration, updateItem } = useForm();

    const markDirty = useCallback(() => {
        updateItem(item.id, state => controller.markDirty(state));
    }, [controller, updateItem, item.id]);

    const error = useMemo(() => validate(item), [item]);

    const updateValue = useCallback((selectedChoiceIndex: string) => {
        updateItem(item.id, (state: DropdownSingleChoiceFormItem<NextItem>) => ({
            ...state,
            selectedChoiceIndex: parseInt(selectedChoiceIndex),
        }));
    }, [updateItem, item.id]);

    const renderPlaceholder = useCallback(() => item.placeholder, [item]);

    return (
        <div id={`${item.id}-section`}>
            <Label htmlFor={item.id} style={{ marginBottom: "8px" }}>{item.questionText}</Label>
            <Group error={Boolean(item.dirty && error)}>
                <Dropdown labelId={item.id} variant="outlined" value={item.selectedChoiceIndex !== null ? item.selectedChoiceIndex.toString() : ""} onChange={updateValue} onBlur={markDirty} displayEmpty renderValue={item.selectedChoiceIndex !== null ? undefined : renderPlaceholder}>
                    {item.answerTexts.map((answerText, index) => (
                        <MenuItem key={index} value={index.toString()}>{answerText}</MenuItem>
                    ))}
                </Dropdown>

                <Collapse in={Boolean(item.dirty && error)} timeout={validationAnimationDuration}>
                    <AlternativeHint message={error} />
                </Collapse>
            </Group>
        </div>
    );
};

export class SingleChoiceFormItemSupport implements FormItemSupport<SingleChoiceFormItem<FormItem[]>> {

    supportedType = "single-choice" as const;

    updateChildren = (item: SingleChoiceFormItem<FormItem[]>, updateChildItem: (item: FormItem) => FormItem): SingleChoiceFormItem<FormItem[]> => {
        return {
            ...item,
            answerItems: item.answerItems.map(childItem => {
                return updateChildItem(childItem);
            }),
        };
    };

    markDirty(item: SingleChoiceFormItem<FormItem[]>): SingleChoiceFormItem<FormItem[]> {
        if ( !("dirty" in item) ) return item;

        return { ...item, dirty: true };
    }

    getAllChildren(controller: FormController, item: SingleChoiceFormItem<FormItem[]>) {
        return item.answerItems;
    }

    getActiveChildren(controller: FormController, item: SingleChoiceFormItem<FormItem[]>) {
        if ( item.selectedChoiceIndex === null ) return [];

        return [item.answerItems[item.selectedChoiceIndex]];
    }

    blocksParent(item: SingleChoiceFormItem<FormItem[]>): boolean {
        return Boolean(validate(item));
    }
}

const validate = (item: SingleChoiceFormItem<FormItem[]>): string | undefined => {
    return [validateMandatory(item.selectedChoiceIndex !== null) && "Vælg et svar"].find(Boolean);
}
