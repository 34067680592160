import { useEffect, useState } from "react";

import firebase from "firebase/app";
import { TaskDocument } from "./task.model";

export const useTasksThatAreOpenByGardener = (gardenerId: string | null | undefined, types: TaskDocument["type"][]) => {
    const [state, setState] = useState<TaskDocument[] | null>();

    useEffect(() => {
        if ( gardenerId === undefined ) return setState(undefined);
        if ( gardenerId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore()
            .collection("tasks")
                .where("type", "in", types)
                .where("gardenerId", "==", gardenerId)
                .where("hasBeenPerformed", "==", false)
            .onSnapshot(collection => {
                const documents = collection.docs.map(document => ({ id: document.id, ...document.data() } as TaskDocument));

                setState(documents);
            });

    }, [gardenerId, types]);

    return state;
};

export const useTasksBetweenGardenerAndCustomer = <
    Type extends TaskDocument["type"],
    Task extends Extract<TaskDocument, { type: Type }>,
>(gardenerId: string | null | undefined, customerId: string | null | undefined, types: Type[]): Task[] | null | undefined => {
    const [state, setState] = useState<Task[] | null>();

    useEffect(() => {
        if ( gardenerId === null || customerId === null ) return setState(null);
        if ( gardenerId === undefined || customerId === undefined ) return setState(undefined);

        setState(undefined);

        return firebase.firestore()
            .collection("tasks")
                .where("type", "in", types)
                .where("gardenerId", "==", gardenerId)
                .where("customerId", "==", customerId)
            .onSnapshot(collection => {
                const documents = collection.docs.map(document => ({ id: document.id, ...document.data() } as Task));

                setState(documents);
            });

    }, [gardenerId, customerId, types]);

    return state;
};

export const useTask = (taskId: string): TaskDocument | null | undefined => {
    const [state, setState] = useState<TaskDocument | null>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore().collection("tasks").doc(taskId).onSnapshot(document => {
            if ( !document.exists ) return setState(null);

            const task = { id: document.id, ...document.data() } as TaskDocument;

            setState(task);
        }, error => {
            console.error(error);

            setState(null);
        });
    }, [taskId]);

    return state;
};
