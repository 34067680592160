import { useCallback, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { LinearProgress } from "../../application/LinearProgress";
import { useTask } from "../../task/tasks.hook";

const useQuery = () => {
    const location = useLocation();

    return new URLSearchParams(location.search);
};

export const RedirectToRegistrationFollowUpTask = () => {
    const { taskId } = useParams<{ taskId: string }>();

    const query = useQuery();

    const status = (() => {
        switch ( query.get("status") ) {
            case "performed": return "performed";
            default: return "ignore";
        }
    })();

    const task = useTask(taskId);

    const history = useHistory();
    const goToList = useCallback(() => history.replace("/overview"), [history]);

    useEffect(() => {
        if ( !task || task.type !== "registration-follow-up" ) return;

        if ( task.hasBeenPerformed && status === "performed" ) return goToList();
    }, [task, status, goToList]);

    return <LinearProgress />;
};
