import { AccordionList } from "../components/accordion/AccordionList";
import { AccordionListItem } from "../components/accordion/AccordionListItem";
import { UnorderedList } from "../components/unordered-list/UnorderedList";

export const OverviewFaq = () => {
    return (
        <AccordionList>

            <AccordionListItem id="overview-question-1" title="Hvad kan jeg gøre for at blive tilbudt flere opgaver?">
                Først og fremmest bliver du kun tilbudt opgaver, som opfylder de betingelser, du har indstillet på din profil. Det vil sige opgaver, som er inden for din radius, og som du har de rette redskaber til og er i stand til at udføre.<br />
                <br />
                Derudover vil vi gerne prioritere de havemænd, som har de gladeste kunder og som vi har det bedste samarbejde med, når vi tilbyder opgaverne ud.<br />
                <br />
                Du kan derfor modtage flere opgaver ved at:
                <UnorderedList>
                    <li>være opmærksom på hvad dine kunder har bestilt, f.eks. om de har bestilt fast hjælp</li>
                    <li>være opmærksom på om din kunde har brug for yderligere hjælp efter en opgave</li>
                    <li>gøre dig umage med at forstå kundens ønsker og behov, så du undgår reklamationer</li>
                    <li>gøre dig umage med at finde en løsning, hvis du alligevel modtager en reklamation</li>
                </UnorderedList>
                <br />
                Desuden synes vi der skal være plads til alle slags havemænd og at det skal være et frit valg, når man tilbydes opgaver.
                Hvor mange opgaver du tilbydes påvirkes derfor ikke af:
                <UnorderedList>
                    <li>om du siger nej tak til tilbudte opgaver</li>
                    <li>om du accepterer mange eller få opgaver</li>
                    <li>om du accepterer store eller små opgaver</li>
                    <li>om du accepterer faste opgaver eller engangsopgaver</li>
                </UnorderedList>
            </AccordionListItem>

            <AccordionListItem id="overview-question-2" title="Hvad forventes der af mig som havemand ved Go Go Garden?">
                Du bestemmer selv, hvilke opgaver du vil acceptere, men accepterer du en opgave, så accepterer du også de forpligtelser, der følger med den.<br />
                <br />
                Dine forpligtelser består i at ringe til kunden, aftale besøg og udføre ordentligt havearbejde.<br />
                <br />
                I din oversigt kan du se de forpligtelser, som du behøver forholde dig til. Det er vigtigt, at du besvarer opgaverne inden for tidsfristen.
            </AccordionListItem>

            <AccordionListItem id="overview-question-3" title="Hvad sker der, hvis en af mine opgaver går over tid?">
                Er der opgaver, som du ikke besvarer til tiden, vil du blive sat på pause og vil derfor ikke få tilbudt nye opgaver. Du bliver informeret om dette på sms og email.<br />
                <br />
                Pausen forsvinder af sig selv, når du besvarer de opgaver, som ikke er besvaret til tiden.<br />
                <br />
                Går en af dine opgaver 10 dage over tid, så fjerner vi opgaven fra din profil og kontakter kunden for at følge op på deres opgave.
            </AccordionListItem>

        </AccordionList>
    );
};
