import { useCallback } from "react";

import { makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        padding: "12px 16px",
        cursor: "pointer",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        
        "&:hover": {
            backgroundColor: "#EBF3EA",
        },
    },
    heading: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#4A4A4A",
        marginTop: 0,
        marginBottom: "4px",
    },
    subheading: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#818181",
        margin: 0,
    }
});

export interface ListSectionItemProps {
    heading: string;
    subheading: string | JSX.Element;
    alert?: JSX.Element,
    index: number;
    onClick: (index: number) => void;
}

export const ListSectionItem = (props: ListSectionItemProps) => {
    const classes = useStyles();

    const { heading, subheading, alert, index, onClick } = props;

    const onSelectCustomer = useCallback(() => {
        onClick(index);
    }, [onClick, index]);

    return (
        <div>
            {alert}
            <Paper className={classes.root} onClick={onSelectCustomer}>
                <h3 className={classes.heading}>{heading}</h3>
                <p className={classes.subheading}>{subheading}</p>
            </Paper>
        </div>
    );
};
