import { PageFormItem } from "../../client/components";
import { FormItem } from "./form-item.model";
import { FormItemSupport } from "./form-item.support";

export class FormController {

    constructor(
        private support: FormItemSupport<any>[],
    ) {}

    updateItem<Item extends FormItem, ChildItem extends FormItem>(id: string, item: Item, updateChildItem: (item: ChildItem) => ChildItem): Item {
        if ( "id" in item && item.id === id ) return updateChildItem(item as Item & ChildItem) as unknown as Item;

        const support = this.support.find(x => x.supportedType === item.type);
        if ( !support || !support.updateChildren ) return item;

        return support.updateChildren(item, (childItem) => {
            return this.updateItem(id, childItem, updateChildItem);
        });
    }

    markDirty(item: FormItem): FormItem {
        const support = this.support.find(x => x.supportedType === item.type);
        if ( !support || !support.markDirty ) return item;
        
        return support.markDirty(item);
    }

    getItem(id: string, item: FormItem): FormItem | null {
        if ( "id" in item && item.id === id ) return item;

        const support = this.support.find(x => x.supportedType === item.type);
        if ( !support ) return null;

        return support.getAllChildren(this, item).map(childItem => this.getItem(id, childItem)).find(Boolean) ?? null;
    }

    getPages(item: FormItem): PageFormItem<FormItem>[] {
        const support = this.support.find(x => x.supportedType === item.type);
        if ( !support ) return [];

        const completed = !this.getBlockingItemDescendant(item);

        const children = completed ? support.getActiveChildren(this, item) : support.getAllChildren(this, item);

        return children.reduce<PageFormItem<FormItem>[]>((pages, childItem) => {
            if ( item.type === "page" ) return [...pages, item, ...this.getPages(childItem)];

            return [...pages, ...this.getPages(childItem)];
        }, []);
    }

    getPageItems(item: FormItem): FormItem[] {
        const support = this.support.find(x => x.supportedType === item.type);
        if ( !support ) return [];

        const children = support.getActiveChildren(this, item);

        return children.reduce<FormItem[]>((pageItems, childItem) => {
            if ( childItem.type === "page" ) return pageItems;

            return [...pageItems, childItem, ...this.getPageItems(childItem)];
        }, []);
    }

    getBlockingItemDescendant(item: FormItem): FormItem | null {
        if ( item.type === "page" ) return null;

        const support = this.support.find(x => x.supportedType === item.type);
        if ( !support ) return null;

        const blocksParent = support.blocksParent(item);
        if ( blocksParent ) return item;

        const children = support.getActiveChildren(this, item);

        return children.find(childItem => this.getBlockingItemDescendant(childItem)) ?? null;
    }

}
