import { HTMLAttributes, PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
    },
    anchor: {
        ...theme.mixins.toolbar,
        position: "absolute",
        bottom: "100%",
    },
}));

interface HeaderAwareAnchorProps extends Pick<HTMLAttributes<HTMLDivElement>, "className"> {
    id: string;
}

export const HeaderAwareAnchor = (props: PropsWithChildren<HeaderAwareAnchorProps>) => {
    const classes = useStyles();

    const { children, className } = props;

    return (
        <div className={className ? `${classes.root} ${className}` : classes.root}>
            <div id={props.id} className={classes.anchor} />
            {children}
        </div>
    );
};
