import { makeStyles } from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";
import { forwardRef } from "react";

import { useSnackbar } from "./SnackbarProvider";

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
})

interface SnackbarAlertProps {
    severity: AlertProps["severity"];
    message: string;
}

export const SnackbarAlert = forwardRef((props: SnackbarAlertProps, ref) => {
    const classes = useStyles();

    const { severity, message } = props;
    const { closeSnackbar } = useSnackbar();

    return (
        <Alert ref={ref} elevation={6} variant="filled" severity={severity} onClose={closeSnackbar} className={classes.root}>
            {message}
        </Alert>
    );
});
