import { Container } from "@material-ui/core";

import { useTranslation } from "../internationalisation/translation.hook";
import { ContentSection } from "./ContentSection";
import { Message } from "../components/Message";

export const ForbiddenPage = () => {
    const { t } = useTranslation();

    return (
        <ContentSection>

            <Container maxWidth="sm" disableGutters>

                <Message text={t("ForbiddenPage: no-access-message")} />

            </Container>

        </ContentSection>
    );
};
