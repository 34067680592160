import { PageFormItem, GroupFormItem, InfoFormItem, SingleChoiceFormItem } from "../../components";

export interface RegisterContactAttemptBranchIds {
    singleChoiceWantsToCallAgain: string;
}

type DoYouStillWantToCallTheCustomerAgain =
    PageFormItem<
        GroupFormItem<[
            InfoFormItem,
            SingleChoiceFormItem<[
                PageFormItem<InfoFormItem>,
                PageFormItem<InfoFormItem>,
            ]>,
        ]>
    >

export type RegisterContactAttemptBranch = DoYouStillWantToCallTheCustomerAgain | PageFormItem<InfoFormItem>;

export const createRegisterContactAttemptBranch = (numberOfPostponements: number, ids: RegisterContactAttemptBranchIds): RegisterContactAttemptBranch => {
    const doesNotWantToCallTheCustomerAgainConfirmationPage: PageFormItem<InfoFormItem> = {
        type: "page",
        title: "Afvis kunde",
        item: {
            type: "info",
            render: "default",
            text: `
                Du ønsker ikke at ringe til kunden igen og derfor overtager vi opgaven, så du slipper for at bruge din tid på den.<br />
                <br />
                Vi kontakter kunden og vil så vidt muligt prioritere dig, hvis kunden får brug for hjælp igen.<br />
                <br />
                Du vil ikke længere se denne opgave på din oversigt.
            `,
        },
    };

    const postponeContactConfirmationPage: PageFormItem<InfoFormItem> = {
        type: "page",
        title: "Udskyd kontakt til kunde",
        item: {
            type: "info",
            render: "default",
            text: `
                Vi forventer at du <strong>ringer til kunden i morgen</strong> for at aftale et besøg.<br />
                <br />
                Du vil se en “Har I lavet en aftale?”-opgave på din oversigt. Gå ind på opgaven for at besvare den, når du har ringet til kunden.<br />
                <br />
                Vi minder dig om opgaven med en sms på dagen.
            `,
        },
    };

    const doYouWantToCallTheCustomerAgainPage: DoYouStillWantToCallTheCustomerAgain = {
        type: "page",
        title: "Jeg har ringet til kunden uden held",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "info",
                    render: "default",
                    text: `
                        Du har forsøgt at ringe til kunden <strong>${numberOfPostponements} ${numberOfPostponements === 1 ? "gang" : "gange"}</strong>.
                        Vi anbefaler, at du forsøger at ringe til kunden <strong>3 gange</strong> inden du giver op.
                    `,
                },
                {
                    id: ids.singleChoiceWantsToCallAgain,
                    type: "single-choice",
                    mode: "switch page",
                    render: "horizontal-buttons",
                    selectedChoiceIndex: null,
                    dirty: false,
                    questionText: "Vil du ringe til kunden igen?",
                    answerTexts: ["Nej", "Ja"],
                    answerItems: [
                        doesNotWantToCallTheCustomerAgainConfirmationPage,
                        postponeContactConfirmationPage,
                    ],

                }
            ]
        }
    };

    const didNotReachCustomerInThreeAttemptsConfirmationPage: PageFormItem<InfoFormItem> = {
        type: "page",
        title: "Afvis kunde",
        item: {
            type: "info",
            render: "default",
            text: `
                Du har ringet forgæves til kunden 3 gange. Det tyder på, at kunden ikke har brug for hjælp alligevel.<br />
                <br />
                Vi anser ikke opgaven for at være aktuel og derfor overtager vi opgaven, så du slipper for at bruge din tid på den.<br />
                <br />
                Vi kontakter kunden og vil så vidt muligt prioritere dig, hvis kunden får brug for hjælp igen.<br />
                <br />
                Du vil ikke længere se denne opgave på din oversigt.
            `,
        },
    };

    return numberOfPostponements < 3 ? doYouWantToCallTheCustomerAgainPage : didNotReachCustomerInThreeAttemptsConfirmationPage; 
};
