import moment from "moment";

import { InfoFormItem, PageFormItem } from "../../components";

export const createRegisterInspectionBranch = (hasMultipleAppointments: boolean, now: string): PageFormItem<InfoFormItem> => {
    const callToday = moment(now).hours() < 20;
    const timeToCall = callToday ? "i dag" : "i morgen";

    if ( hasMultipleAppointments ) {
        return {
            type: "page",
            title: "Registrer besøg",
            item: {
                type: "info",
                render: "default",
                text: `
                    Vær opmærksom på, at du allerede har planlagt et andet besøg hos denne kunde.<br />
                    <br />
                    Du vil ikke længere se denne opgave på din oversigt.
                `,
            },
        };
    }

    return {
        type: "page",
        title: "Aftal nyt besøg",
        item: {
            type: "info",
            render: "default",
            text: `
                Vi forventer at du <strong>ringer til kunden ${timeToCall}</strong> for at aftale et besøg.<br />
                <br />
                Du vil se en “Har I lavet en aftale?”-opgave på din oversigt. Gå ind på opgaven for at besvare den, når du har ringet til kunden.
                ${callToday ? "" : `
                    <br />
                    <br />
                    Vi minder dig om opgaven med en sms på dagen.
                `}
            `,
        },
    };
};
