import { Container } from "@material-ui/core";
import { useCallback, useMemo } from "react";

import { ContentSection } from "../../application/ContentSection";
import { MatchDocument } from "../match.model";
import { useTranslation } from "../../internationalisation/translation.hook";
import { ListSection } from "../../components/list-section/ListSection";

interface PastCustomersTabProps {
    matches: MatchDocument[];
    goToCustomer: (matchId: string) => void;
}

export const PastCustomersTab = (props: PastCustomersTabProps) => {
    const { matches, goToCustomer } = props;
    const { t } = useTranslation();

    const sortedMatches = useMemo(() => {
        return matches.sort((a, b) => {
            if ( a.customer.name < b.customer.name ) return -1;
            if ( a.customer.name > b.customer.name ) return 1;
            return 0;
        });
    }, [matches]);

    const matchItems = useMemo(() => {
        return sortedMatches.map(match => ({
            heading: match.customer.name,
            subheading: `${match.customer.address.street}, ${match.customer.address.zipCode}`,
        }));
    }, [sortedMatches]);

    const goToCustomerUsingIndex = useCallback((index: number) => {
        const match = sortedMatches[index];
        goToCustomer(match.id);
    }, [sortedMatches, goToCustomer]);
    
    return (
        <ContentSection>
            <Container maxWidth="sm" disableGutters>

                <ListSection
                    title={t("PastCustomersTab: header")}
                    placeholder={t("PastCustomersTab: no-customers-message")}
                    items={matchItems}
                    onClick={goToCustomerUsingIndex}
                />

            </Container>
        </ContentSection>
    );
};
