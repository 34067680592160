import { cloneElement } from "react";

import { IconButton, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    icon: {
        width: "24px",
        height: "24px",
    },
});

export interface IconButtonBaseProps {
    className?: string;
    icon: JSX.Element;
    onClick?: () => void;
}

export const IconButtonBase = (props: IconButtonBaseProps) => {
    const classes = useStyles();

    const icon = cloneElement(props.icon, { className: classes.icon });

    return (
        <IconButton className={props.className} onClick={props.onClick}>
            {icon}
        </IconButton>
    );
};
