import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";

import { Fade, makeStyles } from "@material-ui/core";

import { LightAndGrayButton } from "../components/button";
import { useTranslation } from "../internationalisation/translation.hook";
import { useUser } from "../user/UserProvider";
import { formatDate } from "../utility";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        padding: "24px",

        "& > *:first-child": {
            marginRight: "48px",
        },

        [theme.breakpoints.down(1200)]: {
            flexDirection: "column",
            textAlign: "center",

            "& > *:first-child": {
                marginRight: 0,
                marginBottom: "16px",
            },

            "& > *:first-child:last-child": {
                marginBottom: 0,
            },
        },
    },
    notice: {
        backgroundColor: "#A259FF",
    },
    warning: {
        backgroundColor: "#EC8000",
    },
    title: {
        fontSize: "16px",
        fontWeight: 600,
        color: "#FFFFFF",
        margin: 0,
        marginBottom: "4px",
    },
    message: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#FFFFFF",
        margin: 0,
    },
}));

interface StatusBannerProps {
    enabled: boolean
}

export const StatusBanner = (props: StatusBannerProps) => {
    const classes = useStyles();
    const { enabled } = props;

    const { t } = useTranslation();
    
    const gardener = useUser();

    const history = useHistory();
    const goToProfile = useCallback(() => {
        history.push("/profile");
    }, [history]);
    const goToOverview = useCallback(() => {
        history.push("/overview/current");
    }, [history]);

    const scenario = useMemo(() => {
        if ( !enabled ) return null;
        if ( gardener.status === "terminated" ) return "terminated gardener";
        if ( gardener.protections.includes("process") ) return "process protection";
        return "paused gardener";
    }, [enabled, gardener]);

    const [retainedScenario, setRetainedScenario] = useState<typeof scenario>(scenario);
    useEffect(() => {
        if ( !scenario ) return;
        if ( scenario === retainedScenario ) return;

        setRetainedScenario(scenario);
    }, [scenario, retainedScenario]);

    const severityClass = useMemo(() => {
        if ( !retainedScenario ) return null;

        switch ( retainedScenario ) {
            case "terminated gardener": return classes.notice;
            case "process protection": return classes.warning;
            case "paused gardener": return classes.notice;
        }
    }, [retainedScenario, classes]);

    const message = useMemo(() => {
        if ( !retainedScenario ) return null;

        switch ( retainedScenario ) {
            case "terminated gardener":
                return (
                    <div>
                        <h4 className={classes.title}>
                            {gardener.terminationDate ? t("StatusBanner: terminated-banner-title-with-date").replaceAll("$DATE", formatDate(gardener.terminationDate)) : t("StatusBanner: terminated-banner-title-without-date")}
                        </h4>
                        <p className={classes.message}>{t("StatusBanner: terminated-banner-message")}</p>
                    </div>
                );

            case "process protection":
                return (
                    <div>
                        <h4 className={classes.title}>{t("StatusBanner: paused-banner-title")}</h4>
                        <p className={classes.message} dangerouslySetInnerHTML={{ __html: t("StatusBanner: process-protection-banner-message").split("\n").join("<br />") }} />
                    </div>
                );

            case "paused gardener":
                return (
                    <div>
                        <h4 className={classes.title}>{t("StatusBanner: paused-banner-title")}</h4>
                        <p className={classes.message}>{t("StatusBanner: paused-banner-message")}</p>
                    </div>
                );
        }
    }, [retainedScenario, classes, gardener, t]);

    const route = useRouteMatch();
    const shouldShowProfilebutton = retainedScenario === "paused gardener" && route.url !== "/profile";
    const shouldShowOverviewbutton = retainedScenario === "process protection" && route.url !== "/overview/current";

    const button = useMemo(() => {
        if ( shouldShowOverviewbutton ) return <LightAndGrayButton onClick={goToOverview}>{t("StatusBanner: overview-link")}</LightAndGrayButton>;
        if ( shouldShowProfilebutton ) return <LightAndGrayButton onClick={goToProfile}>{t("StatusBanner: profile-link")}</LightAndGrayButton>;

        return null;
    }, [goToOverview, goToProfile, shouldShowOverviewbutton, shouldShowProfilebutton, t]);

    if ( !retainedScenario ) return null;

    return (
        <div className={`${classes.root} ${severityClass}`}>

            {message}

            <Fade appear={false} in={shouldShowOverviewbutton || shouldShowProfilebutton} unmountOnExit>
                <div>
                    {button}
                </div>
            </Fade>

        </div>
    );
};
