import { PageFormItem, GroupFormItem, DatePickerFormItem, TextAreaFormItem, InfoFormItem } from "../../components";

export interface CreateAppointmentBranchIds {
    datePickerAppointmentDate: string;
    textAreaAppointmentDescription: string;
}

export type CreateAppointmentBranch =
    PageFormItem<
        GroupFormItem<[
            DatePickerFormItem,
            TextAreaFormItem,
            PageFormItem<
                InfoFormItem
            >
        ]>
    >;

export const createCreateAppointmentBranch = (today: string, ids: CreateAppointmentBranchIds, appointmentDescription: string = "", title = "Planlæg besøg"): CreateAppointmentBranch => {

    const confirmationPage: PageFormItem<InfoFormItem> = {
        type: "page",
        title: "Planlæg besøg",
        item: {
            type: "info",
            render: "default",
            text: `
                Du vil se en “Hvordan gik dit besøg?”-opgave på din oversigt.<br />
                <br />
                Gå ind på opgaven for at besvare den efter dit besøg eller hvis I ændrer eller aflyser besøget.<br />
                <br />
                Vi minder dig om opgaven med en sms dagen inden dit besøg.
            `,
        },
    };

    return {
        type: "page",
        title,
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    id: ids.datePickerAppointmentDate,
                    type: "date-picker",
                    label: "Dato for besøg",
                    value: "",
                    dirty: false,
                    minimumDate: today,
                    maximumDate: null,
                },
                {
                    id: ids.textAreaAppointmentDescription,
                    type: "text-area",
                    label: "Hvad skal du lave hos kunden?",
                    placeholder: "Hvad skal du lave? Skal du medbringe egne redskaber? Hvor længe har I aftalt, at du bruger på opgaven?",
                    value: appointmentDescription,
                    dirty: false,
                },
                confirmationPage,
            ],
        },
    };
};
