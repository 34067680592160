import React, { FocusEvent, useCallback, useMemo, useState } from "react";
import { DatePickerCalendarProps } from "react-nice-dates";

import { ClickAwayListener, OutlinedInput, OutlinedInputProps, makeStyles, useMediaQuery } from "@material-ui/core";
import moment from "moment";

import { DatePicker } from "../date-picker/DatePicker";

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: "16px",
        fontWeight: 400,
        backgroundColor: "#FFFFFF",
        color: "#4A4A4A",
        borderRadius: "24px",

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.25)",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1C5B13",
        },
        "&.Mui-disabled .MuiOutlinedInput-notchedOutline, &.Mui-disabled:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.25)",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline, &.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#2FA52B",
        },
        "&.Mui-error .MuiOutlinedInput-notchedOutline, &.Mui-error:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.error.main,
        },
    },
    input: {
        padding: "12px 16px",
    },
    notchedOutline: {
        marginTop: "5px",

        "& legend": {
            display: "none",
        },
    },
    multiline: {
        padding: 0,
    },
    inputMultiline: {
        overflowY: "hidden",
    },
}));

type InputFieldProps = Omit<OutlinedInputProps, "onChange"> & { onChange?: (value: string) => void };

const InputField = (props: InputFieldProps) => {
    const { classes, onChange, ...otherProps } = props;

    const customClasses = useStyles();

    const change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if ( !onChange ) return;

        onChange(event.target.value);
    }, [onChange]);

    return (
        <OutlinedInput
            classes={{
                root: customClasses.root,
                input: customClasses.input,
                notchedOutline: customClasses.notchedOutline,
                multiline: customClasses.multiline,
                inputMultiline: customClasses.inputMultiline,
                ...classes,
            }}
            onChange={onChange !== undefined ? change : undefined}
            {...otherProps}
        />
    );
};

export type TextFieldProps = Omit<InputFieldProps, "type" | "multiline">;

export const TextField = (props: TextFieldProps) => {
    return <InputField type="text" {...props} />;
};

export const EmailField = (props: Omit<InputFieldProps, "type" | "multiline">) => {
    return <InputField type="email" {...props} />;
};

export const TextArea = (props: Omit<InputFieldProps, "type" | "multiline">) => {
    return <InputField multiline minRows={2} {...props} />
};

export const DateField = (props: Omit<InputFieldProps, "type" | "multiline"> & { minimumDate?: Date, maximumDate?: Date, locale: DatePickerCalendarProps["locale"] }) => {
    const { value, onChange, onBlur, minimumDate, maximumDate, locale, ...otherProps } = props;

    const mainlyTouchDevice = useMediaQuery(`(hover: none)`);

    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    const addAnchor = useCallback((event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAnchor(event.currentTarget);

        if ( mainlyTouchDevice ) event.currentTarget.blur();
    }, [mainlyTouchDevice]);

    const removeAnchor = useCallback(() => {
        setAnchor(null);

        if ( onBlur ) onBlur(null as any);
    }, [onBlur]);

    const textDate = useMemo(() => {
        if ( !moment(value as string, true).isValid() ) return value;

        return moment(value as string, true).format("DD-MM-YYYY");
    }, [value]);

    const setTextDate = useCallback((value: string) => {
        if ( !onChange ) return;
        if ( !moment(value, "DD-MM-YYYY", true).isValid() ) return onChange(value)

        const newDate = moment(value, "DD-MM-YYYY", true).toISOString(true);

        onChange(newDate);
    }, [onChange]);

    const setDateDate = useCallback((value: string) => {
        if ( !onChange ) return;

        onChange(value);

        removeAnchor();
    }, [onChange, removeAnchor]);

    return (
        <ClickAwayListener onClickAway={removeAnchor}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <InputField type="text" value={textDate} onChange={setTextDate} inputProps={{ onFocus: addAnchor, autoComplete: "off" }} {...otherProps} />

                <DatePicker anchor={anchor} value={props.value as string} minimumDate={minimumDate} maximumDate={maximumDate} onChange={setDateDate} locale={locale} />
            </div>
        </ClickAwayListener>
    );
};
