import { PageFormItem, InfoFormItem, SingleChoiceFormItem, GroupFormItem, NoticeFormItem } from "../../components";

export interface RejectCustomerBranchIds {
    singleChoiceDoesCustomerStillWantHelp: string,
}

export type RejectCustomerBranchLegacy =
    PageFormItem<
        SingleChoiceFormItem<[
            PageFormItem<InfoFormItem>,
            PageFormItem<InfoFormItem>,
            PageFormItem<InfoFormItem>,
        ]>
    >;

export type RejectCustomerBranch =
    PageFormItem<
        GroupFormItem<[
            NoticeFormItem,
            SingleChoiceFormItem<[
                PageFormItem<InfoFormItem>,
                PageFormItem<InfoFormItem>,
                PageFormItem<InfoFormItem>,
            ]>,
        ]>
    >;

export const createCustomerDoesNotNeedHelpConfirmationPage = (hasHistory: boolean): PageFormItem<InfoFormItem> => {
    const lines: string[] = [];
    if ( !hasHistory ) lines.push("Vi fortæller kunden, at vi har modtaget besked om, at de ikke ønsker hjælp lige nu, og at vi derfor lukker deres opgave.");
    lines.push("Du vil ikke længere se denne opgave på din oversigt.");

    return {
        type: "page",
        title: "Afvis kunde",
        item: {
            type: "info",
            render: "default",
            text: lines.join("<br /><br />"),
        },
    };
};

export const createCustomerMightNeedHelpConfirmationPage = (hasHistory: boolean, hasRecentHistory: boolean): PageFormItem<InfoFormItem> => {
    const customerWantsHelp = !hasHistory;
    const shouldNotifyCustomer = !hasHistory || hasRecentHistory;

    const lines: string[] = [];
    if ( shouldNotifyCustomer ) lines.push("Vi fortæller kunden, at du ikke kan hjælpe dem alligevel.");
    if ( customerWantsHelp ) lines.push("Vi vil forsøge at finde en anden havemand til opgaven.",);
    lines.push("Du vil ikke længere se denne opgave på din oversigt.");


    return {
        type: "page",
        title: "Afvis kunde",
        item: {
            type: "info",
            render: "default",
            text: lines.join("<br /><br />"),
        },
    };
}

export const createGardenerCannotHelpConfirmationPage = (): PageFormItem<InfoFormItem> => {    
    const lines: string[] = [
        "Vi fortæller kunden, at du ikke kan hjælpe dem alligevel.",
        "Vi vil forsøge at finde en anden havemand til opgaven.",
        "Du vil ikke længere se denne opgave på din oversigt.",
    ];

    return {
        type: "page",
        title: "Afvis kunde",
        item: {
            type: "info",
            render: "default",
            text: lines.join("<br /><br />"),
        },
    };
}

export const createRejectCustomerBranchLegacy = (hasHistory: boolean, hasRecentHistory: boolean, ids: RejectCustomerBranchIds): RejectCustomerBranchLegacy => {
    const customerDoesNotWantHelpConfirmationPage = createCustomerDoesNotNeedHelpConfirmationPage(hasHistory);
    const customerMightWantHelpConfirmationPage = createCustomerMightNeedHelpConfirmationPage(hasHistory, hasRecentHistory);
    const gardenerCannotHelpConfirmationPage = createGardenerCannotHelpConfirmationPage();

    return {
        type: "page",
        title: "Ønsker kunden stadig hjælp?",
        item: {
            id: ids.singleChoiceDoesCustomerStillWantHelp,
            type: "single-choice",
            mode: "switch page",
            render: "horizontal-buttons",
            selectedChoiceIndex: null,
            dirty: false,
            questionText: null,
            answerTexts: ["Nej", "Ja", "Ved ikke"],
            answerItems: [
                customerDoesNotWantHelpConfirmationPage,
                gardenerCannotHelpConfirmationPage,
                customerMightWantHelpConfirmationPage,
            ],
        },
    };
};

export const createRejectCustomerBranch = (hasHistory: boolean, hasRecentHistory: boolean, ids: RejectCustomerBranchIds): RejectCustomerBranch => {
    const customerDoesNotWantHelpConfirmationPage = createCustomerDoesNotNeedHelpConfirmationPage(hasHistory);
    const customerMightWantHelpConfirmationPage = createCustomerMightNeedHelpConfirmationPage(hasHistory, hasRecentHistory);
    const gardenerCannotHelpConfirmationPage = createGardenerCannotHelpConfirmationPage();

    return {
        type: "page",
        title: "Opgaven skal lukkes",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "notice",
                    render: "warning",
                    html: `
                        Du bør kun lukke denne opgave, hvis du ikke kan hjælpe kunden alligevel eller hvis kunden ikke har brug for hjælp alligevel. Undgå så vidt muligt at acceptere opgaver, du ikke kan løse.
                    `,
                },
                {
                    id: ids.singleChoiceDoesCustomerStillWantHelp,
                    type: "single-choice",
                    mode: "switch page",
                    render: "horizontal-buttons",
                    selectedChoiceIndex: null,
                    dirty: false,
                    questionText: "Skal vi finde en anden havemand til opgaven?",
                    answerTexts: ["Nej", "Ja", "Ved ikke"],
                    answerItems: [
                        customerDoesNotWantHelpConfirmationPage,
                        gardenerCannotHelpConfirmationPage,
                        customerMightWantHelpConfirmationPage,
                    ],
                }
            ],
        },
    };
};
