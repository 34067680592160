import { useCallback } from "react";
import { DatePickerCalendar, DatePickerCalendarProps } from "react-nice-dates";
import "react-nice-dates/build/style.css";

import { makeStyles, Paper, Popper } from "@material-ui/core";
import moment from "moment";

import { useWindowSize } from "../../window-size.hook";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";

const useStyles = makeStyles(theme => ({
    popper: {
        zIndex: theme.zIndex.modal + 1,
    },
    paper: {
        marginTop: "8px",

        "& .nice-dates-navigation": {

            "& > .nice-dates-navigation_current": {
                color: "#4A4A4A",
            },

            "& > .nice-dates-navigation_previous, & > .nice-dates-navigation_next": {
                "&::before": {
                    borderColor: "#818181",
                },
    
                "&:hover::before": {
                    borderColor: "#4A4A4A",
                },

                "&.-disabled::before": {
                    opacity: 0.3,
                },
            },

        },

        "& .nice-dates-week-header": {
            boxShadow: "0 1px 0 #ededed",

            "& > .nice-dates-week-header_day": {
                color: "#818181",
            },
        },

        "& .nice-dates-grid .nice-dates-day": {
            color: "#4A4A4A",

            "&.-outside": {
                opacity: 0.4,
            },

            "&.-disabled": {
                opacity: 0.2,
            },

            "&.-selected": {
                opacity: 1,
                pointerEvents: "auto",
                cursor: "pointer",
            },

            "&::before": {
                backgroundColor: "#297A1D",
            },

            "&::after": {
                borderColor: "#297A1D",
            },

            "& > .nice-dates-day_date": {
                transition: "none",
            },

            "& > .nice-dates-day_month": {
                display: "none",
            },
        },
    },
}));

const popperModifiers = {
    flip: {
        enabled: false,
    },
};

interface DatePickerProps extends DatePickerCalendarProps {
    anchor: HTMLElement | null;
    value: string;
    onChange: (value: string) => void;
}

export const DatePicker = (props: DatePickerProps) => {
    const classes = useStyles();

    const { value, onChange, anchor, ...otherProps } = props;

    const date = useMemo(() => {
        if ( !moment(value, moment.ISO_8601, true).isValid() ) return undefined;

        return moment(value, moment.ISO_8601, true).toDate();
    }, [value]);

    const onDateChange = useCallback((date: Date | null) => {
        const newValue = moment(date).toISOString(true);

        onChange(newValue);

        anchor?.blur();
    }, [onChange, anchor]);

    useWindowSize();

    const [enabled, setEnabled] = useState(true);
    useEffect(() => {
        setEnabled(false);

    }, [value]);
    useEffect(() => {
        if ( enabled ) return;

        setEnabled(true);
    }, [enabled]);

    return (
        <Popper open={enabled && Boolean(anchor)} anchorEl={anchor} modifiers={popperModifiers} className={classes.popper}>
            <Paper elevation={6} className={classes.paper} style={anchor ? { width: `${Math.max(anchor.clientWidth, 288)}px` } : undefined}>
                <DatePickerCalendar date={date} onDateChange={onDateChange} {...otherProps} />
            </Paper>
        </Popper>
    );
};
