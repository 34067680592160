import { useCallback, useMemo, useState } from "react";

import { Collapse, IconButton, makeStyles, Zoom } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import firebase from "firebase/app";

import { CardContent } from "../components/card/CardContent";
import { useSnackbar } from "../components/snackbar";
import { LinearProgress } from "../application/LinearProgress";
import { PersonalInformationDocument } from "./personal-information.model";
import { CardActions } from "../components/card/CardActions";
import { NeutralAction } from "../components/card/NeutralAction";
import { PositiveAction } from "../components/card/PositiveAction";
import { HeaderAwareAnchor } from "../components/HeaderAwareAnchor";
import { Group } from "../components/form/Group";
import { Label } from "../components/form/Label";
import { TextField } from "../components/form/InputField";
import { AlternativeHint } from "../components/form/Hint";
import { useAnimation } from "../components/validation/animation.hook";
import { useBlockingProgress } from "../components/blocking-progress";
import { useTranslation } from "../internationalisation/translation.hook";
import { Card, CardHeader } from "../components/card";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
        }
    },
    editIcon: {
        fontSize: "24px",
        color: "#297A1D",
    },
});

const initialDirty = {
    registrationNumber: false,
    accountNumber: false,
};

const fullDirty = {
    registrationNumber: true,
    accountNumber: true,
};

interface PersonalInformationCardProps {
    personalInformation: PersonalInformationDocument;
}

export const PersonalInformationCard = (props: PersonalInformationCardProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { personalInformation } = props;
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const [dirty, setDirty] = useState(personalInformation.bankAccount ? fullDirty : initialDirty);

    const [editing, setEditing] = useState(false);

    const enterEditMode = useCallback(() => {
        setEditing(true);
    }, []);

    const cancelEdit = useCallback(() => {
        setEditing(false);
        setRegistrationNumber(personalInformation.bankAccount?.registrationNumber ?? "");
        setAccountNumber(personalInformation.bankAccount?.accountNumber ?? "");
        setDirty(personalInformation.bankAccount ? fullDirty : initialDirty);
    }, [personalInformation]);

    const [registrationNumber, setRegistrationNumber] = useState(personalInformation.bankAccount?.registrationNumber ?? "");
    const registrationNumberError = useMemo(() => {
        if ( editing && !registrationNumber ) return t("Validation: must-be-specified");
        if ( editing && !registrationNumber.match(/^\d{4}$/) ) return t("Validation: must-be-4-digits");

        return undefined;
    }, [t, editing, registrationNumber]);
    const markRegistrationNumberDirty = useCallback(() => setDirty({ ...dirty, registrationNumber: true }), [dirty]);

    const [accountNumber, setAccountNumber] = useState(personalInformation.bankAccount?.accountNumber ?? "");
    const accountNumberError = useMemo(() => {
        if ( editing && !accountNumber ) return t("Validation: must-be-specified");
        if ( editing && !accountNumber.match(/^\d{10}$/) ) return t("Validation: must-be-10-digits");

        return undefined;
    }, [t, editing, accountNumber]);
    const markAccountNumberDirty = useCallback(() => setDirty({ ...dirty, accountNumber: true }), [dirty]);

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( registrationNumberError ) return "registration-number-section";
            if ( accountNumberError ) return "account-number-section";

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [registrationNumberError, accountNumberError]);

    const gardenerId = personalInformation.id;
    const bankAccount: Required<PersonalInformationDocument["bankAccount"]> = useMemo(() => ({
        registrationNumber,
        accountNumber,
    }), [registrationNumber, accountNumber]);
    const bankAccountError = registrationNumberError || accountNumberError;

    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();

    const saveEdit = useCallback(() => {

        if ( bankAccountError ) {
            skipValidationAnimation();
            setDirty(fullDirty);
            return scrollToFirstError();
        }

        block();

        firebase.firestore().collection("personalInformation").doc(gardenerId).set({ bankAccount }, { merge: true })
            .then(() => {
                setEditing(false);
            })
            .catch(() => {
                openSnackbar("error", t("PersonalInformationCard: save-error-message"));
            })
            .then(() => {
                unblock();
            });
    }, [scrollToFirstError, skipValidationAnimation, block, unblock, openSnackbar, t, gardenerId, bankAccount, bankAccountError]);

    if ( personalInformation === undefined ) return <LinearProgress />;

    const editButton = (
        <Zoom appear={false} in={!editing}>
            <IconButton aria-label="edit" onClick={enterEditMode}>
                <EditIcon className={classes.editIcon} />
            </IconButton>
        </Zoom>
    );

    return (
        <Card>
            <CardHeader title={t("PersonalInformationCard: card-title")} button={editButton} />
            <CardContent>

                <HeaderAwareAnchor id="registration-number-section" className={classes.group}>
                    {editing ? (
                        <>
                            <Label style={{ marginBottom: "8px" }}>{t("PersonalInformationCard: registration-number-label")}</Label>
                            <Group error={Boolean(dirty.registrationNumber && registrationNumberError)}>
                                <TextField value={registrationNumber} onChange={setRegistrationNumber} onBlur={markRegistrationNumberDirty} />

                                <Collapse in={Boolean(dirty.registrationNumber && registrationNumberError)} timeout={validationAnimationDuration}>
                                    <AlternativeHint message={registrationNumberError} />
                                </Collapse>
                            </Group>
                        </>
                    ) : (
                        <>
                            <label>{t("PersonalInformationCard: registration-number-label")}</label>
                            <span>{registrationNumber || t("PersonalInformationCard: not-specified")}</span>
                        </>
                    )}
                </HeaderAwareAnchor>
                
                <HeaderAwareAnchor id="account-number-section" className={classes.group}>
                    {editing ? (
                        <>
                            <Label style={{ marginBottom: "8px" }}>{t("PersonalInformationCard: account-number-label")}</Label>
                            <Group error={Boolean(dirty.accountNumber && accountNumberError)}>
                                <TextField value={accountNumber} onChange={setAccountNumber} onBlur={markAccountNumberDirty} />

                                <Collapse in={Boolean(dirty.accountNumber && accountNumberError)} timeout={validationAnimationDuration}>
                                    <AlternativeHint message={accountNumberError} />
                                </Collapse>
                            </Group>
                        </>
                    ) : (
                        <>
                            <label>{t("PersonalInformationCard: account-number-label")}</label>
                            <span>{accountNumber || t("PersonalInformationCard: not-specified")}</span>
                        </>
                    )}
                </HeaderAwareAnchor>

            </CardContent>

            {editing ? (
                <CardActions>
                    <NeutralAction onClick={cancelEdit}>{t("Button: cancel")}</NeutralAction>
                    <PositiveAction onClick={saveEdit}>{t("Button: save")}</PositiveAction>
                </CardActions>
            ) : null}
        </Card>
    )
};
