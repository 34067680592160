import { PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",

        padding: "0 24px 24px",
        marginBottom: "8px",

        "&:last-child": {
            marginBottom: 0,
        },

        [theme.breakpoints.down(500)]: {
            padding: "0 16px 16px",
        },

        "& > *": {
            marginBottom: "24px",
            
            "&:last-child": {
                marginBottom: 0,
            },
        },
    },
}));

export const DialogContent = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    const { children } = props;

    return (
        <div className={classes.root} id="dialog-content">
            {children}
        </div>
    );
};
