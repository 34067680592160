import { makeStyles } from "@material-ui/core";
import ClockIcon from "@material-ui/icons/ScheduleOutlined";
import moment from "moment";

import { CardContent } from "../components/card/CardContent";
import { Skill } from "../gardener/gardener.model";
import { Translation, useTranslation } from "../internationalisation/translation.hook";
import { Card, CardHeader } from "../components/card";
import { MatchDocument } from "./match.model";

const useStyles = makeStyles({
    customer: {
        "& > *": {
            marginBottom: "16px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        }
    },
    date: {
        display: "flex",
        alignItems: "center",

        "& > svg": {
            fontSize: "18px",
            color: "#91BAF1",
            marginRight: "8px",
        },

        "& > span": {
            flexBasis: "100%",
            fontSize: "14px",
            fontWeight: 400,
            color: "#818181",
        },
    },
    link: {
        display: "block",
        fontSize: "16px",
        fontWeight: 500,
        color: "#297A1D",
        textDecoration: "none",

        "&:hover": {
            color: "#1C5B13",
            textDecoration: "underline",
        },
    },
    orderInformation: {
        "& > p": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#4A4A4A",
            marginTop: 0,
            marginBottom: "4px",

            "&:last-child": {
                marginBottom: 0,
            },
        },
    },
    skillsLabel: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        lineHeight: "125%",
        marginTop: 0,
        marginBottom: "4px !important",
    },
    list: {
        listStyle: "none",
        paddingLeft: "8px",
        marginTop: 0,
        marginBottom: "16px",
    },
    listItem: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        lineHeight: "125%",

        "&::before": {
            content: '"\\2022"',
            fontWeight: 600,
            marginRight: "8px",
        },
    },
    orderDescription: {
        fontSize: "16px",
        fontWeight: 400,
        fontStyle: "italic",
        color: "#4A4A4A",
        whiteSpace: "pre-wrap",
        marginTop: 0,
    },
});

interface CustomerCardProps {
    status?: JSX.Element | null;
    title: string;
    customer: MatchDocument;
    actions?: JSX.Element | null;
}

export const CustomerCard = (props: CustomerCardProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { customer: match, title, status, actions } = props;

    const requiredSkills = match.order?.requiredSkills.sort((a, b) => {
        const sortOrder: Skill[] = ["lawn-mowing", "weed-control", "hedge-trimming", "pruning-of-trees-and-shrubs", "disposal-of-garden-waste", "other-garden-services"];
        return sortOrder.indexOf(a) - sortOrder.indexOf(b);
    });

    return (
        <Card>

            {status}

            <CardHeader title={title} />

            <CardContent className={classes.customer}>

                <div className={classes.date}>
                    <ClockIcon />
                    <span>{formatDate(t, match.creationDate)}</span>
                </div>

                {title !== match.customer.name ? (
                    <div>
                        {match.customer.name}
                    </div>
                ) : null}

                <a href={phoneNumberHref(match.customer.phoneNumber)} className={classes.link}>
                    {match.customer.phoneNumber}
                </a>

                <a href={gpsHref(match.customer.address)} target="_blank" rel="noreferrer" className={classes.link}>
                    {match.customer.address.street}<br />
                    {match.customer.address.zipCode}
                </a>

                {match.order ? (
                    <div className={classes.orderInformation}>
                        <p>{t("CustomerCard: customer-specified-hours")} {formatHours(t, match.order)}</p>
                        <p>{formatTools(t, match.order)}</p>
                        <p>{formatFrequency(t, match.order)}</p>
                    </div>
                ) : null}

                {match.order && match.order.requiredSkills.length > 0 ? (
                    <>
                        <p className={classes.skillsLabel}>{t("CustomerCard: skills-label")}</p>
                        <ul className={classes.list}>
                            {requiredSkills!.map(skill => (
                                <li key={skill} className={classes.listItem}>
                                    {(() => {
                                        switch ( skill ) {
                                            case "lawn-mowing": return t("Skill: lawn-mowing");
                                            case "weed-control": return t("Skill: weed-control");
                                            case "hedge-trimming": return t("Skill: hedge-trimming");
                                            case "pruning-of-trees-and-shrubs": return t("Skill: pruning");
                                            case "disposal-of-garden-waste": return t("Skill: disposal-of-garden-waste");
                                            case "other-garden-services": return t("Skill: other-garden-services");
                                        }
                                    })()}
                                </li>
                            ))}
                        </ul>
                    </>
                ) : null}

                {match.order ? (
                    <p className={classes.orderDescription}>“{match.order.description}”</p>
                ) : null}

            </CardContent>

            {actions}

        </Card>
    );
};

function formatDate(t: Translation, date: string): string {
    const formattedDate = moment(date).format("DD.MM.YYYY");

    return `${t("CustomerCard: connection-established")} ${formattedDate}`;
}

function phoneNumberHref(phoneNumber: string): string {
    return `tel:${phoneNumber}`;
}

function gpsHref(address: MatchDocument["customer"]["address"]): string {
    const query = `${address.street}, ${address.zipCode}`;

    return encodeURI(`https://maps.google.com/?q=${query}`);
}

function formatHours(t: Translation, order: NonNullable<MatchDocument["order"]>): string {
    switch ( order.hourInterval ) {
        case "1-2 hours": return t("CustomerCard: hours-1-2");
        case "3-5 hours": return t("CustomerCard: hours-3-5");
        case "more-than-6-hours": return t("CustomerCard: hours-more-than-6");;
    }
}

function formatTools(t: Translation, order: NonNullable<MatchDocument["order"]>): string {
    return order.tools === "helper-tools" ? t("CustomerCard: tools-gardener-tools") : t("CustomerCard: tools-customer-tools");
}

function formatFrequency(t: Translation, order: NonNullable<MatchDocument["order"]>): string {
    if ( order.frequency === "once-every-week" ) return t("CustomerCard: frequency-once-every-week");
    if ( order.frequency === "once-every-second-week" ) return t("CustomerCard: frequency-once-every-second-week");
    if ( order.frequency === "once-every-month" ) return t("CustomerCard: frequency-once-every-month");

    return t("CustomerCard: frequency-once");
}
