import { useEffect, useState } from "react";

import firebase from "firebase/app";

import { GardenerBalanceDocument } from "./gardener-balance.model";

export const useGardenerBalance = (gardenerId: string | null | undefined) => {
    const [state, setState] = useState<GardenerBalanceDocument | null>();
  
    useEffect(() => {
        if ( gardenerId === undefined ) return setState(undefined);
        if ( gardenerId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore().collection("gardenerBalances").where("gardenerId", "==", gardenerId).onSnapshot(snapshot => {
            if ( snapshot.empty ) return setState(null);

            const document = snapshot.docs[0];
            const gardenerBalance = { id: document.id, ...document.data() } as GardenerBalanceDocument;

            setState(gardenerBalance);
        });
    }, [gardenerId]);
  
    return state;
};
