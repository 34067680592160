import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";

import moment from "moment";

import { NotFoundPage } from "../../application/NotFoundPage";
import { LinearProgress } from "../../application/LinearProgress";
import { TitleHeader } from "../../components/page/TitleHeader";
import { BackButton } from "../../components/page/BackButton";
import { useTranslation } from "../../internationalisation/translation.hook";
import { formatTitle } from "../../utility";
import { useTask } from "../../task/tasks.hook";
import { useTasks } from "../../task/TaskProvider";
import { TaskDocument } from "../../task/task.model";
import { IntegratedNewTaskDetailPage } from "../new-task/IntegratedNewTaskDetailPage";
import { ErrorPage } from "../../application/ErrorPage";
import { IntegratedRegistrationTaskDetailPage } from "../registration-task/IntegratedRegistrationTaskDetailPage";
import { IntegratedAppointmentTaskDetailPage } from "../appointment-task/IntegratedAppointmentTaskDetailPage";

export const IntegratedOverviewTaskDetailPage = () => {
    const { taskId, tab } = useParams<{ taskId: string, tab: "current" | "future" }>();
    const { t } = useTranslation();

    const history = useHistory();
    const goToOverview = useCallback(() => {
        history.push(`/overview/${tab}`);
    }, [history, tab]);

    const task = useTask(taskId);
    
    const now = moment().toISOString(true);
    
    const tasks = useTasks();
    const relevantTasks = tasks.filter(task => task.type === "offer-response" || moment(task.performAfterDate).isSameOrBefore(now, "day"));
    
    if ( task === null ) return <NotFoundPage />;
    if ( task === undefined ) return <LinearProgress />;

    const backButton = <BackButton onClick={goToOverview} />;
    const title = <TitleHeader button={backButton}>{formatTitle(t("LeftTabBar: overview-tab"), relevantTasks.length)}</TitleHeader>

    const page = determinePage(task, title, goToOverview);
    if ( !page ) return <ErrorPage message={t("OverviewTaskDetailPage: unrecognized-task-type")} />;

    if ( task.type !== "offer-response" && task.hasBeenPerformed ) return <ErrorPage message={t("OverviewTaskDetailPage: task-has-already-been-performed")} />;

    return page;
};

function determinePage(task: TaskDocument, title: JSX.Element, goToOverview: () => void): JSX.Element | null {
    if ( task.type === "offer-response" ) return <IntegratedNewTaskDetailPage title={title} task={task} goBack={goToOverview} />;
    if ( task.type === "appointment-follow-up" ) return <IntegratedAppointmentTaskDetailPage title={title} task={task} />;
    if ( task.type === "registration-follow-up" ) return <IntegratedRegistrationTaskDetailPage title={title} task={task} />;

    return null;
}
