import { HTMLAttributes } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "24px",

        [theme.breakpoints.down(500)]: {
            padding: "24px 16px",
        },
    },
}));

export const CardContent = (props: HTMLAttributes<HTMLDivElement>) => {
    const classes = useStyles();

    const { className, ...otherProps } = props;

    return (
        <div className={className ? `${classes.root} ${className}` : classes.root} {...otherProps} />
    );
};
