import { useEffect, useState } from "react";

import firebase from "firebase/app";
import { GardeningTaskGardenerViewDocument } from "./gardening-task-gardener-view.model";

export const useGardeningTask = (gardenerId: string, gardeningTaskId: string) => {
    const [state, setState] = useState<GardeningTaskGardenerViewDocument | null>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore().collection("helpers").doc(gardenerId).collection("gardeningTasks").doc(gardeningTaskId).onSnapshot(document => {
            if ( !document.exists ) return setState(null);

            setState({ id: document.id, ...document.data() } as GardeningTaskGardenerViewDocument);
        }, error => {
            console.error(error);

            setState(null);
        });

    }, [gardenerId, gardeningTaskId]);

    return state;
}
