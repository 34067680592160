import { cloneElement } from "react";

import { makeStyles } from "@material-ui/core";
import { FloatingZone } from "./FloatingZone";

export interface FloatingButtonProps {
    children: JSX.Element;
}

const useStyles = makeStyles({
    button: {
        minWidth: "288px",
    },
});

export const FloatingButton = (props: FloatingButtonProps) => {
    const classes = useStyles();

    return (
        <FloatingZone>
            {cloneElement(props.children, { className: classes.button })}
        </FloatingZone>
    );
};
