import { LinearProgress as Progress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    barColorPrimary: {
        backgroundColor: "#2FA52B",
    },
    colorPrimary: {
        backgroundColor: "#afdcae",
    },
});

export const LinearProgress = () => {
    const classes = useStyles();

    const { barColorPrimary, colorPrimary } = classes;

    return (
        <Progress classes={{ barColorPrimary, colorPrimary }} />
    );
};
