import { useCallback } from "react";

import { makeStyles } from "@material-ui/core";

import { MultipleChoiceOption } from ".";

const useStyles = makeStyles({
    list: {
        padding: "8px 0",
        margin: 0,
    },
    listItem: {
        listStyle: "none",

        "& > *": {
            textAlign: "center",
            width: "100%",
            minHeight: "44px",
            fontWeight: 500,
            lineHeight: "125%",
            marginBottom: "16px",
        },
        "&:last-child > *": {
            marginBottom: 0,
        },
    },
});

interface MultipleChoiceGroupProps<T extends string> {
    options: Array<{
        id: T;
        text: string;
    }>;
    value: T[];
    onChange: (value: T[]) => void;
    destructive?: boolean;
}

export const MultipleChoiceGroup = <T extends string>(props: MultipleChoiceGroupProps<T>) => {
    const classes = useStyles();

    const { options, value, onChange, destructive } = props;

    const toggleOption = useCallback((id: T) => {
        const newValue = value.includes(id) ? value.filter(x => x !== id) : [...value, id];
        
        onChange(newValue);
    }, [onChange, value]);

    return (
        <ul className={classes.list}>
            {options.map((option, index) => (
                <li key={option.id} className={classes.listItem}>
                    <MultipleChoiceOption
                        id={option.id}
                        text={option.text}
                        on={!destructive ? value.includes(option.id) : false}
                        onClick={toggleOption}
                        destructive={destructive && (index === (options.length - 1)) ? true : false}
                    />
                </li>
            ))}
        </ul>
    );
};
