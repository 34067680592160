import { PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "rgba(248, 243, 174, 0.5)",
    },
    content: {
        padding: "24px",
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        textAlign: "center",

        [theme.breakpoints.down(500)]: {
            padding: "24px 16px",
        },

        "& > *": {
            marginTop: 0,
            marginBottom: "16px",

            "&:first-child": {
                fontWeight: 500,
            },

            "&:last-child": {
                marginBottom: 0,
            },
        },

        "& a": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#297A1D",
            textDecoration: "none",
    
            "&:hover": {
                color: "#1C5B13",
                textDecoration: "underline",
            },
        },
    },
    dottedSeparator: {
        backgroundImage: "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='-10%25' x2='110%25' y1='0' y2='0' fill='none' rx='4' ry='4' stroke='%23818181' stroke-width='2' stroke-dasharray='1%2c 4' stroke-dashoffset='2' stroke-linecap='butt'/%3e%3c/svg%3e\")",
        height: "1px",
    }
}));

export const CardAlert = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    const { children } = props;

    return (
        <div className={classes.root}>

            <div className={classes.content}>
                {children}
            </div>

            <div className={classes.dottedSeparator} />
        </div>
    );
};
