import { Container } from "@material-ui/core";

import { Message } from "../components/Message";
import { ContentSection } from "./ContentSection";

export interface ErrorPageProps {
    message: string;
}

export const ErrorPage = (props: ErrorPageProps) => {
    const { message } = props;

    return (
        <ContentSection>

            <Container maxWidth="sm" disableGutters>
                <Message text={message} />
            </Container>

        </ContentSection>
    );
};
