import { useCallback, useMemo, useState } from "react";

import { IconButton, makeStyles, Zoom } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import firebase from "firebase/app";

import { CardContent } from "../components/card/CardContent";
import { Slider } from "../components/Slider";
import { GardenerDocument, Skill, Tool } from "./gardener.model";
import { useSnackbar } from "../components/snackbar";
import { NeutralAction } from "../components/card/NeutralAction";
import { PositiveAction } from "../components/card/PositiveAction";
import { CardActions } from "../components/card/CardActions";
import { Error } from "../components/form/Error";
import { ChipSelector } from "../components/chip-selector/ChipSelector";
import { HeaderAwareAnchor } from "../components/HeaderAwareAnchor";
import { useBlockingProgress } from "../components/blocking-progress";
import { useTranslation } from "../internationalisation/translation.hook";
import { Card, CardHeader } from "../components/card";
import { RecursivePartial } from "../types";

const skillOptions: Skill[] = ["lawn-mowing", "weed-control", "hedge-trimming", "pruning-of-trees-and-shrubs", "disposal-of-garden-waste", "other-garden-services"];

const toolOptions: Tool[] = [
    "lawn-mower", "grass-trimmer", "garden-tractor", "verticutter", "other-lawn-mowing-tools",
    "hand-tools", "weed-burner", "other-weed-control-tools",
    "hedge-trimmer", "chainsaw", "other-hedge-trimming-tools",
    "pruning-shears", "telescopic-pole", "scaffolding", "other-pruning-tools",
    "regular-trailer", "large-trailer", "other-disposal-tools",
];

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
        }
    },
    editIcon: {
        fontSize: "24px",
        color: "#297A1D",
    },
});

interface PreferencesCardProps {
    gardener: GardenerDocument;
}

export const PreferencesCard = (props: PreferencesCardProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { gardener } = props;
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const [editing, setEditing] = useState(false);

    const enterEditMode = useCallback(() => {
        setEditing(true);
    }, []);

    const cancelEdit = useCallback(() => {
        setEditing(false);
        setServiceRadius(gardener.taskAgent.serviceRadiusInKilometers);
        setSkills(gardener.taskAgent.skills);
        setTools(gardener.taskAgent.tools);
    }, [gardener]);

    const [serviceRadius, setServiceRadius] = useState(gardener.taskAgent.serviceRadiusInKilometers);

    const serviceRadiusError = useMemo(() => {
        if ( !editing ) return undefined;

        if ( serviceRadius < 10 ) return t("PreferencesCard: must-be-at-least-10-kilometers");

        return undefined;
    }, [t, editing, serviceRadius]);

    const formatServiceRadius = useCallback((value: number) => {
        return `${value} ${t("PreferencesCard: kilometers-abbreviation")}`;
    }, [t]);

    const [skills, setSkills] = useState(gardener.taskAgent.skills);

    const skillsError = useMemo(() => {
        if ( !editing ) return undefined;

        if ( skills.length === 0 ) return t("PreferencesCard: select-at-least-one-task");
        if ( skills.length === 1 && skills.includes("other-garden-services") ) return t("PreferencesCard: select-at-least-one-other-task");

        return undefined;
    }, [t, editing, skills]);

    const formatSkill = useCallback((value: Skill) => {
        switch ( value ) {
            case "lawn-mowing": return t("Skill: lawn-mowing");
            case "weed-control": return t("Skill: weed-control");
            case "hedge-trimming": return t("Skill: hedge-trimming");
            case "pruning-of-trees-and-shrubs": return t("Skill: pruning-of-trees-and-shrubs");
            case "disposal-of-garden-waste": return t("Skill: disposal-of-garden-waste");
            case "other-garden-services": return t("Skill: other-garden-services");
        }
    }, [t]);

    const [tools, setTools] = useState(gardener.taskAgent.tools);

    const formatTool = useCallback((value: Tool) => {
        switch ( value ) {
            case "lawn-mower": return t("Tool: lawn-mower");
            case "grass-trimmer": return t("Tool: grass-trimmer");
            case "garden-tractor": return t("Tool: garden-tractor");
            case "verticutter": return t("Tool: verticutter");
            case "other-lawn-mowing-tools": return t("Tool: other-lawn-mowing-tools");
            case "hand-tools": return t("Tool: hand-tools") ;
            case "weed-burner": return t("Tool: weed-burner") ;
            case "other-weed-control-tools": return t("Tool: other-weed-control-tools");
            case "hedge-trimmer": return t("Tool: hedge-trimmer") ;
            case "chainsaw": return t("Tool: chainsaw") ;
            case "other-hedge-trimming-tools": return t("Tool: other-hedge-trimming-tools");
            case "pruning-shears": return t("Tool: pruning-shears") ;
            case "telescopic-pole": return t("Tool: telescopic-pole") ;
            case "scaffolding": return t("Tool: scaffolding");
            case "other-pruning-tools": return t("Tool: other-pruning-tools");
            case "regular-trailer": return t("Tool: regular-trailer");
            case "large-trailer": return t("Tool: large-trailer");
            case "other-disposal-tools": return t("Tool: other-disposal-tools");
        }
    }, [t]);

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( serviceRadiusError ) return "service-radius-section";
            if ( skillsError ) return "skills-section";

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [serviceRadiusError, skillsError]);

    const gardenerId = gardener.id;

    const preferences = useMemo(() => {
        const value: RecursivePartial<GardenerDocument> = {
            taskAgent: {
                skills,
                tools,
                serviceRadiusInKilometers: serviceRadius,
            },
        };

        return value;
    }, [serviceRadius, skills, tools]);
    const preferencesError = serviceRadiusError || skillsError;

    const saveEdit = useCallback(() => {
        if ( preferencesError ) return scrollToFirstError();

        block();

        firebase.firestore().collection("helpers").doc(gardenerId).set(preferences, { merge: true })
            .then(() => {
                setEditing(false);
            })
            .catch(() => {
                openSnackbar("error", t("PreferencesCard: save-error-message"));
            })
            .then(() => {
                unblock();
            });
    }, [scrollToFirstError, block, unblock, openSnackbar, t, gardenerId, preferences, preferencesError]);

    const editButton = (
        <Zoom appear={false} in={!editing}>
            <IconButton aria-label="edit" onClick={enterEditMode}>
                <EditIcon className={classes.editIcon} />
            </IconButton>
        </Zoom>
    );

    return (
        <Card>
            <CardHeader title={t("PreferencesCard: card-title")} button={editButton} />

            <CardContent>

                <HeaderAwareAnchor id="service-radius-section" className={classes.group}>
                    <label>{t("PreferencesCard: service-radius-label")}</label>
                    <Slider
                        value={serviceRadius}
                        onChange={setServiceRadius}
                        valueLabelFormat={formatServiceRadius}
                        disabled={!editing}
                        min={0}
                        max={100}
                        step={5}
                    />
                    <Error message={serviceRadiusError} animate />
                </HeaderAwareAnchor>

                <HeaderAwareAnchor id="skills-section" className={classes.group}>
                    <label>{t("PreferencesCard: skills-label")}</label>
                    <ChipSelector
                        values={skills}
                        onChange={setSkills}
                        onFormat={formatSkill}
                        options={skillOptions}
                        disabled={!editing}
                    />
                    <Error message={skillsError} animate />
                </HeaderAwareAnchor>

                <div className={classes.group}>
                    <label>{t("PreferencesCard: tools-label")}</label>
                    <ChipSelector
                        values={tools}
                        onChange={setTools}
                        onFormat={formatTool}
                        options={toolOptions}
                        disabled={!editing}
                    />
                </div>

            </CardContent>

            {editing ? (
                <CardActions>
                    <NeutralAction onClick={cancelEdit}>{t("Button: cancel")}</NeutralAction>
                    <PositiveAction onClick={saveEdit}>{t("Button: save")}</PositiveAction>
                </CardActions>
            ) : null}

        </Card>
    )
};
