import { PropsWithChildren } from "react";

import { Dialog as MaterialDialog, makeStyles } from "@material-ui/core";

import { useDialog } from "./DialogProvider";

const useStyles = makeStyles(theme => ({
    paper: {
        maxWidth: `${theme.breakpoints.values.sm}px !important`,
        margin: "24px",

        [theme.breakpoints.down(theme.breakpoints.values.sm + 48)]: {
            maxWidth: "calc(100% - 48px) !important",
        },

        [theme.breakpoints.down(500)]: {
            maxWidth: "calc(100% - 32px) !important",
            margin: "24px 16px",
        },
    },
}));

export const Dialog = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    const { children } = props;

    const { open, closeDialog } = useDialog();

    return (
        <MaterialDialog open={open} onClose={closeDialog} aria-labelledby="dialog-title" aria-describedby="dialog-content" classes={classes} scroll="body" fullWidth>
            {children}
        </MaterialDialog>
    );
};
