import { PropsWithChildren, useEffect } from "react";
import { useParams } from "react-router-dom";

import firebase from "firebase/app";

import { LinearProgress } from "../application/LinearProgress";

interface SameUserProps {
    userId: string;
}

export const SameUser = (props: PropsWithChildren<SameUserProps>) => {
    const params = useParams<{ gardenerId: string }>();
    const sameUser = props.userId === params.gardenerId;

    useEffect(() => {
        if ( sameUser ) return;
        
        firebase.auth().signOut();
    }, [sameUser]);

    if ( !sameUser ) return <LinearProgress />;

    return <>{props.children}</>;
};
