import { useMemo } from "react";

import moment from "moment";

import { FormProps } from "../../../framework/react/FormProvider";
import { useFormDialog } from "../../components/FormDialog";
import { FormItem } from "../../../framework/core/form-item.model";
import { createCreateAppointmentBranch } from "../appointment-task-dialog";
import { createRegistrationTaskBranch } from "./registration-task-branch";
import { AppointmentDocument } from "../../../../../appointment/appointment.model";
import { createActionPage } from "./action-page";
import { createCancelAppointmentBranch } from "./cancel-appointment-branch";
import { createRejectCustomerBranch } from "../reject-customer-dialog/reject-customer-branch";
import { createInspectionActionPage } from "./inspection-action-page";
import { createRegisterInspectionBranch } from "./register-inspection-branch";
import { GardeningTaskGardenerViewDocument } from "../../../../../gardening-task/gardener-view/gardening-task-gardener-view.model";

export enum RegistrationTaskResponseDialogIds {
    SingleChoiceAction = "SingleChoiceAction",

    DidGardenerDoGardening = "DidGardenerDoGardening",
    RegistrationForm = "RegistrationForm",
    HasClearedLargeTools = "HasClearedLargeTools",
    HasClearedManyHours = "HasClearedManyHours",

    DidNotVisitAppointmentDate = "DidNotVisitAppointmentDate",
    DidNotVisitAppointmentDescription = "DidNotVisitAppointmentDescription",

    DidNotVisitSingleChoiceDoesCustomerStillWantHelp = "DidNotVisitSingleChoiceDoesCustomerStillWantHelp",

    SingleChoiceInspectionAction = "SingleChoiceInspectionAction",

    DidVisitAppointmentDate = "DidVisitAppointmentDate",
    DidVisitAppointmentDescription = "DidVisitAppointmentDescription",

    DidVisitSingleChoiceDoesCustomerStillWantHelp = "DidVisitSingleChoiceDoesCustomerStillWantHelp",
}

export const useRegistrationTaskResponseDialog = (hasHistory: boolean, hasRecentHistory: boolean, hasMultipleAppointments: boolean, isFuture: boolean, gardeningTask: GardeningTaskGardenerViewDocument, appointment: AppointmentDocument, now: string, onConfirm: FormProps<FormItem>["onSubmit"]) => {
    const today = moment(now).startOf("day").toISOString(true);

    const page = useMemo(() => {

        const createNextAppointmentBranch = createCreateAppointmentBranch(today, {
            datePickerAppointmentDate: RegistrationTaskResponseDialogIds.DidVisitAppointmentDate,
            textAreaAppointmentDescription: RegistrationTaskResponseDialogIds.DidVisitAppointmentDescription,
        }, undefined, "Vi har planlagt næste besøg");

        const didNotPlanNextAppointmentYet = createRegisterInspectionBranch(hasMultipleAppointments, now);

        const closeTaskAfterInspectionBranch = createRejectCustomerBranch(hasHistory, hasRecentHistory, {
            singleChoiceDoesCustomerStillWantHelp: RegistrationTaskResponseDialogIds.DidVisitSingleChoiceDoesCustomerStillWantHelp,
        });

        const inspectionActionPage = createInspectionActionPage(createNextAppointmentBranch, didNotPlanNextAppointmentYet, closeTaskAfterInspectionBranch, {
            singleChoiceAction: RegistrationTaskResponseDialogIds.SingleChoiceInspectionAction,
        });

        const registrationTaskBranch = !isFuture ? createRegistrationTaskBranch(gardeningTask, appointment, inspectionActionPage, {
            DidGardenerDoGardening: RegistrationTaskResponseDialogIds.DidGardenerDoGardening,

            RegistrationForm: RegistrationTaskResponseDialogIds.RegistrationForm,
            HasClearedLargeTools: RegistrationTaskResponseDialogIds.HasClearedLargeTools,
            HasClearedManyHours: RegistrationTaskResponseDialogIds.HasClearedManyHours,
        }) : null;

        const changeDateBranch = createCreateAppointmentBranch(today, {
            datePickerAppointmentDate: RegistrationTaskResponseDialogIds.DidNotVisitAppointmentDate,
            textAreaAppointmentDescription: RegistrationTaskResponseDialogIds.DidNotVisitAppointmentDescription,
        }, appointment.description, "Vi har ændret datoen for besøget");

        const cancelAppointmentBranch = createCancelAppointmentBranch(hasMultipleAppointments, now);

        const closeTaskBranch = createRejectCustomerBranch(hasHistory, hasRecentHistory, {
            singleChoiceDoesCustomerStillWantHelp: RegistrationTaskResponseDialogIds.DidNotVisitSingleChoiceDoesCustomerStillWantHelp,
        });

        return createActionPage(registrationTaskBranch, changeDateBranch, cancelAppointmentBranch, closeTaskBranch, appointment, {
            singleChoiceAction: RegistrationTaskResponseDialogIds.SingleChoiceAction,
        });
    }, [hasHistory, hasRecentHistory, hasMultipleAppointments, isFuture, today, gardeningTask, appointment, now]);

    return useFormDialog(page, onConfirm);
};
