import moment from "moment";

import { FormProps } from "../../framework/react/FormProvider";
import { useFormDialog } from "../components/FormDialog";
import { PageFormItem, GroupFormItem, InfoFormItem, NoticeFormItem } from "../components";
import { CreateAppointmentBranch, createCreateAppointmentBranch } from "./appointment-task-dialog";

export enum CreateAppointmentFormIds {
    DatePickerDate = "date-picker-date",
    TextAreaDescription = "text-area-description",
}

export type CreateAppointmentForm =
    GroupFormItem<Array<
        | WarningPage
        | CreateAppointmentBranch
    >>

type WarningPage = PageFormItem<GroupFormItem<[NoticeFormItem, InfoFormItem]>>;

export const useCreateAppointmentDialog = (hasOpenAppointments: boolean, now: string, onConfirm: FormProps<CreateAppointmentForm>["onSubmit"]) => {
    const today = moment(now).startOf("day").toISOString(true);

    const warningPage: WarningPage = {
        type: "page",
        title: "Du har andre aktuelle besøg hos kunden",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "notice",
                    render: "warning",
                    html: "Du har allerede planlagt andre besøg hos kunden.",
                },
                {
                    type: "info",
                    render: "default",
                    text: `
                        Vil du ændre allerede planlagte besøg, kan du finde og besvare “Hvordan gik dit besøg?”-opgaven på din oversigt.<br />
                        <br />
                        Vil du gerne planlægge endnu et besøg hos kunden, kan du vælge “Næste”.
                    `,
                },
            ],
        },
    };

    const createAppointmentBranch = createCreateAppointmentBranch(today, {
        datePickerAppointmentDate: CreateAppointmentFormIds.DatePickerDate,
        textAreaAppointmentDescription: CreateAppointmentFormIds.TextAreaDescription,
    });

    const pages = [];
    if ( hasOpenAppointments ) pages.push(warningPage);
    pages.push(createAppointmentBranch);

    const form: CreateAppointmentForm = {
        type: "group",
        render: "all at once",
        items: pages,
    };

    return useFormDialog(form, onConfirm);
};
