import { HTMLAttributes } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "32px",

        [theme.breakpoints.down(500)]: {
            padding: "32px 16px",
        },

        "& > *": {
            marginBottom: "96px",

            "&:last-child": {
                marginBottom: 0,
            },
        },
    },
}));

export const ContentSection = (props: HTMLAttributes<HTMLDivElement>) => {
    const classes = useStyles();

    const { className, ...otherProps } = props;

    return (
        <div
            className={className ? `${classes.root} ${className}` : classes.root}
            {...otherProps}
        />
    );
};
