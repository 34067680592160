import { PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        "& > *": {
            marginBottom: "32px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        },
    },
});

export const Form = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    return (
        <form noValidate autoComplete="off" className={classes.root}>

            {props.children}

        </form>
    );
};
