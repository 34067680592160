import { useMemo } from "react";

import { makeStyles } from "@material-ui/core";

import { CardContent } from "../../components/card/CardContent";
import { PositiveAction } from "../../components/card/PositiveAction";
import { CardActions } from "../../components/card/CardActions";
import { Notice } from "../../components/notice/Notice";
import { useTranslation } from "../../internationalisation/translation.hook";
import { Card, CardHeader } from "../../components/card";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
        }
    },
});

interface CommissionPayoutCardProps {
    balance: number;
    amountScheduled: number | null;
    payoutInProgress: boolean;
    hasBankAccount: boolean;
    onRequestPayout: () => void;
}

export const CommissionPayoutCard = (props: CommissionPayoutCardProps) => {
    const classes = useStyles();
    const { t, formatCurrency } = useTranslation();

    const { payoutInProgress, amountScheduled, balance, hasBankAccount, onRequestPayout } = props;

    const notice = useMemo(() => {
        if ( payoutInProgress ) return <Notice>{t("CommissionPayoutCard: payout-in-progress-notice")}</Notice>;
        if ( balance <= 0 ) return <Notice>{t("CommissionPayoutCard: balace-not-positive-notice")}</Notice>;
        if ( !hasBankAccount ) return <Notice>{t("CommissionPayoutCard: needs-bank-information-notice")}</Notice>;

        return null;
    }, [t, payoutInProgress, balance, hasBankAccount]);

    const balanceView = useMemo(() => {
        if ( payoutInProgress && amountScheduled === null ) {
            return (
                <div className={classes.group}>
                    <label>{t("CommissionPayoutCard: balance-scheduled-label")}</label>
                    <span>{formatCurrency(balance)}</span>
                </div>
            );
        }

        if ( payoutInProgress && amountScheduled !== null ) {
            return (
                <>
                    <div className={classes.group}>
                        <label>{t("CommissionPayoutCard: balance-progress-remaining-label")}</label>
                        <span>{formatCurrency(balance - amountScheduled)}</span>
                    </div>

                    <div className={classes.group}>
                        <label>{t("CommissionPayoutCard: balance-progress-scheduled-label")}</label>
                        <span>{formatCurrency(amountScheduled)}</span>
                    </div>
                </>
            );
        }

        return (
            <div className={classes.group}>
                <label>{t("CommissionPayoutCard: balance-label")}</label>
                <span>{formatCurrency(balance)}</span>
            </div>
        )
    }, [payoutInProgress, amountScheduled, balance, classes.group, formatCurrency, t]);

    return (
       <Card>
            <CardHeader title={t("CommissionPayoutCard: card-title")} />

            <CardContent>

                {balanceView}

                <div className={classes.group}>
                    <label>{t("CommissionPayoutCard: explanation-label")}</label>
                    <span>{t("CommissionPayoutCard: explanation-text")}</span>
                </div>

                {notice}

            </CardContent>

            <CardActions>
                <PositiveAction disabled={Boolean(notice)} onClick={onRequestPayout}>{t("CommissionPayoutCard: payout-button")}</PositiveAction>
            </CardActions>

        </Card>
    );
};
