import moment from "moment";

import { FormProps } from "../../framework/react/FormProvider";
import { useFormDialog } from "../components/FormDialog";
import { PageFormItem, GroupFormItem, InfoFormItem, SingleChoiceFormItem, VerticalButtonsMultiChoiceFormItem, TextAreaFormItem } from "../components";

export enum NewTaskResponseFormIds {
    SingleChoiceAreYouInterested = "single-choice-are-you-interested",
    MultiChoiceWhyIsTheTaskIrrelevant = "multi-choice-why-is-the-task-irrelevant",
    TextAreaReasonForDeclining = "text-area-reason-for-declining",
}

export type NewTaskResponseForm =
    PageFormItem<
        GroupFormItem<[
            InfoFormItem,
            SingleChoiceFormItem<[
                DeclineTaskPage,
                AcceptTaskConfirmPage,
            ]>
        ]>
    >;

type DeclineTaskPage =
    PageFormItem<
        VerticalButtonsMultiChoiceFormItem<[
            null,
            null,
            null,
            null,
            TextAreaFormItem,
        ],
            DeclineTaskConfirmPage
        >
    >

type DeclineTaskConfirmPage =
    PageFormItem<
        InfoFormItem
    >

type AcceptTaskConfirmPage =
    PageFormItem<
        SingleChoiceFormItem<[
            InfoFormItem,
            InfoFormItem,
        ]>
    >

export const useNewTaskResponseDialog = (now: string, onConfirm: FormProps<NewTaskResponseForm>["onSubmit"]) => {
    const declineTaskConfirmPage: DeclineTaskConfirmPage = {
        type: "page",
        title: "Afvis opgave",
        item: {
            type: "info",
            render: "default",
            text: `
                Du vil ikke længere se denne opgave på din oversigt.<br />
                <br />
                Vi vil forsøge at finde en anden havemand til opgaven.
            `,
        },
    };

    const declineTaskPage: DeclineTaskPage = {
        type: "page",
        title: "Hvorfor er opgaven ikke noget for dig?",
        item: {
            id: NewTaskResponseFormIds.MultiChoiceWhyIsTheTaskIrrelevant,
            type: "multi-choice",
            render: "vertical-buttons",
            selectedChoiceIndexes: [],
            dirty: false,
            questionText: "Årsag til afvisning (vælg gerne flere)",
            answerTexts: ["Opgaven er ikke tydelig nok", "Jeg har ikke tid til opgaven", "Jeg kan ikke løse opgaven", "Opgaven er for langt væk / for lille", "Andet"],
            answerItems: [
                null,
                null,
                null,
                null,
                {
                    id: NewTaskResponseFormIds.TextAreaReasonForDeclining,
                    type: "text-area",
                    label: "Uddyb dit valg af “Andet”",
                    placeholder: "Fortæl os hvorfor du afviser opgaven",
                    value: "",
                    dirty: false,
                },
            ],
            afterItem: declineTaskConfirmPage,
        },
    };

    const acceptTaskConfirmPage: AcceptTaskConfirmPage = {
        type: "page",
        title: "Accepter opgave",
        item: {
            type: "single-choice",
            mode: "regular",
            render: "void",
            selectedChoiceIndex: moment(now).hours() < 17 ? 0 : 1,
            questionText: "Er det før eller efter 17:00?",
            answerTexts: ["Før 17:00", "Efter 17:00"],
            answerItems: [
                {
                    type: "info",
                    render: "default",
                    text: `
                        Vi fortæller kunden, at du gerne vil hjælpe dem.<br />
                        <br />
                        Du forpligter dig til at <strong>ringe til kunden i dag</strong> med henblik på at aftale et besøg.<br />
                        <br />
                        Vi anbefaler, at du lægger en besked på telefonsvareren og sender en sms, hvis ikke du får fat i kunden.<br />
                        <br />
                        Du vil se en “Har I lavet en aftale?”-opgave på din oversigt. Gå ind på opgaven for at besvare den, når du har ringet til kunden.
                    `,
                },
                {
                    type: "info",
                    render: "default",
                    text: `
                        Vi fortæller kunden, at du gerne vil hjælpe dem.<br />
                        <br />
                        Du forpligter dig til at <strong>ringe til kunden i morgen</strong> med henblik på at aftale et besøg.<br />
                        <br />
                        Vi anbefaler, at du lægger en besked på telefonsvareren og sender en sms, hvis ikke du får fat i kunden.<br />
                        <br />
                        Du vil se en “Har I lavet en aftale?”-opgave på din oversigt. Gå ind på opgaven for at besvare den, når du har ringet til kunden.<br />
                        <br />
                        Vi minder dig om opgaven med en sms på dagen.
                    `,
                },
            ],
        },
    };

    return useFormDialog({
        type: "page",
        title: "Vil du acceptere opgaven?",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "info",
                    render: "default",
                    text: `
                        Du bestemmer selv, om du vil acceptere opgaven. Vi har bare brug for et svar fra dig hurtigst muligt.
                    `,
                },
                {
                    id: NewTaskResponseFormIds.SingleChoiceAreYouInterested,
                    type: "single-choice",
                    mode: "switch page",
                    render: "horizontal-buttons",
                    selectedChoiceIndex: null,
                    dirty: false,
                    questionText: null,
                    answerTexts: ["Nej tak", "Ja tak"],
                    answerItems: [
                        declineTaskPage,
                        acceptTaskConfirmPage,
                    ],
                },
            ],
        },
    }, onConfirm);
};
