import { PropsWithChildren, cloneElement } from "react";

import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        fontSize: "16px",
        fontWeight: 600,
        textTransform: "none",
    },
    icon: {
        width: "24px",
        height: "24px",
        marginRight: "8px",
    },
});

export interface TextButtonBaseProps extends PropsWithChildren<{}> {
    className?: string;
    icon?: JSX.Element;
    onClick?: () => void;
}

export const TextButtonBase = (props: TextButtonBaseProps) => {
    const classes = useStyles();

    const icon = props.icon ? cloneElement(props.icon, { className: classes.icon }) : null;

    return (
        <Button className={props.className ? `${classes.root} ${props.className}` : classes.root} onClick={props.onClick}>
            {icon}
            {props.children}
        </Button>
    );
};
