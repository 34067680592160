import { makeStyles } from "@material-ui/core";
import { PropsWithChildren } from "react";

const useStyles = makeStyles({
    root: {
        marginBottom: "64px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > *": {
            marginBottom: "32px !important",

            "&:last-child": {
                marginBottom: "0 !important",
            },
        },
    },
});

export const Section = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    const { children } = props;

    return (
        <section className={classes.root}>
            {children}
        </section>
    );
};
