import { NeutralAction, PositiveAction } from "../components/card";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../components/dialog";
import { useTranslation } from "../internationalisation/translation.hook";

interface AcceptAgreementDialogProps {
    onConfirm: () => void;
}

export const AcceptAgreementDialog = (props: AcceptAgreementDialogProps) => {
    const { onConfirm } = props;
    const { closeDialog } = useDialog();
    const { t } = useTranslation();

    return (
        <Dialog>

            <DialogHeader>{t("AcceptAgreementDialog: dialog-header")}</DialogHeader>
            <DialogContent>{t("AcceptAgreementDialog: dialog-content")}</DialogContent>

            <DialogActions>
                <NeutralAction onClick={closeDialog}>{t("Button: cancel")}</NeutralAction>
                <PositiveAction onClick={onConfirm}>{t("AcceptAgreementDialog: accept-button")}</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};
