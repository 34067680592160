import { GroupFormItem, PageFormItem, SingleChoiceFormItem } from "../../components";

export interface ActionPageIds {
    singleChoiceAction: string;
}

export type InspectionActionPage<Pages extends PageFormItem<any>[]> =
    PageFormItem<
        GroupFormItem<[
            SingleChoiceFormItem<Pages>,
        ]>
    >;

export const createInspectionActionPage = (createAppointmentAfterInspectionPage: PageFormItem<any>, createNewAppointmentConfirmationPage: PageFormItem<any>, closeTaskPage: PageFormItem<any>, formIds: ActionPageIds): InspectionActionPage<any[]> => {
    const answerTexts = [];
    answerTexts.push("Vi har planlagt næste besøg");
    answerTexts.push("Vi har endnu ikke aftalt næste besøg");
    answerTexts.push("Opgaven skal lukkes");

    const answerItems: PageFormItem<any>[] = [];
    answerItems.push(createAppointmentAfterInspectionPage);
    answerItems.push(createNewAppointmentConfirmationPage);
    answerItems.push(closeTaskPage);

    const actionPage: InspectionActionPage<any[]> = {
        type: "page",
        title: "Jeg har besigtiget opgaven",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    id: formIds.singleChoiceAction,
                    type: "single-choice",
                    mode: "switch page",
                    render: "vertical-buttons",
                    selectedChoiceIndex: null,
                    dirty: false,
                    questionText: "Hvad vil du gerne registrere?",
                    answerTexts,
                    answerItems,
                    destructive: true,
                },
            ],
        },
    };

    return actionPage;
};
