import { AppointmentDocument } from "../../../../../appointment/appointment.model";
import { GardeningTaskGardenerViewDocument } from "../../../../../gardening-task/gardener-view/gardening-task-gardener-view.model";
import { FormItem } from "../../../framework/core/form-item.model";
import { GroupFormItem, InfoFormItem, NoticeFormItem, PageFormItem, RegistrationFormItem, SingleChoiceFormItem } from "../../components";
import { HorizontalButtonsFormItem } from "../../form-item/HorizontalButtonsFormItemComponent";

export interface RegistrationTaskResponseDialogIds {
    DidGardenerDoGardening: string;

    RegistrationForm: string;
    HasClearedLargeTools: string;
    HasClearedManyHours: string;
}

export const createRegistrationTaskBranch = (gardeningTask: GardeningTaskGardenerViewDocument, appointment: AppointmentDocument, inspectionBranch: FormItem, ids: RegistrationTaskResponseDialogIds) => {

    const clearLargeToolsPage: PageFormItem<GroupFormItem<[InfoFormItem, HorizontalButtonsFormItem]>> = {
        type: "page",
        title: "Du har registreret store redskaber",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "info",
                    render: "default",
                    text: `
                        Det er en del af konceptet, at I kan aftale brugen af taksten “havemandens store redskaber” i særlige tilfælde.<br />
                        <br />
                        For at undgå reklamationer bør du aftale brugen af taksten med kunden på forhånd og informere om den højere timepris.
                    `,
                },
                {
                    id: ids.HasClearedLargeTools,
                    type: "horizontal-buttons",
                    mode: "switch page",
                    selectedChoiceIndex: null,
                    dirty: false,
                    label: "Er kunden indforstået med, at du bruger denne takst?",
                    answerTexts: ["Nej", "Ja"],
                },
            ]
        },
    };

    const clearManyHoursLines: string[] = [];
    if ( gardeningTask.data.hourInterval === "1-2 hours" ) clearManyHoursLines.push("Du har brugt over 2,5 timer på denne opgave og det er mere, end hvad kunden angav i sin bestilling.");
    if ( gardeningTask.data.hourInterval === "3-5 hours" ) clearManyHoursLines.push("Du har brugt over 6 timer på denne opgave og det er mere, end hvad kunden angav i sin bestilling.");
    if ( gardeningTask.data.hourInterval === "more-than-6-hours" ) clearManyHoursLines.push("Du har brugt over 8 timer på denne opgave. Når opgaven er så stor, er forventningsafstemning med kunden ekstra vigtig.");
    clearManyHoursLines.push("For at undgå reklamationer bør du aftale det med kunden på forhånd, hvis der skal bruges mange timer på en opgave.");
    const clearManyHoursPage: PageFormItem<GroupFormItem<[InfoFormItem, HorizontalButtonsFormItem]>> = {
        type: "page",
        title: "Du har registreret mange timer",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "info",
                    render: "default",
                    text: clearManyHoursLines.join("<br /><br />"),
                },
                {
                    id: ids.HasClearedManyHours,
                    type: "horizontal-buttons",
                    mode: "switch page",
                    selectedChoiceIndex: null,
                    dirty: false,
                    label: "Er kunden indforstået med antallet af timer?",
                    answerTexts: ["Nej", "Ja"],
                },
            ]
        },
    };

    const registrationForm: PageFormItem<GroupFormItem<[InfoFormItem, RegistrationFormItem]>> = {
        type: "page",
        title: "Jeg har udført arbejde",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "info",
                    render: "default",
                    text: "Vi fakturerer kunden på baggrund af din registrering, så det er vigtigt, at du har udfyldt oplysningerne korrekt.",
                },
                {
                    id: ids.RegistrationForm,
                    type: "registration",
                    customerName: appointment.customer.name,
                    appointmentDate: appointment.scheduledDate,
                    tasks: [{
                        description: "",
                        descriptionDirty: false,
                        duration: undefined,
                        durationDirty: false,
                        tools: undefined,
                        toolsDirty: false,
                    }],

                    clearLargeToolsId: ids.HasClearedLargeTools,
                    clearLargeToolsPage,

                    orderedHours: gardeningTask.data.hourInterval,
                    clearManyHoursId: ids.HasClearedManyHours,
                    clearManyHoursPage,
                },
            ],
        },
    };

    const haveYouDoneGardeningWorkPage: PageFormItem<GroupFormItem<[NoticeFormItem, SingleChoiceFormItem<[FormItem, FormItem]>]>> = {
        type: "page",
        title: "Jeg har besøgt kunden",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "notice",
                    render: "info",
                    html: "Vælg nej, hvis du kun har foretaget en besigtigelse.",
                },
                {
                    id: ids.DidGardenerDoGardening,
                    type: "single-choice",
                    mode: "switch page",
                    render: "horizontal-buttons",
                    selectedChoiceIndex: null,
                    dirty: false,
                    questionText: "Har du udført havearbejde?",
                    answerTexts: ["Nej", "Ja"],
                    answerItems: [inspectionBranch, registrationForm],
                }
            ],
        },
    }

    return haveYouDoneGardeningWorkPage;
};
