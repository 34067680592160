import { makeStyles } from "@material-ui/core";

import { CardContent } from "../../components/card/CardContent";
import { Skill } from "../../gardener/gardener.model";
import { Translation, useTranslation } from "../../internationalisation/translation.hook";
import { Card, CardHeader } from "../../components/card";
import { Address, OfferResponseTaskDocument } from "../../task/task.model";
import { GardeningTaskDocument } from "../../gardening-task/gardening-task.model";

const useStyles = makeStyles({
    customer: {
        "& > *": {
            marginBottom: "16px",
        },

        "& > *:last-child": {
            marginBottom: 0,
        }
    },
    link: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#297A1D",
        textDecoration: "none",

        "&:hover": {
            color: "#1C5B13",
            textDecoration: "underline",
        },
    },
    orderInformation: {
        "& > p": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#4A4A4A",
            marginTop: 0,
            marginBottom: "4px",

            "&:last-child": {
                marginBottom: 0,
            },
        },
    },
    skillsLabel: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        lineHeight: "125%",
        marginTop: 0,
        marginBottom: "4px !important",
    },
    list: {
        listStyle: "none",
        paddingLeft: "8px",
        marginTop: 0,
        marginBottom: "16px",
    },
    listItem: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        lineHeight: "125%",

        "&::before": {
            content: '"\\2022"',
            fontWeight: 600,
            marginRight: "8px",
        },
    },
    orderDescription: {
        fontSize: "16px",
        fontWeight: 400,
        fontStyle: "italic",
        color: "#4A4A4A",
        whiteSpace: "pre-wrap",
        marginTop: 0,
    },
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            lineHeight: "125%",
            whiteSpace: "pre-wrap",

            "& > a": {
                fontSize: "16px",
                fontWeight: 500,
                color: "#297A1D",
                textDecoration: "none",
        
                "&:hover": {
                    color: "#1C5B13",
                    textDecoration: "underline",
                },
            },
        },
    },
});

interface TaskOfferCardProps {
    status?: JSX.Element | null;
    title: string;
    task: OfferResponseTaskDocument;
    actions?: JSX.Element | null;
}

export const TaskOfferCard = (props: TaskOfferCardProps) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const { task, title, status, actions } = props;

    const requiredSkills = task.task.requiredSkills.sort((a, b) => {
        const sortOrder: Skill[] = ["lawn-mowing", "weed-control", "hedge-trimming", "pruning-of-trees-and-shrubs", "disposal-of-garden-waste", "other-garden-services"];
        return sortOrder.indexOf(a) - sortOrder.indexOf(b);
    });

    return (
        <Card>

            {status}

            <CardHeader title={title} />

            <CardContent className={classes.customer}>


                <div className={classes.group}>
                    <label>Kunde</label>
                    <span>{task.customerName.trim().split(" ")[0]}</span>
                </div>

                <div className={classes.group}>
                    <label>Lokation</label>
                    <span>
                        {task.task.address.street}, {task.task.address.zipCode} (<a href={gpsHref(task.task.address.coordinates)} target="_blank" rel="noreferrer" className={classes.link}>Se på kort</a>)
                    </span>
                </div>

                <div className={classes.orderInformation}>
                    <p>{t("CustomerCard: customer-specified-hours")} {formatHours(t, task.task.hourInterval)}</p>
                    <p>{formatTools(t, task.task.tools)}</p>
                    <p>{formatFrequency(t, task.task.frequency)}</p>
                </div>

                <p className={classes.skillsLabel}>{t("CustomerCard: skills-label")}</p>
                <ul className={classes.list}>
                    {requiredSkills!.map(skill => (
                        <li key={skill} className={classes.listItem}>
                            {(() => {
                                switch ( skill ) {
                                    case "lawn-mowing": return t("Skill: lawn-mowing");
                                    case "weed-control": return t("Skill: weed-control");
                                    case "hedge-trimming": return t("Skill: hedge-trimming");
                                    case "pruning-of-trees-and-shrubs": return t("Skill: pruning");
                                    case "disposal-of-garden-waste": return t("Skill: disposal-of-garden-waste");
                                    case "other-garden-services": return t("Skill: other-garden-services");
                                }
                            })()}
                        </li>
                    ))}
                </ul>

                <p className={classes.orderDescription}>“{task.task.description}”</p>

            </CardContent>

            {actions}

        </Card>
    );
};

function gpsHref(coordinates: Address["coordinates"]): string {
    const query = `${coordinates.latitude},${coordinates.longitude}`;

    return encodeURI(`https://maps.google.com/?q=${query}`);
}

function formatHours(t: Translation, hourInterval: GardeningTaskDocument["data"]["hourInterval"]): string {
    switch ( hourInterval ) {
        case "1-2 hours": return t("CustomerCard: hours-1-2");
        case "3-5 hours": return t("CustomerCard: hours-3-5");
        case "more-than-6-hours": return t("CustomerCard: hours-more-than-6");;
    }
}

function formatTools(t: Translation, tools: GardeningTaskDocument["data"]["tools"]): string {
    return tools === "helper-tools" ? t("CustomerCard: tools-gardener-tools") : t("CustomerCard: tools-customer-tools");
}

function formatFrequency(t: Translation, frequency: GardeningTaskDocument["data"]["frequency"]): string {
    if ( frequency === "once-every-week" ) return t("CustomerCard: frequency-once-every-week");
    if ( frequency === "once-every-second-week" ) return t("CustomerCard: frequency-once-every-second-week");
    if ( frequency === "once-every-month" ) return t("CustomerCard: frequency-once-every-month");

    return t("CustomerCard: frequency-once");
}
