import { useCallback, useMemo, useState } from "react";

import AddIcon from "@material-ui/icons/AddCircleOutline";
import { da } from "date-fns/locale";

import { NeutralAction } from "../../components/card/NeutralAction";
import { PositiveAction } from "../../components/card/PositiveAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, DialogSection, useDialog } from "../../components/dialog";
import { useAnimation } from "../../components/validation/animation.hook";
import { Validation, validateDate, validateDateNotInTheFuture, validateMandatory } from "../../components/validation/validation";
import { TaskSection } from "./TaskSection";
import { useTranslation } from "../../internationalisation/translation.hook";
import { Task } from "../registration.model";
import { AlternativeHint, DateField, Group, Label, TextField } from "../../components/form";
import { GreenTextButton } from "../../components/text-button";
import { DialogParagraph } from "../../components/dialog/DialogParagraph";
import moment from "moment";
import { validateTools } from "./create-registration.validation";
import { Collapse } from "@material-ui/core";

export interface CreateRegistrationDialogProps {
    initialViewModel: CreateRegistrationDialogViewModel;
    onConfirm: (viewModel: CreateRegistrationDialogViewModel) => void;
    onCancel: () => void;
}

export interface CreateRegistrationDialogViewModel {
    customer: {
        id: string;
        name: string;
    },
    appointmentDate: string;
    appointmentDateEnabled: boolean,
    appointmentDateDirty: boolean,
    tasks: Array<{
        description: string;
        descriptionDirty: boolean;
        duration: number | undefined;
        durationDirty: boolean;
        tools: Task["tools"] | undefined;
        toolsDirty: boolean;
    }>,
    now: string;
}

export interface CreateRegistrationDialogErrorModel {
    any: boolean;
    appointmentDate: string | undefined;
    tasks: Array<{
        description: string | undefined;
        duration: string | undefined;
        tools: Validation | undefined;
    }>;
}

export const CreateRegistrationDialog = (props: CreateRegistrationDialogProps) => {
    const { initialViewModel, onConfirm, onCancel } = props;
    const [viewModel, setViewModel] = useState(initialViewModel);
    const { t } = useTranslation();

    const changeAppointmentDate = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, appointmentDate: value }));
    }, [])

    const markAppointmentDateDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, appointmentDateDirty: true }));
    }, []);

    const errorModel = useMemo<CreateRegistrationDialogErrorModel>(() => {

        const appointmentDateError = [validateDate(viewModel.appointmentDate), validateDateNotInTheFuture(viewModel.appointmentDate, viewModel.now)].find(Boolean);

        const tasksError: CreateRegistrationDialogErrorModel["tasks"] = viewModel.tasks.map(task => {
            const descriptionError = [validateMandatory(task.description)].find(Boolean);
            const durationError = [validateMandatory(task.duration)].find(Boolean);
            const toolsError = [validateTools(task.tools, viewModel.customer.name)].find(Boolean);

            return { description: descriptionError, duration: durationError, tools: toolsError };
        });
        const anyTasksError = tasksError.reduce((result: boolean, x) => Boolean(result || x.description || x.duration || x.tools?.severity === "error"), false);

        return {
            any: Boolean(appointmentDateError || anyTasksError),
            appointmentDate: appointmentDateError,
            tasks: tasksError,
        };
    }, [viewModel]);

    const addTask = useCallback(() => {
        setViewModel(viewModel => ({
            ...viewModel,
            tasks: [
                ...viewModel.tasks,
                {
                    description: "",
                    descriptionDirty: false,
                    duration: undefined,
                    durationDirty: false,
                    tools: undefined,
                    toolsDirty: false,
                },
            ],
        }));
    }, []);

    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( errorModel.appointmentDate ) return "appointment-date-section";

            for (let index = 0; index < errorModel.tasks.length; index++) {
                if ( errorModel.tasks[index].description ) return `task-${index}-description-section`;
                if ( errorModel.tasks[index].duration ) return `task-${index}-duration-section`;
                if ( errorModel.tasks[index].tools ) return `task-${index}-tools-section`;
            }

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [errorModel]);

    const confirm = useCallback(() => {
        if ( document.activeElement ) {
            (document.activeElement as HTMLInputElement).blur();
        }

        if ( errorModel.any ) {
            skipValidationAnimation();
            setViewModel(viewModel => ({
                ...viewModel,
                appointmentDateDirty: true,
                tasks: viewModel.tasks.map(task => ({
                    ...task,
                    descriptionDirty: true,
                    durationDirty: true,
                    toolsDirty: true,
                })),
            }));

            return setTimeout(scrollToFirstError, 100);
        }

        onConfirm(viewModel);
    }, [skipValidationAnimation, scrollToFirstError, onConfirm, errorModel.any, viewModel]);

    const today = moment(viewModel.now).startOf("day").toDate();

    return (
        <Dialog>
            <DialogHeader>{t("CreateRegistrationDialog: dialog-header")}</DialogHeader>

            <DialogContent>

                <DialogSection>

                    <DialogParagraph>{t("CreateRegistrationDialog: dialog-text")}</DialogParagraph>

                    <div id="customer-section">
                        <Label htmlFor="customer" style={{ marginBottom: "8px" }}>{t("CreateRegistrationDialog: customer-label")}</Label>
                        <Group>
                            <TextField id="customer" value={viewModel.customer.name} disabled />
                        </Group>
                    </div>

                    <div id="appointment-date-section">
                        <Label htmlFor="appointment-date" style={{ marginBottom: "8px" }}>{t("CreateRegistrationDialog: appointment-date-label")}</Label>

                        {viewModel.appointmentDateEnabled ? (
                            <Group>
                                <DateField id="appointment-date" value={viewModel.appointmentDate} onChange={changeAppointmentDate} onBlur={markAppointmentDateDirty} placeholder="DD-MM-YYYY" maximumDate={today} locale={da} />

                                <Collapse in={Boolean(viewModel.appointmentDateDirty && errorModel.appointmentDate)} timeout={validationAnimationDuration}>
                                    <AlternativeHint message={errorModel.appointmentDate} />
                                </Collapse>
                            </Group>
                        ) : (
                            <Group>
                                <TextField id="appointment-date" value={formatAppointmentDate(viewModel.appointmentDate)} disabled />
                            </Group>
                        )}

                    </div>

                </DialogSection>


                {viewModel.tasks.map((_task, index) => (
                    <TaskSection
                        key={index}
                        viewModel={viewModel}
                        setViewModel={setViewModel}
                        errorModel={errorModel}
                        validationAnimationDuration={validationAnimationDuration}
                        index={index}
                    />
                ))}

                <GreenTextButton icon={<AddIcon />} onClick={addTask}>{t("CreateRegistrationDialog: add-task-button-text")}</GreenTextButton>

            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>{t("Button: cancel")}</NeutralAction>
                <PositiveAction onClick={confirm}>{t("CreateRegistrationDialog: dialog-confirm")}</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

export const useCreateRegistrationDialog = (initialViewModel: CreateRegistrationDialogViewModel | undefined, onConfirm: CreateRegistrationDialogProps["onConfirm"]) => {
    const { openDialog, closeDialog } = useDialog();

    const confirm: CreateRegistrationDialogProps["onConfirm"] = useCallback(data => {
        closeDialog();

        onConfirm(data);
    }, [closeDialog, onConfirm]);

    return useCallback(() => {
        if ( !initialViewModel ) return;

        openDialog(<CreateRegistrationDialog initialViewModel={initialViewModel} onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm, initialViewModel]);
};

function formatAppointmentDate(appointmentDate: string): string {
    const day = moment(appointmentDate).format("dddd");
    const dayTitleCased = day.slice(0, 1).toUpperCase() + day.slice(1);

    const date = moment(appointmentDate).format("D MMMM YYYY");

    return `${dayTitleCased} d. ${date}`;
}
