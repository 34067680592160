import { useCallback, useMemo } from "react";

import { Collapse, MenuItem } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";

import { Label } from "../../components/form/Label";
import { Group } from "../../components/form/Group";
import { AlternativeHint } from "../../components/form/Hint";
import { TextField } from "../../components/form/InputField";
import { useTranslation } from "../../internationalisation/translation.hook";
import { Dropdown } from "../../components/form";
import { Task, Tools } from "../registration.model";
import { DialogSection } from "../../components/dialog";
import { DialogSectionHeader } from "../../components/dialog/DialogSectionHeader";
import { GreenIconButton } from "../../components/icon-button/GreenIconButton";
import { RegistrationFormItem } from "../../components/form-item/client/components";
import { useForm } from "../../components/form-item/framework/react/FormProvider";
import { validateMandatory } from "../../components/validation/validation";
import { validateTools } from "./create-registration.validation";

export interface AdaptedTaskSectionProps {
    item: RegistrationFormItem;
    index: number;
}

export const AdaptedTaskSection = (props: AdaptedTaskSectionProps) => {
    const { item, index } = props;
    const { t } = useTranslation();

    const { validationAnimationDuration, updateItem } = useForm();

    const deleteTask = useCallback(() => {
        updateItem(item.id, (item: RegistrationFormItem) => {
            if ( item.tasks.length === 1 ) {
                return {
                    ...item,
                    tasks: [{
                        description: "",
                        descriptionDirty: false,
                        duration: undefined,
                        durationDirty: false,
                        tools: undefined,
                        toolsDirty: false,
                    }],
                };
            }

            const tasks = [...item.tasks];
            tasks.splice(index, 1);

            return { ...item, tasks: tasks };
        });
    }, [updateItem, item.id, index]);

    const changeTask = useCallback((task: Partial<RegistrationFormItem["tasks"][0]>) => {
        updateItem(item.id, (item: RegistrationFormItem) => ({
            ...item,
            tasks: item.tasks.map((x, i) => {
                if ( i !== index ) return x;

                return { ...x, ...task };
            }),
        }));
    }, [updateItem, item.id, index]);

    const changeDescription = useCallback((value: string) => {
        changeTask({ description: value });
    }, [changeTask])

    const markDescriptionDirty = useCallback(() => {
        changeTask({ descriptionDirty: true });
    }, [changeTask]);

    const changeDuration = useCallback((value: number) => {
        changeTask({ duration: value });
    }, [changeTask])

    const markDurationDirty = useCallback(() => {
        changeTask({ durationDirty: true });
    }, [changeTask]);

    const renderDurationPlaceholder = useCallback(() => t("CreateRegistrationDialog: task-duration-placeholder"), [t]);

    const durationOptions = useMemo(() => {
        const options = [];

        const numberOfHours = 24;
        for (let i = 1; i <= 4 * numberOfHours; i++) {
            const value = i * 15;

            const hours = Math.floor(i / 4).toString().padStart(1, "0");
            const minutes = ((i % 4) * 15).toString().padStart(2, "0");

            const label = `${hours}t ${minutes}m`;

            options.push({ value, label });
        }

        return options;
    }, []);

    const changeTools = useCallback((value: Task["tools"]) => {
        changeTask({ tools: value });
    }, [changeTask])

    const markToolsDirty = useCallback(() => {
        changeTask({ toolsDirty: true });
    }, [changeTask]);

    const renderToolsPlaceholder = useCallback(() => t("CreateRegistrationDialog: task-tools-placeholder"), [t]);

    const toolsOptions = useMemo(() => {
        const options: Array<{ value: Task["tools"], label: string }> = [
            { value: "customers-tools", label: t("CreateRegistrationDialog: task-tools-customer-tools-option") },
            { value: "helper-tools", label: t("CreateRegistrationDialog: task-tools-gardener-tools-option") },
            { value: "large-tools", label: t("CreateRegistrationDialog: task-tools-gardener-large-tools-option") },
        ];

        return options;
    }, [t]);

    const task = item.tasks[index];
    const descriptionError = validateTaskDescription(task.description);
    const durationError = validateTaskDuration(task.duration);
    const toolsError = validateTaskTools(task.tools, item.customerName);

    return (
        <DialogSection>

            <DialogSectionHeader text={`Opgave ${index + 1}`} button={<GreenIconButton icon={<DeleteIcon />} onClick={deleteTask} />} />

            <div id={`${item.id}-task-${index}-description-section`}>
                <Label htmlFor={`${item.id}-task-${index}-description`} style={{ marginBottom: "8px" }}>{t("CreateRegistrationDialog: task-description-label")}</Label>
                <Group error={Boolean(item.tasks[index].descriptionDirty && descriptionError)}>
                    <TextField id={`${item.id}-task-${index}-description`} value={item.tasks[index].description} onChange={changeDescription} onBlur={markDescriptionDirty} placeholder="Beskrivelsen bliver vist på kundens faktura" />

                    <Collapse in={Boolean(item.tasks[index].descriptionDirty && descriptionError)} timeout={validationAnimationDuration}>
                        <AlternativeHint message={descriptionError} />
                    </Collapse>
                </Group>
            </div>

            <div id={`${item.id}-task-${index}-duration-section`}>
                <Label htmlFor={`${item.id}-task-${index}-duration`} style={{ marginBottom: "8px" }}>{t("CreateRegistrationDialog: task-duration-label")}</Label>
                <Group error={Boolean(item.tasks[index].durationDirty && durationError)}>
                    <Dropdown labelId={`${item.id}-task-${index}-duration`} variant="outlined" value={item.tasks[index].duration ?? ""} onChange={changeDuration} onBlur={markDurationDirty} displayEmpty renderValue={item.tasks[index].duration ? undefined : renderDurationPlaceholder}>
                        {durationOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        ))}
                    </Dropdown>

                    <Collapse in={Boolean(item.tasks[index].durationDirty && durationError)} timeout={validationAnimationDuration}>
                        <AlternativeHint message={durationError} />
                    </Collapse>
                </Group>
            </div>

            <div id={`${item.id}-task-${index}-tools-section`}>
                <Label htmlFor={`${item.id}-task-${index}-tools`} style={{ marginBottom: "8px" }}>{t("CreateRegistrationDialog: task-tools-label")}</Label>
                <Group error={Boolean(item.tasks[index].toolsDirty && toolsError?.severity === "error")}>
                    <Dropdown labelId={`${item.id}-task-${index}-tools`} variant="outlined" value={item.tasks[index].tools ?? ""} onChange={changeTools} onBlur={markToolsDirty} displayEmpty renderValue={item.tasks[index].tools ? undefined : renderToolsPlaceholder}>
                        {toolsOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                        ))}
                    </Dropdown>

                    <Collapse in={Boolean(item.tasks[index].toolsDirty && toolsError)} timeout={validationAnimationDuration}>
                        <AlternativeHint severity={toolsError?.severity} message={toolsError?.message} />
                    </Collapse>
                </Group>
            </div>

        </DialogSection>
    );
};

export const validateTaskDescription = (description: string): string | undefined => {
    return validateMandatory(description);
}

export const validateTaskDuration = (durationInMinutes: number | undefined): string | undefined => {
    return validateMandatory(durationInMinutes);
}

export const validateTaskTools = (tools: Tools | undefined, customerName: string) => {
    return validateTools(tools, customerName);
};
