import { Section } from "../section/Section";
import { SectionHeader } from "../section/SectionHeader";
import { ListSectionAltItem, ListSectionAltItemProps } from "./ListSectionAltItem";
import { Message } from "../Message";

export interface ListSectionAltProps {
    title: string;
    items: Omit<ListSectionAltItemProps, "index" | "onClick">[];
    placeholder: string;
    onClick: (index: number) => void;
}

export const ListSectionAlt = (props: ListSectionAltProps) => {
    const { title, items, placeholder, onClick } = props;

    return (
        <Section>
            <SectionHeader>{title}</SectionHeader>

            {items.length === 0 ? (
                <Message text={placeholder} />
            ) : null}

            {items.map((item, index) => (
                <ListSectionAltItem
                    key={index}
                    heading={item.heading}
                    subheading={item.subheading}
                    secondaryHeading={item.secondaryHeading}
                    alert={item.alert}
                    index={index}
                    onClick={onClick}
                />
            ))}

        </Section>
    );
};
