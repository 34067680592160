import moment from "moment";

import { TaskDocument } from "./task/task.model";

export const formatTitle = (text: string, count: number): string => {
    if ( count === 0 ) return text;

    return `${text} (${count})`;
};

export const determineDaysUntilTaskDeadline = (task: TaskDocument, now: string) => {
    return Math.floor(moment(task.performAfterDate).diff(now, "hours") / 24);
}

export const formatDate = (date: string): string => {
    return moment(date).format("D MMMM YYYY");
}
