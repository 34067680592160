import { Container, makeStyles } from "@material-ui/core";

import { ContentSection } from "../application/ContentSection";
import { TitleHeader } from "../components/page/TitleHeader";
import { useTranslation } from "../internationalisation/translation.hook";
import { LinearProgress } from "../application/LinearProgress";
import { useGardenerBalance } from "./payout/gardener-balance.hook";
import { GardenerDocument } from "./gardener.model";
import { usePersonalInformation } from "./personal-information.hook";
import { PersonalInformationCard } from "./PersonalInformationCard";
import { PreferencesCard } from "./PreferencesCard";
import { GardenerPausedSwitch } from "./GardenerPausedSwitch";
import { GardenerCard } from "./GardenerCard";
import { CommissionPayoutCardWrapper } from "./payout/CommissionPayoutCardWrapper";
import { useGardenerPayout } from "./payout/gardener-payout.hook";

const useStyles = makeStyles({
    container: {
        "& > *": {
            marginBottom: "32px",
        },
    
        "& > *:last-child": {
            marginBottom: 0,
        },
    },
});

interface ProfilePageProps {
    gardener: GardenerDocument;
}

export const ProfilePage = (props: ProfilePageProps) => {
    const classes = useStyles();

    const { gardener } = props;
    const { t } = useTranslation();

    const gardenerBalance = useGardenerBalance(gardener.id);
    const payout = useGardenerPayout(gardener.id);
    const personalInformation = usePersonalInformation(gardener.id);

    if ( gardenerBalance === undefined || payout === undefined || personalInformation === undefined ) return <LinearProgress />;

    return (
        <>
            <TitleHeader>{t("ProfilePage: page-title")}</TitleHeader>

            <ContentSection>
                <Container maxWidth="sm" disableGutters className={classes.container}>

                    <GardenerPausedSwitch gardener={gardener} />
                    <GardenerCard gardener={gardener} />

                    {gardenerBalance && personalInformation ? (
                        <CommissionPayoutCardWrapper gardenerBalance={gardenerBalance} payout={payout} personalInformation={personalInformation} />
                    ) : null}

                    {personalInformation ? (
                        <PersonalInformationCard personalInformation={personalInformation} />
                    ) : null}

                    <PreferencesCard gardener={gardener} />

                </Container>
            </ContentSection>
        </>
    )
};
