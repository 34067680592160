import { useEffect, useMemo, useState } from "react";

import moment from "moment";
import firebase from "firebase/app";

import { RegistrationDocument } from "../registration.model";

export const useRegistrations = (gardenerId: string, fromDate: string | null, toDate: string | null) => {
    const [state, setState] = useState<RegistrationDocument[]>();

    useEffect(() => {
        setState(undefined);

        let query = firebase.firestore().collection("registrations").where("helperId", "==", gardenerId);
        if ( fromDate ) query = query.where("date", ">=", fromDate);
        if ( toDate ) query = query.where("date", "<=", toDate);

        return query.onSnapshot(collection => {
            const documents = collection.docs.map(document => ({ id: document.id, ...document.data() } as RegistrationDocument));
                
            setState(documents);
        });

    }, [gardenerId, fromDate, toDate]);

    return state;
};

export const useCustomerRegistrations = (gardenerId: string | null | undefined, customerId: string | null | undefined): RegistrationDocument[] | null | undefined => {
    const [state, setState] = useState<RegistrationDocument[] | null>();

    useEffect(() => {
        if ( gardenerId === null || customerId === null ) return setState(null);
        if ( gardenerId === undefined || customerId === undefined ) return setState(undefined);

        setState(undefined);

        firebase.firestore().collection("registrations")
            .where("helperId", "==", gardenerId)
            .where("customerId", "==", customerId)
            .onSnapshot(collection => {
                const documents = collection.docs.map(document => ({ id: document.id, ...document.data() } as RegistrationDocument))

                setState(documents);
            });

    }, [gardenerId, customerId]);

    return state;
}

export const useRegistrationHistory = (gardenerId: string | null | undefined, customerId: string | null | undefined, now: string): { hasHistory: boolean, hasRecentHistory: boolean } | null | undefined => {
    const registrations = useCustomerRegistrations(gardenerId, customerId);

    return useMemo(() => {
        if ( registrations === undefined ) return undefined;
        if ( registrations === null ) return null;

        const hasHistory = registrations.length > 0;
        const hasRecentHistory = registrations.filter(x => x.creationDate > moment(now).subtract(30, "days").toISOString(true)).length > 0;

        return { hasHistory, hasRecentHistory };
    }, [registrations, now]);
}
