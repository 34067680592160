import firebase from "firebase/app";
import { useEffect, useState } from "react";

import { GardenerDocument } from "./gardener.model";

export const useGardener = (gardenerId: string | null | undefined) => {
    const [state, setState] = useState<GardenerDocument | null>();
  
    useEffect(() => {
        if ( gardenerId === undefined ) return setState(undefined);
        if ( gardenerId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore().collection("helpers").doc(gardenerId).onSnapshot(document => {
            if ( !document.exists ) return setState(null);

            const helper = { id: document.id, ...document.data() } as GardenerDocument;

            setState(helper);
        }, () => setState(null));
    }, [gardenerId]);
  
    return state;
};
