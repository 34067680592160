import { ButtonProps, makeStyles } from "@material-ui/core";

import { ButtonBase } from "./ButtonBase";

const useStyles = makeStyles({
    root: {
        backgroundColor: "#297A1D",
        color: "#FFFFFF",

        "&:hover": {
            backgroundColor: "#1c5514",
        },
        
        "&:disabled": {
            backgroundColor: "#818181",
            color: "#FFFFFF",
        },
    },
});

export const GreenButton = (props: ButtonProps) => {
    const classes = useStyles();

    const { className, ...otherProps } = props;

    return (
        <ButtonBase
            className={className ? `${classes.root} ${className}` : classes.root}
            variant="contained"
            {...otherProps}
        />
    );
};
