import { useEffect, useState } from "react";

import firebase from "firebase/app";

import { PersonalInformationDocument } from "./personal-information.model";

export const usePersonalInformation = (gardenerId: string | null | undefined) => {
    const [state, setState] = useState<PersonalInformationDocument | null>();
  
    useEffect(() => {
        if ( gardenerId === undefined ) return setState(undefined);
        if ( gardenerId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore().collection("personalInformation").doc(gardenerId).onSnapshot(document => {
            if ( !document.exists ) return setState(null);

            const personalInformation = { id: document.id, ...document.data() } as PersonalInformationDocument;

            setState(personalInformation);
        });
    }, [gardenerId]);
  
    return state;
};
