export const customerPrices = {
    customerTools: 350,
    gardenerTools: 385,
    gardenerLargeTools: 525,
};

export const gardenerPrices = {
    customerTools: 175,
    gardenerTools: 210,
    gardenerLargeTools: 350,
};

export const gardenerPricesIfVatLiable = {
    customerTools: 218.75,
    gardenerTools: 262.50,
    gardenerLargeTools: 350,
};
