import { HTMLAttributes } from "react";
import Burger from "react-css-burger";

import { makeStyles, useMediaQuery } from "@material-ui/core";

// TODO: Replace with library that does not use css variables:
// - clean up react-css-burger.d.ts
// - clean up ie11CustomProperties

const useStyles = makeStyles({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "48px",
        height: "48px",
        cursor: "pointer",
        "-webkitTapHighlightColor": "transparent",
    },
    hover: {
        transition: "opacity 0.15s linear",

        "&:hover": {
            opacity: 0.7,
        },
    },
});

interface HamburgerButtonProps extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
    active: boolean;
    toggle: () => void;
}

export const HamburgerButton = (props: HamburgerButtonProps) => {
    const classes = useStyles();
    const noHoverCapability = useMediaQuery("(hover: none)");
    const componentClassNames = noHoverCapability ? classes.root : `${classes.root} ${classes.hover}`;

    const { active, toggle, className, ...otherProps } = props;

    return (
        <div className={className ? `${componentClassNames} ${className}` : componentClassNames} onClick={toggle} {...otherProps}>
            <Burger
                active={active}
                burger="collapse"
                color="#ffffff"
                scale={0.6}
                style={{ margin: 0 }}
            />
        </div>
    );
};
