import { useCallback } from "react";

import { makeStyles } from "@material-ui/core";

import { Chip } from "./Chip";

const useStyles = makeStyles({
    root: {
        padding: "8px 0",
        margin: "-8px",

        "& > *": {
            margin: "8px",
        },
    },
});

interface ChipSelectorProps<T> {
    values: T[];
    onChange: (values: T[]) => void;
    options: T[];
    onFormat: (value: T) => string;
    disabled?: boolean;
}

export const ChipSelector = <T extends unknown>(props: ChipSelectorProps<T>) => {
    const classes = useStyles();

    const { values, onChange, options, onFormat, disabled } = props;

    const toggle = useCallback((value: T) => {
        if ( !values.includes(value) ) return onChange([...values, value]);

        const valuesExcludingValue = values.filter(x => x !== value);
        onChange(valuesExcludingValue);
    }, [onChange, values]);

    return (
        <div className={classes.root}>
            {options.map((value, index) => {
                const on = values.includes(value);
                const label = onFormat(value);

                return (
                    <Chip
                        key={index}
                        value={value}
                        on={on}
                        label={label}
                        onToggle={toggle}
                        disabled={disabled}
                    />
                );
            })}
        </div>
    );
};
