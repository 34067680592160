import { useCallback, useMemo } from "react";

import { Container } from "@material-ui/core";

import { ContentSection } from "../application/ContentSection";
import { CustomerCard } from "./CustomerCard";
import { Section } from "../components/section/Section";
import { MatchDocument } from "./match.model";
import { useTranslation } from "../internationalisation/translation.hook";
import { GreenButton, VerticalButtons } from "../components/new-button";
import { CreateRegistrationDialogProps, useCreateRegistrationDialog } from "../registration/create-registration-dialog/CreateRegistrationDialog";
import { CreateRegistrationDialogViewModel } from "../registration/create-registration-dialog/CreateRegistrationDialog";
import { GardenerDocument } from "../gardener/gardener.model";
import { TaskResponseData } from "../task/task-response-action.hook";
import { CreateAppointmentForm, CreateAppointmentFormIds, useCreateAppointmentDialog } from "../components/form-item/client/application/CreateAppointmentDialog";
import { FormItem } from "../components/form-item/framework/core/form-item.model";
import { FormController } from "../components/form-item/framework/core/form.controller";
import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { DatePickerFormItem, TextAreaFormItem } from "../components/form-item/client/components";
import { FloatingButton } from "../components/floating-button/FloatingButton";

interface CustomerDetailPageProps {
    gardener: GardenerDocument;
    customer: MatchDocument;
    hasOpenAppointments: boolean;
    createRegistration: CreateRegistrationDialogProps["onConfirm"];
    taskResponseAction: (data: TaskResponseData | string) => void;
    now: string;
    canCreateAppointment: boolean;
}

export const CustomerDetailPage = (props: CustomerDetailPageProps) => {
    const { gardener, customer: match, hasOpenAppointments, createRegistration, taskResponseAction, now, canCreateAppointment } = props;
    const { t } = useTranslation();

    const initialCreateRegistrationDialogViewModel = useMemo<CreateRegistrationDialogViewModel>(() => ({
        customer: {
            id: match.customer.id,
            name: match.customer.name,
        },
        appointmentDate: "",
        appointmentDateEnabled: true,
        appointmentDateDirty: false,
        tasks: [{
            description: "",
            descriptionDirty: false,
            duration: undefined,
            durationDirty: false,
            tools: undefined,
            toolsDirty: false,
        }],
        now: now,
    }), [match, now])

    const openCreateRegistrationDialog = useCreateRegistrationDialog(initialCreateRegistrationDialogViewModel, createRegistration);

    const createAppointmentActionAdapter = useCallback<FormProps<CreateAppointmentForm>["onSubmit"]>((controller, form): void => {
        const data = mapCreateAppointmentFormToTaskResponseData(controller, form, match.customer.id);

        taskResponseAction(data);
    }, [taskResponseAction, match.customer.id]);

    const openCreateAppointmentDialog = useCreateAppointmentDialog(hasOpenAppointments, now, createAppointmentActionAdapter);

    return (
        <ContentSection>
            <Container maxWidth="sm" disableGutters>

                <Section>
                    <CustomerCard title={match.customer.name} customer={match} />

                    {gardener.allowRegistrationBackdoor ? (
                        <FloatingButton>
                            <VerticalButtons>
                                <GreenButton onClick={openCreateRegistrationDialog}>{t("CreateRegistrationDialog: dialog-confirm")}</GreenButton>
                            </VerticalButtons>
                        </FloatingButton>
                    ) : canCreateAppointment ? (
                        <FloatingButton>
                            <VerticalButtons>
                                <GreenButton onClick={openCreateAppointmentDialog}>{t("CustomerDetailPage: create-appointment-button")}</GreenButton>
                            </VerticalButtons>
                        </FloatingButton>
                    ) : null}

                </Section>

            </Container>
        </ContentSection>
    );
};

const mapCreateAppointmentFormToTaskResponseData = (controller: FormController, form: FormItem, customerId: string): TaskResponseData => {
    const date = (controller.getItem(CreateAppointmentFormIds.DatePickerDate, form) as DatePickerFormItem).value;
    const description = (controller.getItem(CreateAppointmentFormIds.TextAreaDescription, form) as TextAreaFormItem).value;

    return {
        action: "create additional appointment",
        customerId,
        date,
        description,
    };
};
