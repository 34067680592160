import { useCallback } from "react";

import { makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "24px",
        cursor: "pointer",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        
        "&:hover": {
            backgroundColor: "#EBF3EA",
        },
    },
    list: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        [theme.breakpoints.down(500)]: {
            flexDirection: "column",
            textAlign: "center",
        },
    },
    leftPanel: {
        marginRight: "8px",

        [theme.breakpoints.down(500)]: {
            marginRight: 0,
            marginBottom: "24px",
        },
    },
    heading: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#4A4A4A",
        marginTop: 0,
        marginBottom: "4px",

        "&:last-child": {
            marginBottom: 0,
        },
    },
    subheading: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#818181",
        margin: 0,
    },
    secondaryHeading: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        margin: 0,
    },
}));

export interface ListSectionAltItemProps {
    heading: string;
    subheading?: string | JSX.Element;
    secondaryHeading?: string | JSX.Element;
    alert?: JSX.Element,
    index: number;
    onClick: (index: number) => void;
}

export const ListSectionAltItem = (props: ListSectionAltItemProps) => {
    const classes = useStyles();

    const { heading, subheading, secondaryHeading, alert, index, onClick } = props;

    const onSelectCustomer = useCallback(() => {
        onClick(index);
    }, [onClick, index]);

    return (
        <div>
            {alert}
            <Paper className={classes.root} onClick={onSelectCustomer}>

                <div className={classes.list}>

                    <div className={classes.leftPanel}>
                        <h3 className={classes.heading}>{heading}</h3>
                        {subheading ? <p className={classes.subheading}>{subheading}</p> : null}
                    </div>

                    {secondaryHeading ? <h4 className={classes.secondaryHeading}>{secondaryHeading}</h4> : null}
                </div>

            </Paper>
        </div>
    );
};
