import { createContext, PropsWithChildren, useContext, useRef } from "react";

import { TaskDocument } from "./task.model";
import { useTasksThatAreOpenByGardener } from "./tasks.hook";
import { LinearProgress } from "../application/LinearProgress";

type Task = Extract<TaskDocument, { type: "offer-response" | "appointment-follow-up" | "registration-follow-up" }>;

const Context = createContext<Task[] | null | undefined>(undefined);

interface TaskProviderProps {
    userId: string | null;
}

export const TaskProvider = (props: PropsWithChildren<TaskProviderProps>) => {
    const types = useRef<Task["type"][]>(["offer-response", "appointment-follow-up", "registration-follow-up"]).current;
    const tasks = useTasksThatAreOpenByGardener(props.userId, types);

    if ( tasks === undefined ) return <LinearProgress />;

    return (
        <Context.Provider value={tasks}>
            {props.children}
        </Context.Provider>
    );
};

export const useTasks = (): Task[] => {
    return useContext(Context) as Task[];
};
