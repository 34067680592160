import { useCallback, useMemo } from "react";

import { Collapse, MenuItem } from "@material-ui/core";

import { AlternativeHint, Dropdown, Group, Label } from "../../../form";
import { useForm } from "../../framework/react/FormProvider";
import { FormItemSupport } from "../../framework/core/form-item.support";
import { Validation, validateMandatory } from "../../../validation/validation";

export interface DropdownFormItem<Value> {
    id: string;
    type: "dropdown";
    label: string;
    placeholder: string;
    options: DropdownFormItemValue<Value>[];
    warningOptions: string[]; // TODO: should live in validation instead
    value: DropdownFormItemValue<Value> | null,
    dirty: boolean;
}

export interface DropdownFormItemValue<Value> {
    id: string;
    label: string;
    value: Value;
}

interface DropdownFormItemComponentProps<Value> {
    item: DropdownFormItem<Value>;
}

export const DropdownFormItemComponent = <Value extends DropdownFormItemValue<any>>(props: DropdownFormItemComponentProps<Value>) => {
    const { controller, validationAnimationDuration, updateItem } = useForm();
    const { item } = props;

    const updateValue = useCallback((id: string) => {
        updateItem(item.id, (state: DropdownFormItem<Value>) => ({
            ...state,
            value: state.options.find(x => x.id === id) ?? null,
        }));
    }, [updateItem, item.id]);

    const markDirty = useCallback(() => {
        updateItem(item.id, state => controller.markDirty(state));
    }, [controller, updateItem, item.id]);

    const message = useMemo(() => validate(item), [item]);

    const renderPlaceholder = useCallback(() => item.placeholder, [item]);

    return (
        <div id={`${item.id}-section`}>
            <Label htmlFor={item.id} style={{ marginBottom: "8px" }}>{item.label}</Label>
            <Group error={Boolean(item.dirty && message)}>
                <Dropdown labelId={item.id} variant="outlined" value={item.value?.id ?? ""} onChange={updateValue} onBlur={markDirty} displayEmpty renderValue={item.value ? undefined : renderPlaceholder}>
                    {item.options.map(option => (
                        <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>
                    ))}
                </Dropdown>

                <Collapse in={Boolean(item.dirty && message)} timeout={validationAnimationDuration}>
                    <AlternativeHint severity={message?.severity} message={message?.message} />
                </Collapse>
            </Group>
        </div>
    );
};

export class DropdownFormItemSupport<Value> implements FormItemSupport<DropdownFormItem<DropdownFormItemValue<Value>>> {
    supportedType = "dropdown" as const;
    updateChildren = undefined;

    markDirty(item: DropdownFormItem<DropdownFormItemValue<Value>>): DropdownFormItem<DropdownFormItemValue<Value>> {
        return { ...item, dirty: true };
    }

    getAllChildren() {
        return [];
    }

    getActiveChildren() {
        return [];
    }

    blocksParent(item: DropdownFormItem<DropdownFormItemValue<Value>>): boolean {
        return validate(item)?.severity === "error";
    }
}

const validate = (item: DropdownFormItem<DropdownFormItemValue<any>>): Validation => {
    return [validateCustomer(item.value?.id, item.warningOptions)].find(Boolean)
}

const validateCustomer = (customerId: string | undefined, customerWarningOptionIds: string[]): Validation => {
    const error = validateMandatory(customerId);
    if ( error ) return { severity: "error", message: error };

    if ( customerId && customerWarningOptionIds.includes(customerId) ) return { severity: "warning", message: "Du har allerede en aftale med denne kunde" };

    return undefined;
};
