import { useMemo } from "react";

import { makeStyles } from "@material-ui/core";

import { FormItemSupport } from "../../framework/core/form-item.support";

export interface NoticeFormItem {
    type: "notice",
    html: string;
    render: "default" | "info" | "warning" | "danger";
}

const useStyles = makeStyles({
    root: {
        padding: "16px",
        borderRadius: "24px",
        lineHeight: "125%",

        "& strong": {
            fontWeight: 600,
        },

        "& a": {
            fontWeight: 500,
            color: "#297A1D",
            textDecoration: "none",
        
            "&:hover": {
                color: "#1C5B13",
                textDecoration: "underline",
            },
        },

        "& ul": {
            listStyle: "none",
            paddingLeft: "8px",
            margin: 0,

            "& > li": {
                fontSize: "16px",
                fontWeight: 400,
                color: "#4A4A4A",
                lineHeight: "125%",
                paddingLeft: "13px",
            
                "&::before": {
                    content: '"\\2022"',
                    fontWeight: 600,
                    marginRight: "8px",
                    marginLeft: "-13px",
                },
            },
        },
    },
    default: {
        border: "1px solid rgba(0, 0, 0, 0.15)",
    },
    info: {
        backgroundColor: "#0076EB14",
    },
    warning: {
        backgroundColor: "#E37F091F",
    },
    danger: {
        backgroundColor: "#F4433614",
    },
});

interface NoticeFormItemComponentProps {
    item: NoticeFormItem;
}

export const NoticeFormItemComponent = (props: NoticeFormItemComponentProps) => {
    const classes = useStyles();
    const { item } = props;

    const html = useMemo(() => ({ __html: item.html }), [item.html]);

    const renderClass = (() => {
        switch ( item.render ) {
            case "default": return classes.default;
            case "info": return classes.info;
            case "warning": return classes.warning;
            case "danger": return classes.danger;
        }
    })();

    return <div dangerouslySetInnerHTML={html} className={`${classes.root} ${renderClass}`} />;
};

export class NoticeFormItemSupport implements FormItemSupport<NoticeFormItem> {
    supportedType = "notice" as const;
    updateChildren = undefined;
    markDirty = undefined;

    getAllChildren() {
        return [];
    }

    getActiveChildren() {
        return [];
    }

    blocksParent(): boolean {
        return false;
    }
}
