import { useEffect, useState } from "react";

import firebase from "firebase/app";

import { GardenerPayoutDocument } from "./gardener-payout.model";

export const useGardenerPayout = (gardenerId: string | null | undefined) => {
    const [state, setState] = useState<GardenerPayoutDocument | null>();
  
    useEffect(() => {
        if ( gardenerId === undefined ) return setState(undefined);
        if ( gardenerId === null ) return setState(null);

        setState(undefined);

        return firebase.firestore()
            .collection("gardenerPayouts")
                .where("gardenerId", "==", gardenerId)
                .where("status", "in", ["payout-planned", "payout-processing"])
                .limit(1)
            .onSnapshot(snapshot => {
            if ( snapshot.empty ) return setState(null);

            const document = snapshot.docs[0];
            const payout = { id: document.id, ...document.data() } as GardenerPayoutDocument;

            setState(payout);
        });
    }, [gardenerId]);
  
    return state;
};
