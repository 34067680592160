export const defaultTranslation = {
    "AppointmentTaskFaq: question-1": "Hvordan skal jeg forsøge at komme i kontakt med kunden?",
    "AppointmentTaskFaq: answer-1": "Det er en del af konceptet, at du skal ringe til kunden for at aftale et besøg.\n\nVi anbefaler, at du efter første opkald også lægger en besked på telefonsvareren og sender en sms, hvis ikke du får fat i kunden.\n\nDu bestemmer selv, om du udover at ringe også vil have dialog med kunden via sms.",
    "AppointmentTaskFaq: question-2": "Hvor mange gange skal jeg forsøge at kontakte kunden?",
    "AppointmentTaskFaq: answer-2": "Det er en del af konceptet, at du skal ringe til kunden over 1-3 dage.\n\nVi anbefaler, at du ringer 1 gang om dagen i tre dage.\n\nDu bestemmer selv, om du vil ringe mere end én gang om dagen.\n\nDu bestemmer selv, om du vil opgive inden 3. dag.",
    "AppointmentTaskFaq: question-3": "Hvad nu hvis det er for sent på dagen til at ringe?",
    "AppointmentTaskFaq: answer-3": "Accepterer du en opgave efter 17:00, så beder systemet dig først ringe til kunden dagen efter for at aftale et besøg.\n\nDu bestemmer selv, om du alligevel vil ringe samme aften.",
    "AppointmentTaskFaq: question-4": "Hvordan bruger jeg taksten “havemandens store redskaber”?",
    "AppointmentTaskFaq: question-5": "Må jeg medbringe en medhjælper?",

    "FaqPage: overview-faq-header": "Spørgsmål til oversigten",
    "FaqPage: new-task-faq-header": "Spørgsmål til “Ny opgave”",
    "FaqPage: appointment-task-faq-header": "Spørgsmål til “Har I lavet en aftale?”",
    "FaqPage: registration-task-faq-header": "Spørgsmål til “Hvordan gik dit besøg?”",

    "NewTaskDetailPage: expired-message": "Opgaven er udløbet, fordi du ikke besvarede den i tide",
    "NewTaskDetailPage: declined-message": "Du har allerede afvist denne opgave",
    "NewTaskDetailPage: accepted-message": "Du har allerede accepteret denne opgave",

    "CurrentTasksTab: exceeded-deadline-header": "Ikke besvaret til tiden",
    "CurrentTasksTab: today-header": "I dag",
    "CurrentTasksTab: today-placeholder": "Du har ingen opgaver i dag",
    "CurrentTasksTab: tomorrow-header": "I morgen",
    "CurrentTasksTab: tomorrow-placeholder": "Du har ingen opgaver i morgen",

    "FutureTasksTab: future-header": "Fremtidige opgaver",
    "FutureTasksTab: future-placeholder": "Du har ingen fremtidige opgaver",

    "CurrentTasksTab: new-task-title": "Ny opgave",
    "CurrentTasksTab: appointment-task-title": "Har I lavet en aftale?",
    "CurrentTasksTab: registration-task-title": "Hvordan gik dit besøg?",
    "CurrentTasksTab: new-task-format-hours-singular": "Svar inden for 1 time",
    "CurrentTasksTab: new-task-format-hours-plural": "Svar inden for $HOURS timer",
    "CurrentTasksTab: new-task-format-minutes-singular": "Svar inden for 1 minut",
    "CurrentTasksTab: new-task-format-minutes-plural": "Svar inden for $MINUTES minutter",
    "CurrentTasksTab: response-future": "Svar $DATE",
    "CurrentTasksTab: response-today": "Svar i dag",
    "CurrentTasksTab: response-tomorrow": "Svar i morgen",
    "CurrentTasksTab: response-missing": "Mangler svar",
    "CurrentTasksTab: days-ago-singular": "1 dag siden",
    "CurrentTasksTab: days-ago-plural": "$DAYS dage siden",
    "CurrentTasksTab: task-response-action": "Besvar opgave",
    "CurrentTasksTab: task-response-error-message": "Der skete en fejl. Besvarelsen er ikke blevet modtaget.",
    "CurrentTasksTab: task-response-success-message": "Besvarelsen er modtaget",

    "OverviewTaskDetailPage: unrecognized-task-type": "Opgavens type genkendes ikke",
    "OverviewTaskDetailPage: task-has-already-been-performed": "Opgaven er ikke længere aktuel",
    "OverviewTaskDetailPage: task-could-not-be-found": "Opgaven kunne ikke findes",

    "Shared: no-longer-connected": "Du er ikke længere tilknyttet denne kunde",

    "TopBar: profile-link": "Profil",
    "TopBar: ask-for-help-link": "Bed om hjælp",
    "TopBar: faq-link": "Ofte stillede spørgsmål",
    "TopBar: agreement-link": "Samarbejdsaftale",
    "TopBar: log-out-link": "Log ud",

    "LeftTabBar: overview-tab": "Oversigt",
    "LeftTabBar: registrations-tab": "Tidsregistreringer",
    "LeftTabBar: customers-tab": "Kunder",

    "NotFoundPage: page-not-found-message": "Siden kunne ikke findes",

    "ForbiddenPage: no-access-message": "Du har ikke adgang til denne side",

    "BackButton: back": "Tilbage",

    "AppointmentCard: status-cancelled": "Besøget er aflyst",
    "AppointmentCard: status-has-registration": "Der er registreret tid for besøget",
    "AppointmentCard: status-has-inspected": "Besøg foretaget uden at udføre havearbejde",
    "AppointmentCard: status-closed": "Besøget er ikke længere aktuel",

    "GardenerCard: card-title": "Oplysninger",
    "GardenerCard: working-as-label": "Arbejder som",
    "GardenerCard: working-as-private-person-value": "Privatperson (ikke momsregistreret)",
    "GardenerCard: working-as-company-without-vat-value": "Virksomhed (ikke momsregistreret)",
    "GardenerCard: working-as-company-with-vat-value": "Virksomhed (momsregistreret)",
    "GardenerCard: company-label": "Virksomhed",
    "GardenerCard: company-value": "$COMPANY_NAME (CVR: $CVR)",
    "GardenerCard: name-label": "Navn",
    "GardenerCard: contact-person-label": "Kontaktperson",
    "GardenerCard: address-label": "Adresse",
    "GardenerCard: email-label": "Email",
    "GardenerCard: phone-label": "Telefon",

    "GardenerPausedSwitch: active-button": "Aktiv",
    "GardenerPausedSwitch: paused-button": "På pause",
    "GardenerPausedSwitch: toggle-error": "Der skete en fejl. Ændringen af din status blev ikke registreret.",

    "ProfilePage: page-title": "Profil",

    "StatusBanner: paused-banner-title": "Du tilbydes ikke opgaver lige nu",
    "StatusBanner: paused-banner-message": "Det skyldes, at du er sat på pause. Du kan ændre din status på din profil.",
    "StatusBanner: process-protection-banner-message": "Det skyldes, at du har opgaver på din oversigt, som ikke er blevet besvaret til tiden.\n\nBesvar dine opgaver for at blive aktiv igen.",
    "StatusBanner: terminated-banner-title-with-date": "Samarbejdet er ophørt d. $DATE",
    "StatusBanner: terminated-banner-title-without-date": "Samarbejdet er ophørt",
    "StatusBanner: terminated-banner-message": "Derfor tilbyder vi dig ikke nye opgaver. Læs mere i den email vi har sendt til dig.",
    "StatusBanner: profile-link": "Gå til din profil",
    "StatusBanner: overview-link": "Gå til din oversigt",
    
    "RegistrationCard: commission-earned-label": "Honorar optjent",
    "RegistrationCard: commission-earned-description": "Kunden har betalt $PAID_AMOUNT af dit totale honorar på $EARNED_AMOUNT",

    "CommissionPayoutCard: card-title": "Udbetaling af honorar",
    "CommissionPayoutCard: balance-label": "Saldo",
    "CommissionPayoutCard: balance-scheduled-label": "Saldo (udbetaling behandles)",
    "CommissionPayoutCard: balance-progress-remaining-label": "Saldo (efter udbetaling)",
    "CommissionPayoutCard: balance-progress-scheduled-label": "Sat til udbetaling",
    "CommissionPayoutCard: explanation-label": "Sådan fungerer udbetaling af honorar",
    "CommissionPayoutCard: explanation-text": "Når du trykker på knappen, sættes dit honorar til udbetaling. Ofte vil du modtage beløbet samme dag, men der kan gå op til 5 bankdage, alt efter din bank.",
    "CommissionPayoutCard: balace-not-positive-notice": "Din saldo skal være positiv for at få udbetalt honorar.",
    "CommissionPayoutCard: needs-bank-information-notice": "Udfyld kontooplysninger for at få udbetalt honorar.",
    "CommissionPayoutCard: payout-in-progress-notice": "Vi har modtaget din anmodning om at få udbetalt honorar.",
    "CommissionPayoutCard: payout-button": "Udbetal honorar",

    "RequestPayoutDialog: dialog-header": "Vil du sætte dit honorar til udbetaling?",
    "RequestPayoutDialog: dialog-content": "Du modtager dit optjente honorar inden for 5 bankdage. Beløbet udbetales til den konto du har angivet på din profil.",
    "RequestPayoutDialog: error-message": "Der skete en fejl. Dit honorar er ikke blevet sat til udbetaling.",
    "RequestPayoutDialog: success-message": "Dit honorar er sat til udbetaling",

    "PersonalInformationCard: card-title": "Bankkonto",
    "PersonalInformationCard: account-number-label": "Kontonummer",
    "PersonalInformationCard: registration-number-label": "Registreringsnummer",
    "PersonalInformationCard: not-specified": "Ikke angivet",
    "PersonalInformationCard: save-error-message": "Der skete en fejl. Din ændring blev ikke gemt.",

    "Validation: must-be-specified": "Skal udfyldes",
    "Validation: must-be-4-digits": "Skal være 4 tal",
    "Validation: must-be-10-digits": "Skal være 10 tal",
    "Validation: must-select-at-least-1-option": "Vælg mindst ét svar",

    "Button: cancel": "Annuller",
    "Button: save": "Gem",
    "Button: confirm": "Bekræft",

    "PreferencesCard: card-title": "Indstillinger",
    "PreferencesCard: service-radius-label": "Afstand til opgaver",
    "PreferencesCard: skills-label": "Hvilke opgaver kan du udføre?",
    "PreferencesCard: tools-label": "Hvilke redskaber kan du medbringe?",
    "PreferencesCard: save-error-message": "Der skete en fejl. Din ændring blev ikke gemt.",
    "PreferencesCard: select-at-least-one-task": "Vælg mindst én opgave",
    "PreferencesCard: select-at-least-one-other-task": "Vælg mindst én anden opgave",
    "PreferencesCard: kilometers-abbreviation": "km",
    "PreferencesCard: must-be-at-least-10-kilometers": "Skal være minimum 10 kilometer",

    "Skill: lawn-mowing": "Græsslåning",
    "Skill: weed-control": "Ukrudtsbekæmpelse",
    "Skill: hedge-trimming": "Hækklipning",
    "Skill: pruning-of-trees-and-shrubs": "Beskæring af buske og træer",
    "Skill: pruning": "Beskæring",
    "Skill: disposal-of-garden-waste": "Bortkørsel af haveaffald",
    "Skill: other-garden-services": "Anden haveservice",
    
    "Tool: lawn-mower": "Plæneklipper",
    "Tool: grass-trimmer": "Græstrimmer",
    "Tool: garden-tractor": "Havetraktor",
    "Tool: verticutter": "Plænelufter / vertikalskærer",
    "Tool: other-lawn-mowing-tools": "Andre redskaber til græsslåning",
    "Tool: hand-tools": "Håndredskaber",
    "Tool: weed-burner": "Ukrudtsbrænder",
    "Tool: other-weed-control-tools": "Andre redskaber til ukrudtsbekæmpelse",
    "Tool: hedge-trimmer": "Hækklipper",
    "Tool: chainsaw": "Motorsav",
    "Tool: other-hedge-trimming-tools": "Andre redskaber til hækklipning",
    "Tool: pruning-shears": "Beskæringssaks",
    "Tool: telescopic-pole": "Teleskopstang",
    "Tool: scaffolding": "Stige / stillads",
    "Tool: other-pruning-tools": "Andre redskaber til beskæring",
    "Tool: regular-trailer": "Almindelig trailer",
    "Tool: large-trailer": "Stor trailer",
    "Tool: other-disposal-tools": "Andre redskaber til bortkørsel",

    "AcceptAgreementDialog: dialog-header": "Accepter samarbejdsaftale?",
    "AcceptAgreementDialog: dialog-content": "Accepterer du samarbejdsaftalen, vil den være juridisk gældende for det fremtidige samarbejde.",
    "AcceptAgreementDialog: accept-button": "Ja, accepter",

    "AgreementPage: instruction-heading": "Du er næsten færdig!",
    "AgreementPage: instruction-reminder": "Du mangler kun at gennemgå og acceptere vores samarbejdsaftale.",
    "AgreementPage: instruction-how-to-left": "Klik på knappen",
    "AgreementPage: instruction-how-to-middle": "nederst på siden",
    "AgreementPage: instruction-how-to-right": "for at færdiggøre din ansøgning.",
    "AgreementPage: already-accepted-cooperation-agreement-button": "Du har allerede accepteret denne samarbejdsaftale",
    "AgreementPage: terminated-cooperation-agreement-button-with-date": "Samarbejdet er ophørt d. $DATE",
    "AgreementPage: terminated-cooperation-agreement-button-without-date": "Samarbejdet er ophørt",
    "AgreementPage: accept-agreement-error-message": "Der skete en fejl. Din accept af samarbejdsaftalen blev ikke gennemført.",
    "AgreementPage: accept-agreement-success-message": "Vi har modtaget din accept af samarbejdsaftalen.",
    "AgreementPage: accept-agreement-cannot-get-text": "Kunne ikke få fat i aftaleteksten. Din accept af samarbejdsaftalen blev ikke gennemført.",

    "CustomerDetailPage: has-already-scheduled-appointment-warning-message": "Du har allerede aftalt et besøg med kunden på denne dato",
    "CustomerDetailPage: create-appointment-button": "Kunden har brug for hjælp igen",

    "CustomerCard: connection-established": "Du blev tilknyttet kunden",
    "CustomerCard: customer-specified-hours": "Kunden vurderer opgaven til",
    "CustomerCard: skills-label": "Det drejer sig om:",
    "CustomerCard: hours-1-2": "1-2 timer",
    "CustomerCard: hours-3-5": "3-5 timer",
    "CustomerCard: hours-more-than-6": "mere end 6 timer",
    "CustomerCard: tools-customer-tools": "Kunden kan stille redskaber til rådighed",
    "CustomerCard: tools-gardener-tools": "Havemanden skal medbringe redskaber",
    "CustomerCard: frequency-once-every-week": "En gang om ugen",
    "CustomerCard: frequency-once-every-second-week": "En gang hver anden uge",
    "CustomerCard: frequency-once-every-month": "En gang om måneden",
    "CustomerCard: frequency-once": "Engangsopgave",

    "OverviewPage: tab-current": "Aktuelle opgaver",
    "OverviewPage: tab-future": "Fremtidige opgaver",

    "CustomerOverviewPage: tab-current": "Aktuelle kunder",
    "CustomerOverviewPage: tab-past": "Tidligere kunder",

    "CurrentCustomersTab: header": "Aktuelle kunder",
    "CurrentCustomersTab: no-customers-message": "Du har ingen aktuelle kunder",

    "PastCustomersTab: header": "Tidligere kunder",
    "PastCustomersTab: no-customers-message": "Du har ingen tidligere kunder",

    "RegistrationOverviewPage: tools-customer": "kundens redskaber",
    "RegistrationOverviewPage: tools-gardener": "mine redskaber",
    "RegistrationOverviewPage: tools-gardener-large": "store redskaber",
    "RegistrationOverviewPage: tools-unknown": "ukendte redskaber",
    "RegistrationOverviewPage: hours-singular": "time",
    "RegistrationOverviewPage: hours-plural": "timer",
    "RegistrationOverviewPage: with": "med",
    "RegistrationOverviewPage: view-all": "Se alle",
    "RegistrationOverviewPage: no-registrations": "Der er ingen tidsregistreringer at vise.",
    
    "ChangeAppointmentDialog: action-success-message": "Din handling er registreret",
    "ChangeAppointmentDialog: action-error-message": "Der skete en fejl. Din handling er ikke blevet registreret.",

    "CreateRegistrationDialog: dialog-header": "Jeg har udført arbejde",
    "CreateRegistrationDialog: dialog-text": "Vi fakturerer kunden på baggrund af din registrering, så det er vigtigt, at du har udfyldt oplysningerne korrekt.",
    "CreateRegistrationDialog: dialog-confirm": "Registrer tid",
    "CreateRegistrationDialog: customer-label": "Kunde",
    "CreateRegistrationDialog: appointment-date-label": "Dato for besøg",
    "CreateRegistrationDialog: task-description-label": "Beskrivelse",
    "CreateRegistrationDialog: task-duration-label": "Varighed",
    "CreateRegistrationDialog: task-duration-placeholder": "Vælg varighed",
    "CreateRegistrationDialog: task-tools-label": "Redskaber",
    "CreateRegistrationDialog: task-tools-placeholder": "Vælg redskaber",
    "CreateRegistrationDialog: task-tools-customer-tools-option": "Kundens redskaber",
    "CreateRegistrationDialog: task-tools-gardener-tools-option": "Havemandens redskaber",
    "CreateRegistrationDialog: task-tools-gardener-large-tools-option": "Havemandens store redskaber",
    "CreateRegistrationDialog: task-tools-gardener-large-tools-warning": "Brug kun denne takst, hvis det på forhånd er aftalt med $CUSTOMER_NAME",
    "CreateRegistrationDialog: add-task-button-text": "Tilføj opgave",
    "CreateRegistrationDialog: create-registration-success-message": "Vi har modtaget din registrering, som nu kan findes under \"Tidsregistreringer\".",
    "CreateRegistrationDialog: create-registration-error-message": "Der skete en fejl. Din registrering blev ikke gennemført.",

    "Time: yesterday": "I går",
    "Time: today": "I dag",
    "Time: tomorrow": "I morgen",
    "Time: days-ago": "$DAYS $UNIT siden",
    "Time: day-singular": "dag",
    "Time: day-plural": "dage",
};
