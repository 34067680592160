import { PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    wrapper: {
        padding: "8px 0",
        marginLeft: "-8px",
        marginRight: "-8px",
    },
    notice: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        whiteSpace: "pre-wrap",

        backgroundColor: "rgba(162, 89, 255, 0.06)",
        backgroundImage: "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23818181' stroke-width='2' stroke-dasharray='1%2c 4' stroke-dashoffset='2' stroke-linecap='butt'/%3e%3c/svg%3e\")",
        borderRadius: "4px",
        padding: "8px",
        margin: 0,
    },
});

export const Notice = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    const { children } = props;

    return (                
        <div className={classes.wrapper}>
            <p className={classes.notice}>{children}</p>
        </div>
    );
};
