import { PropsWithChildren } from "react";

import { IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import { useDialog } from "./DialogProvider";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px",

        [theme.breakpoints.down(500)]: {
            padding: "16px",
        },
        
        "& > h3": {
            fontSize: "20px",
            fontWeight: 600,
            color: "#4A4A4A",
            margin: 0,
        },
    },
    buttonSlot: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "48px",
        height: "48px",
        margin: "-24px -12px",
    },
    closeIcon: {
        fontSize: "24px",
        color: "#4A4A4A",
    },
}));

export const DialogHeader = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    const { children } = props;
    const { closeDialog } = useDialog();

    return (
        <div className={classes.root}>

            <h3 id="dialog-title">{children}</h3>

            <div className={classes.buttonSlot}>
                <IconButton aria-label="close" onClick={closeDialog}>
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
            </div>

        </div>
    );
};
