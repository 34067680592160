import { useEffect, useState } from "react";

import firebase from "firebase/app";

import { AppointmentDocument } from "./appointment.model";

export const useOpenAppointments = (gardenerId: string) => {
    const [state, setState] = useState<AppointmentDocument[]>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore()
            .collection("appointments")
                .where("status", "==", "open")
                .where("gardener.id", "==", gardenerId)
            .onSnapshot(collection => {
                const appointments = collection.docs.map(document => ({ id: document.id, ...document.data() } as AppointmentDocument));

                setState(appointments);
            });

    }, [gardenerId]);

    return state;
};

export const useOpenAppointmentsForCustomer = (gardenerId: string | null | undefined, customerId: string | null | undefined) => {
    const [state, setState] = useState<AppointmentDocument[] | null>();

    useEffect(() => {
        if ( gardenerId === null || customerId === null ) return setState(null);
        if ( gardenerId === undefined || customerId === undefined ) return setState(undefined);

        setState(undefined);

        return firebase.firestore()
            .collection("appointments")
                .where("status", "==", "open")
                .where("gardener.id", "==", gardenerId)
                .where("customer.id", "==", customerId)
            .onSnapshot(collection => {
                const appointments = collection.docs.map(document => ({ id: document.id, ...document.data() } as AppointmentDocument));

                setState(appointments);
            });

    }, [gardenerId, customerId]);

    return state;
};
