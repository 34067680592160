import { createContext, PropsWithChildren, useContext } from "react";

import { LinearProgress } from "../application/LinearProgress";
import { useGardener } from "../gardener/gardener.hook";
import { GardenerDocument } from "../gardener/gardener.model";
import { useCurrentUser } from "./current-user.hook";

const UserContext = createContext<GardenerDocument | null | undefined>(undefined);

export const UserProvider = (props: PropsWithChildren<{}>) => {
    const userId = useCurrentUser();
    const user = useGardener(userId);

    if ( user === undefined ) return <LinearProgress />

    return (
        <UserContext.Provider value={user}>
            {props.children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext) as GardenerDocument;
};

export const useOptionalUser = () => {
    return useContext(UserContext) as GardenerDocument | null;
};
