import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#b2ff59",
        },
        secondary: {
            main: "#448aff", // We decided on #18ffff
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 560,
            md: 760,
            lg: 995,
            xl: 1152,
        },
    },
    typography: {
        htmlFontSize: 16,
        fontSize: 16,
        fontFamily: "Montserrat, sans-serif",
    },
});
