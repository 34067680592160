import { makeStyles } from "@material-ui/core";

import { useLinks } from "../LinkProvider";
import { Sidebar } from "./Sidebar";

const useStyles = makeStyles(theme => ({
    navigation: {
        backgroundColor: "#EBF3EA",
        boxShadow: theme.shadows[1],
        height: "100%",

        "& > a": {
            display: "block",
            padding: "16px 24px",
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",
            textDecoration: "none",

            "&:hover": {
                backgroundColor: "#D0E6CC",
            },

            "&.active": {
                backgroundColor: "#D0E6CC",
                fontWeight: 500,
            },
        },
    },
}));

export const SideNavigation = () => {
    const classes = useStyles();

    const links = useLinks();
    if ( !links?.sideLinks ) return null;

    return (
        <Sidebar>
            <nav className={classes.navigation}>
                {links.sideLinks}
            </nav>
        </Sidebar>
    );
};
