import { useMemo } from "react";

import moment from "moment";

import { FormProps } from "../../../framework/react/FormProvider";
import { useFormDialog } from "../../components/FormDialog";
import { FormItem } from "../../../framework/core/form-item.model";
import { createCreateAppointmentBranch } from "./create-appointment-branch";
import { createPostponeContactBranch } from "./postpone-contact-branch";
import { createRegisterContactAttemptBranch } from "./register-contact-attempt-branch";
import { GroupFormItem, NoticeFormItem, PageFormItem, SingleChoiceFormItem } from "../../components";
import { createActionPage } from "./action-page";
import { createRejectCustomerBranch } from "../reject-customer-dialog";
import { GardeningTaskGardenerViewDocument } from "../../../../../gardening-task/gardener-view/gardening-task-gardener-view.model";

export * from "./create-appointment-branch";
export * from "./postpone-contact-branch";
export * from "./register-contact-attempt-branch";

export enum AppointmentTaskResponseDialogIds {
    SingleChoiceAction = "SingleChoiceAction",

    GardenerCreatedAppointmentDate = "GardenerCreatedAppointmentDate",
    GardenerCreatedAppointmentDescription = "GardenerCreatedAppointmentDescription",

    HaveCalledAndNotReachedCustomerAndStillWantsToHelpCustomerAndGardenerDoesNotHaveThreeAttemptsButDoesGardenerWantToContactAgain = "HaveCalledAndNotReachedCustomerAndStillWantsToHelpCustomerAndGardenerDoesNotHaveThreeAttemptsButDoesGardenerWantToContactAgain",

    HaveCalledAndReachedCustomerAndWantsToHelpCustomerAndCustomerWantsHelpButExpectsToContact = "HaveCalledAndReachedCustomerAndWantsToHelpCustomerAndCustomerWantsHelpButExpectsToContact",
    HaveCalledAndReachedCustomerAndWantsToHelpCustomerAndCustomerWantsHelpAndExpectsToContactDate = "HaveCalledAndReachedCustomerAndWantsToHelpCustomerAndCustomerWantsHelpAndExpectsToContactDate",

    SingleChoiceDoesCustomerStillWantHelp = "SingleChoiceDoesCustomerStillWantHelp",
}

export type ActionPage<Pages extends PageFormItem<any>[]> =
    PageFormItem<
        GroupFormItem<[
            NoticeFormItem,
            SingleChoiceFormItem<Pages>,
        ]>
    >;

export const useAppointmentTaskResponseDialog = (hasHistory: boolean, hasRecentHistory: boolean, isFuture: boolean, now: string, gardeningTask: GardeningTaskGardenerViewDocument, onConfirm: FormProps<FormItem>["onSubmit"]) => {
    const today = moment(now).startOf("day").toISOString(true);

    const { postponeDateMinimum, postponeDateMaximum } = useMemo(() => {
        return determinePostponeDates(gardeningTask, now);
    }, [gardeningTask, now]);

    const numberOfPostponements = gardeningTask.working.contactAttempts + 1;

    const page = useMemo(() => {
        const createAppointmentBranch = createCreateAppointmentBranch(today, {
            datePickerAppointmentDate: AppointmentTaskResponseDialogIds.GardenerCreatedAppointmentDate,
            textAreaAppointmentDescription: AppointmentTaskResponseDialogIds.GardenerCreatedAppointmentDescription,
        }, undefined, "Vi har planlagt et besøg");
        
        const registerContactAttemptBranch = !isFuture ? createRegisterContactAttemptBranch(numberOfPostponements, {
            singleChoiceWantsToCallAgain: AppointmentTaskResponseDialogIds.HaveCalledAndNotReachedCustomerAndStillWantsToHelpCustomerAndGardenerDoesNotHaveThreeAttemptsButDoesGardenerWantToContactAgain,
        }) : null;

        const postponeContactBranch = createPostponeContactBranch(postponeDateMinimum, postponeDateMaximum, {
            singleChoiceDoYouExpectToFindDate: AppointmentTaskResponseDialogIds.HaveCalledAndReachedCustomerAndWantsToHelpCustomerAndCustomerWantsHelpButExpectsToContact,
            datePickerWhenDoYouExpectToFindDate: AppointmentTaskResponseDialogIds.HaveCalledAndReachedCustomerAndWantsToHelpCustomerAndCustomerWantsHelpAndExpectsToContactDate
        });

        const closeTaskBranch = createRejectCustomerBranch(hasHistory, hasRecentHistory, {
            singleChoiceDoesCustomerStillWantHelp: AppointmentTaskResponseDialogIds.SingleChoiceDoesCustomerStillWantHelp,
        });

        return createActionPage(createAppointmentBranch, registerContactAttemptBranch, postponeContactBranch, closeTaskBranch, {
            singleChoiceAction: AppointmentTaskResponseDialogIds.SingleChoiceAction,
        });
    }, [hasHistory, hasRecentHistory, isFuture, today, postponeDateMinimum, postponeDateMaximum, numberOfPostponements]);

    return useFormDialog(page, onConfirm);
};

export const determinePostponeDates = (gardeningTask: GardeningTaskGardenerViewDocument, now: string) => {
    const relevantDate = moment(gardeningTask.working.contextDate).startOf("day").toISOString(true);

    const postponeDateMinimum = moment(now).add(1, "day").startOf("day").toISOString(true);
    const postponeDateMaximum = moment(relevantDate).add(30, "days").startOf("day").toISOString(true);

    return { postponeDateMinimum, postponeDateMaximum };
};
