import { PropsWithChildren } from "react";

import { Paper, Tabs, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    indicator: {
        backgroundColor: "#297A1D",
    },
});

interface TabBarProps {
    index: number;
}

export const TabBar = (props: PropsWithChildren<TabBarProps>) => {
    const classes = useStyles();

    const { index, children } = props;

    return (
        <Paper square>
            <Tabs value={index} aria-label="faner" variant="scrollable" scrollButtons="on" classes={classes}>
                {children}
            </Tabs>
        </Paper>
    );
}
