import { makeStyles } from "@material-ui/core";
import { HTMLAttributes } from "react";

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: "20px",
        fontWeight: 600,
        letterSpacing: "0.75px",
        color: "#4A4A4A",
        textAlign: "center",
        margin: 0,

        [theme.breakpoints.down(500)]: {
            fontSize: "16px",
        },
    },
}));

export const SectionHeader = (props: HTMLAttributes<HTMLHeadingElement>) => {
    const classes = useStyles();

    const { className } = props;

    return (
        // eslint-disable-next-line jsx-a11y/heading-has-content
        <h2
            className={className ? `${classes.root} ${className}` : classes.root}
            {...props}
        />
    );
};
