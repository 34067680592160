import { Container } from "@material-ui/core";

import { ContentSection } from "../application/ContentSection";
import { useTranslation } from "../internationalisation/translation.hook";
import { TitleHeader } from "../components/page/TitleHeader";
import { Section } from "../components/section/Section";
import { SectionHeader } from "../components/section/SectionHeader";
import { AppointmentTaskFaq } from "./AppointmentTaskFaq";
import { OverviewFaq } from "./OverviewFaq";
import { NewTaskFaq } from "./NewTaskFaq";
import { RegistrationTaskFaq } from "./RegistrationTaskFaq";

export const FaqPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <TitleHeader>{t("TopBar: faq-link")}</TitleHeader>

            <ContentSection>

                <Container maxWidth="md" disableGutters>

                    <Section>
                        <SectionHeader>{t("FaqPage: overview-faq-header")}</SectionHeader>
                        <OverviewFaq />
                    </Section>

                    <Section>
                        <SectionHeader>{t("FaqPage: new-task-faq-header")}</SectionHeader>
                        <NewTaskFaq />
                    </Section>

                    <Section>
                        <SectionHeader>{t("FaqPage: appointment-task-faq-header")}</SectionHeader>
                        <AppointmentTaskFaq />
                    </Section>

                    <Section>
                        <SectionHeader>{t("FaqPage: registration-task-faq-header")}</SectionHeader>
                        <RegistrationTaskFaq />
                    </Section>

                </Container>

            </ContentSection>
        </>
    );
};
