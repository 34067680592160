import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { AlertProps } from "@material-ui/lab";

import { Snackbar, SnackbarAlert } from ".";

const Context = createContext<{
    open: boolean;
    openSnackbar: (severity: AlertProps["severity"], message: string) => void;
    closeSnackbar: () => void;
}>(undefined as any);

export const SnackbarProvider = (props: PropsWithChildren<{}>) => {
    const [snackbar, setSnackbar] = useState<JSX.Element | null>(null);

    const openSnackbar = useCallback((severity: AlertProps["severity"], message: string) => {
        const uniqueId = new Date().getTime();

        setSnackbar(
            <Snackbar key={uniqueId}>
                <SnackbarAlert severity={severity} message={message} />
            </Snackbar>
        );
    }, []);

    const closeSnackbar = useCallback(() => {
        setSnackbar(null);
    }, []);

    const open = Boolean(snackbar);
    const value = useMemo(() => ({ openSnackbar, closeSnackbar, open }), [openSnackbar, closeSnackbar, open]);

    const retainedSnackbar = useRetainedValue(snackbar);

    return (
        <Context.Provider value={value}>

            {props.children}
            {retainedSnackbar}

        </Context.Provider>
    );
};

export const useSnackbar = () => {
    return useContext(Context);
}

const useRetainedValue = <T extends any>(value: T) => {
    const [retainedValue, setRetainedValue] = useState<T | null>(value);

    useEffect(() => {
        if ( !value ) return;

        setRetainedValue(value);
    }, [value]);

    return retainedValue;
};
