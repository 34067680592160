import { cloneElement, PropsWithChildren, useCallback } from "react";

import { makeStyles, MenuItem as MaterialMenuItem } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#4A4A4A",
        padding: "16px",
    },
    icon: {
        fontSize: "24px",
        color: "#818181",
        marginRight: "16px",
    },
});

interface MenuItemProps {
    icon: JSX.Element;
    onClick: () => void;
    closeMenu?: () => void;
}

export const MenuItem = (props: PropsWithChildren<MenuItemProps>) => {
    const classes = useStyles();

    const { icon, children, onClick, closeMenu } = props;

    const click = useCallback(() => {
        closeMenu!();
        onClick();
    }, [onClick, closeMenu]);

    return (
        <MaterialMenuItem className={classes.root} onClick={click}>
            {icon ? cloneElement(icon, { className: classes.icon }) : null}
            {children}
        </MaterialMenuItem>
    );
};
