import { MouseEvent, PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from "react";

interface AccordionContext {
    openAccordion: string | undefined;
    toggleAccordion: (event: MouseEvent<HTMLElement>) => void;
}

const Context = createContext<AccordionContext | undefined>(undefined);

export const AccordionProvider = (props: PropsWithChildren<{}>) => {
    const [openAccordion, setOpenAccordion] = useState<string>();
    const toggleAccordion = useCallback((event: MouseEvent<HTMLElement>) => {
        const accordion = event.currentTarget.id.split("-header")[0];
        if ( accordion === openAccordion ) return setOpenAccordion(undefined);

        setOpenAccordion(accordion);
    }, [openAccordion]);

    const value = useMemo(() => {
        return { openAccordion, toggleAccordion };
    }, [openAccordion, toggleAccordion]);

    return (
        <Context.Provider value={value}>
            {props.children}
        </Context.Provider>
    );
};

export const useAccordionContext = () => {
    return useContext(Context) as AccordionContext;
}
