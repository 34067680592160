import { useCallback } from "react";

import { Chip as MaterialChip, ChipProps as MaterialChipProps, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
    root: {
        padding: "8px 24px",
        borderRadius: "24px",
        height: "auto",

        "& .MuiChip-label": {
            padding: 0,
            whiteSpace: "normal",
        },
    },
    off: {
        backgroundColor: "transparent",
        color: "#A259FF",
        border: "1px solid #A259FF",

        "&.disabled": {
            backgroundColor: "transparent",
            color: "#818181",
            border: "1px solid #818181",
        },
        
        "&:focus": {
            backgroundColor: "transparent",
        },

        "&:hover": {
            backgroundColor: "rgba(162, 89, 255, 0.08) !important",
        },
    },
    on: {
        backgroundColor: "#A259FF",
        color: "#FFFFFF",
        border: "1px solid #A259FF",

        "&.disabled": {
            backgroundColor: "#818181",
            color: "#FFFFFF",
            border: "1px solid #818181",
        },

        "&:focus": {
            backgroundColor: "#A259FF",
        },

        "&:hover": {
            backgroundColor: "#7A14FF",
        },
    },
});

interface ChipProps<T> extends Omit<MaterialChipProps, "variant" | "onChange"> {
    value: T;
    on: boolean;
    onToggle: (value: T) => void;
}

export const Chip = <T extends unknown>(props: ChipProps<T>) => {
    const classes = useStyles();

    const { className, value, on, onToggle, disabled, ...otherProps } = props;

    const stateClass = on ? classes.on : classes.off;
    const variant = on ? "default" : "outlined";

    const disabledClass = disabled ? "disabled" : "active";

    const click = useCallback(() => {
        onToggle(value);
    }, [onToggle, value]);

    return (
        <MaterialChip
            className={className ? `${classes.root} ${stateClass} ${disabledClass} ${className}` : `${classes.root} ${stateClass} ${disabledClass}`}
            variant={variant}
            onClick={click}
            disabled={disabled}
            {...otherProps}
        />
    );
}
