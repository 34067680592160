import { useCallback, useEffect, useMemo, useRef } from "react";

import { Collapse } from "@material-ui/core";

import { AlternativeHint, Group, Label } from "../../../form";
import { useForm } from "../../framework/react/FormProvider";
import { validateMandatory } from "../../../validation/validation";
import { HorizontalButtons } from "../../../form/horizontal-buttons/HorizontalButtons";
import { FormItemSupport } from "../../framework/core/form-item.support";

export interface HorizontalButtonsFormItem {
    id: string;
    type: "horizontal-buttons",
    mode: "regular" | "switch page";
    label: string | null;
    answerTexts: string[];
    selectedChoiceIndex: number | null;
    dirty: boolean;
}

interface HorizontalButtonsFormItemComponentProps {
    item: HorizontalButtonsFormItem;
}

export const HorizontalButtonsFormItemComponent = (props: HorizontalButtonsFormItemComponentProps) => {
    const { item } = props;
    const { validationAnimationDuration, updateItem, goForward } = useForm();

    const error = useMemo(() => validate(item), [item]);
    const completed = !error;

    const shouldGoForward = useRef(false);
    useEffect(() => {
        if ( completed && shouldGoForward.current ) {
            shouldGoForward.current = false;
            goForward();
        }
    }, [completed, goForward]);

    const selectAnswer = useCallback((selectedChoiceIndex: number | null) => {
        if ( item.mode === "switch page" ) {
            shouldGoForward.current = true;
        }

        updateItem(item.id, (state: HorizontalButtonsFormItem) => ({
            ...state,
            selectedChoiceIndex,
            dirty: true,
        }));
    }, [updateItem, item.id, item.mode]);

    const selectedButtonIndexes = useMemo(() => item.selectedChoiceIndex !== null ? [item.selectedChoiceIndex] : [], [item.selectedChoiceIndex]);

    return (
        <div id={`${item.id}-section`}>
            {item.label ? <Label style={{ marginBottom: "8px" }}>{item.label}</Label> : null}

            <Group error={Boolean(item.dirty && error)}>

                <HorizontalButtons
                    color="green"
                    buttonTexts={item.answerTexts}
                    selectedButtonIndexes={selectedButtonIndexes}
                    onSelect={selectAnswer}
                />

                <Collapse in={Boolean(item.dirty && error)} timeout={validationAnimationDuration}>
                    <AlternativeHint message={error} />
                </Collapse>
            </Group>
        </div>
    );
};

export class HorizontalButtonsFormItemSupport implements FormItemSupport<HorizontalButtonsFormItem> {
    supportedType = "horizontal-buttons" as const;
    updateChildren = undefined;

    markDirty(item: HorizontalButtonsFormItem): HorizontalButtonsFormItem {
        return { ...item, dirty: true };
    }

    getAllChildren() {
        return [];
    }

    getActiveChildren() {
        return [];
    }

    blocksParent(item: HorizontalButtonsFormItem): boolean {
        return Boolean(validate(item));
    }
}

const validate = (item: HorizontalButtonsFormItem): string | undefined => {
    return validateMandatory(item.selectedChoiceIndex !== null) && "Vælg et svar";
}
