import { PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        display: "flex",

        "& > *": {
            flexBasis: "100%",
            borderRadius: "0 !important",
        },
    },
});

export const DialogActions = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    const { children } = props;

    return (
        <div className={classes.root}>
            {children}
        </div>
    );
};
