import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

firebase.initializeApp({
  apiKey: "AIzaSyBoVjHjTx5LyDQ1SqVsggTWnbNklhzY7N8",
  authDomain: "sommer-og-fritid.firebaseapp.com",
  databaseURL: "https://sommer-og-fritid.firebaseio.com",
  projectId: "sommer-og-fritid",
  storageBucket: "sommer-og-fritid.appspot.com",
  messagingSenderId: "838002229339",
  appId: "1:838002229339:web:d8767f3c3562d6f41a7f7e",
  measurementId: "G-VH15S2D2JR"
});

firebase.analytics();
