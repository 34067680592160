import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import firebase from "firebase/app";

import { LinearProgress } from "../application/LinearProgress";

const naiveHelperLogin = firebase.app().functions("europe-west3").httpsCallable("naiveHelperLogin");

export const DirectLogin = () => {
    const { gardenerId } = useParams<{ gardenerId: string }>();

    const history = useHistory();

    useEffect(() => {
        naiveHelperLogin(gardenerId)
            .then(result => result.data)
            .then(token => firebase.auth().signInWithCustomToken(token))
            .catch(() => history.replace("/logout"));

    }, [gardenerId, history]);


    return <LinearProgress />;
};
