import { useCallback } from "react";

import { defaultTranslation } from "./default.translation";

export type Translation = (key: keyof typeof defaultTranslation) => string;

export const useTranslation = () => {

    const t = useCallback((key: keyof typeof defaultTranslation) => {
        return defaultTranslation[key];
    }, []);

    const formatCurrency = useCallback((value: number): string => {
        const formattedValue = value.toFixed(2)
            .replace(".", ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        return `${formattedValue} kr.`;
    }, []);

    return { t, formatCurrency };
};
