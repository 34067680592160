import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

import { AccordionList } from "../components/accordion/AccordionList";
import { AccordionListItem } from "../components/accordion/AccordionListItem";

const useStyles = makeStyles({
    link: {
        fontWeight: 600,
        color: "#297A1D",
        textDecoration: "none",

        "&:hover": {
            color: "#1C5B13",
            textDecoration: "underline",
        },
    }
});

export const RegistrationTaskFaq = () => {
    const classes = useStyles();

    return (
        <AccordionList>

            <AccordionListItem id="registration-task-question-1" title="Hvordan registrerer jeg brugen af forskellige redskaber?">
                Har du brug for at fakturere kunden til flere forskellige takster, så kan du ikke nøjes med at registrere 1 opgave på din tidsregistrering.<br />
                <br />
                Du skal i så fald blot bruge “Tilføj opgave” til at tilføje flere opgaver til din tidsregistrering og så kan du for hver opgave beskrive, hvad du udførte, hvor lang tid det tog og hvillke redskaber du brugte.<br />
                <br />
                OBS: Vi fakturerer kunden på baggrund af din registrering, så det er meget vigtigt, at du ikke registrerer forkert.
            </AccordionListItem>

            <AccordionListItem id="registration-task-question-2" title="Hvornår modtager jeg mit honorar for det udførte havearbejde?">
                Når du har registreret tid, så bliver kunden faktureret. Kunden har herefter typisk 8 dage til at betale sin faktura. Betalingsfristen kan variere ud fra aftale med kunden, men det aftales sjældent.<br />
                <br />
                Når kundens betaling er registreret i vores system, bliver det automatisk registreret på din saldo, som du finder under <Link to="/profile" className={classes.link}>din profil</Link>.<br />
                <br />
                Betaler kunden ikke til tiden, så kører vi et opkrævningsforløb over for kunden, hvor vi kontakter dem via email, sms, brev og telefonopkald, udsteder rykkere og i sidste instans sender dem til udenretslig inkasso.<br />
                <br />
                Vi oplever ikke manglende betaling som et stort problem. Ved delvis betaling af en faktura fordeles betalingen først til dig og herefter til Go Go Garden. Du kan derfor få udbetalt honorar for udført arbejde, selvom fakturaen ikke er fuldt betalt.
            </AccordionListItem>
            

        </AccordionList>
    );
};
