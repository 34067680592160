import { useCallback } from "react";

import firebase from "firebase/app";

import { useSnackbar } from "../../components/snackbar";
import { useBlockingProgress } from "../../components/blocking-progress";
import { useTranslation } from "../../internationalisation/translation.hook";

const requestPayout = firebase.app().functions("europe-west3").httpsCallable("GardenerPayout-request");

export const useRequestPayoutAction = () => {
    const { t } = useTranslation();
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const action = useCallback(() => {
        block();

        const idempotencyKey = firebase.firestore().collection("x").doc().id;
        requestPayout({ idempotencyKey })
            .then(() => {
                openSnackbar("success", t("RequestPayoutDialog: success-message"));
                unblock();
            })
            .catch(() => {
                openSnackbar("error", t("RequestPayoutDialog: error-message"));
                unblock();
            });
    }, [t, openSnackbar, block, unblock]);

    return action;
};
