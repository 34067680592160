import { useMemo, useRef } from "react";
import { Redirect, useParams } from "react-router-dom";

import moment from "moment";

import { useTranslation } from "../../internationalisation/translation.hook";
import { TitleHeader } from "../../components/page/TitleHeader";
import { TabBar } from "../../components/tabs/TabBar";
import { TabBarItem } from "../../components/tabs/TabBarItem";
import { IntegratedCurrentTasksTab } from "../current-tasks/IntegratedCurrentTasksTab";
import { useTasks } from "../../task/TaskProvider";
import { formatTitle } from "../../utility";
import { IntegratedFutureTasksTab } from "../future-tasks/IntegratedFutureTasksTab";

export const IntegratedOverviewPage = () => {
    const { tab } = useParams<{ tab: "current" | "future" }>();

    const tabIndex = useMemo(() => {
        switch ( tab ) {
            case "current": return 0;
            case "future": return 1;
        }
    }, [tab]);

    const { t } = useTranslation();

    const now = useRef(moment().toISOString(true)).current;

    const tasks = useTasks();
    const relevantTasks = tasks.filter(task => task.type === "offer-response" || moment(task.performAfterDate).isSameOrBefore(now, "day"));

    if ( tab !== "current" && tab !== "future" ) return <Redirect to="/overview/current" />;

    return (
        <>
            <TitleHeader>{formatTitle(t("LeftTabBar: overview-tab"), relevantTasks.length)}</TitleHeader>

            <TabBar index={tabIndex}>
                <TabBarItem href="/overview/current">{t("OverviewPage: tab-current")}</TabBarItem>
                <TabBarItem href="/overview/future">{t("OverviewPage: tab-future")}</TabBarItem>
            </TabBar>

            {(() => {
                if ( tab === "current" ) return <IntegratedCurrentTasksTab />;
                if ( tab === "future" ) return <IntegratedFutureTasksTab />;

                return null;
            })()}
        </>
    );
};
