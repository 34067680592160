import { useCallback, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

import { LinearProgress } from "../../application/LinearProgress";
import { useTasksBetweenGardenerAndCustomer } from "../../task/tasks.hook";
import { useUser } from "../../user/UserProvider";
import { OfferResponseTaskDocument } from "../../task/task.model";
import { useMatch } from "../../application/matches.hook";

export const RedirectToNewTaskTask = () => {
    const { matchId } = useParams<{ matchId: string }>();

    const match = useMatch(matchId);
    const customerId = match && match.customer.id;

    const gardener = useUser();
    const types = useRef<OfferResponseTaskDocument["type"][]>(["offer-response"]).current;
    const tasks = useTasksBetweenGardenerAndCustomer(gardener.id, customerId, types);

    const history = useHistory();
    const goToList = useCallback(() => history.replace("/overview"), [history]);
    const goToTask = useCallback((taskId: string) => history.replace(`/overview/current/${taskId}`), [history]);

    const navigate = useCallback((task: OfferResponseTaskDocument | null) => {
        if ( !task ) return goToList();

        if ( !match ) return goToList();

        return goToTask(task.id);
    }, [goToList, goToTask, match]);

    useEffect(() => {
        if ( tasks === undefined ) return;

        const task = tasks && tasks.length > 0 ? tasks[0] : null;

        navigate(task);
    }, [tasks, navigate]);

    return <LinearProgress />;
};
