import { useCallback, useRef } from "react";

import { Container, Box, makeStyles, Paper } from "@material-ui/core";

import { AgreementSection } from "./AgreementSection";
import { AcceptAgreementDialog } from "./AcceptAgreementDialog";
import { useAgreementClient } from "./agreement-client.hook";
import { useSnackbar } from "../components/snackbar";
import { GardenerDocument } from "../gardener/gardener.model";
import { ContentSection } from "../application/ContentSection";
import { useLatestAgreement } from "./agreement.hook";
import { LinearProgress } from "../application/LinearProgress";
import { useBlockingProgress } from "../components/blocking-progress";
import { PurpleButton } from "../components/button/PurpleButton";
import { useDialog } from "../components/dialog";
import { useTranslation } from "../internationalisation/translation.hook";
import { FloatingButton } from "../components/floating-button/FloatingButton";
import { formatDate } from "../utility";

const legacyAgreementVersion = 1;
const currentAgreementVersion = 4;

const useStyles = makeStyles({
    instruction: {
        backgroundColor: "#A259FF",
        textAlign: "center",
        padding: "16px",
        borderRadius: "24px",
        marginBottom: "32px",

        "& > h4": {
            fontSize: "24px",
            fontWeight: 600,
            color: "#FFFFFF",
            marginTop: 0,
            marginBottom: "8px",
        },
        "& > p": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#FFFFFF",
            marginTop: 0,
            marginBottom: "8px",
        },
        "& > p > span": {
            fontWeight: 600,
            fontStyle: "italic",
        },
    },
});

interface AgreementPageProps {
    gardener: GardenerDocument;
}

export const AgreementPage = (props: AgreementPageProps) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { gardener } = props;
    const { openDialog, closeDialog } = useDialog();
    const { block, unblock } = useBlockingProgress();
    const { openSnackbar } = useSnackbar();

    const latestAgreement = useLatestAgreement(gardener.id);

    const agreementReference = useRef<HTMLElement>();
    const agreementClient = useAgreementClient();

    const confirmAgreement = useCallback(() => {
        closeDialog();

        if ( !agreementReference.current )
            return openSnackbar("error", t("AgreementPage: accept-agreement-cannot-get-text"));
        
        block();

        const agreementText = agreementReference.current.outerHTML;
        agreementClient.acceptAgreement({ agreementText, version: currentAgreementVersion })
            .then(() => {
                openSnackbar("success", t("AgreementPage: accept-agreement-success-message"));
                unblock()

                if ( gardener.status !== "applicant" ) return;

                window.location.assign("https://gogogarden.dk/bliv-havemand/bekraeftelse");
            })
            .catch(() => {
                openSnackbar("error", t("AgreementPage: accept-agreement-error-message"));
                unblock()
            });
    }, [agreementClient, closeDialog, block, unblock, openSnackbar, t, agreementReference, gardener]);

    const openAcceptAgreementDialog = useCallback(() => {
        openDialog(<AcceptAgreementDialog onConfirm={confirmAgreement} />);
    }, [openDialog, confirmAgreement]);

    if ( latestAgreement === undefined ) return <LinearProgress />;

    return (
        <ContentSection>

            <Container maxWidth="md" disableGutters>

                {gardener.status === "applicant" ? (
                    <Paper className={classes.instruction} elevation={3}>
                        <h4>{t("AgreementPage: instruction-heading")}</h4>
                        <p>{t("AgreementPage: instruction-reminder")}</p>
                        <p>{t("AgreementPage: instruction-how-to-left")} <span>{t("AgreementPage: instruction-how-to-middle")}</span> {t("AgreementPage: instruction-how-to-right")}</p>
                    </Paper>
                ) : null}

                <Box marginBottom={4}>
                    <AgreementSection ref={agreementReference} />
                </Box>

                <FloatingButton>
                    {gardener.status === "terminated" ? (
                        <PurpleButton disabled>
                            {gardener.terminationDate ? t("AgreementPage: terminated-cooperation-agreement-button-with-date").replaceAll("$DATE", formatDate(gardener.terminationDate)) : t("AgreementPage: terminated-cooperation-agreement-button-without-date")}
                        </PurpleButton>
                    ) : !latestAgreement || latestAgreement.version === legacyAgreementVersion ? (
                        <PurpleButton onClick={openAcceptAgreementDialog}>Accepter samarbejdsaftale</PurpleButton>
                    ) : (
                        <PurpleButton disabled>{t("AgreementPage: already-accepted-cooperation-agreement-button")}</PurpleButton>
                    )}
                </FloatingButton>

            </Container>

        </ContentSection>
    );
};
