import { Button, ButtonProps, makeStyles } from "@material-ui/core";
import LeftArrowIcon from "@material-ui/icons/ArrowBackIosOutlined";
import { useTranslation } from "../../internationalisation/translation.hook";

const useStyles = makeStyles(theme => ({
    root: {
        color: "#FFFFFF",
        whiteSpace: "nowrap",

        [theme.breakpoints.down(925)]: {
            minWidth: "auto",
            width: "48px",
            height: "48px",
        },
    },
    icon: {
        fontSize: "24px !important",
    },
    text: {
        fontSize: "16px",
        fontWeight: 500,
        textTransform: "none",

        [theme.breakpoints.down(925)]: {
            display: "none",
        },
    },
}));

const useButtonStyles = makeStyles(theme => ({
    startIcon: {
        [theme.breakpoints.down(925)]: {
            margin: 0,
        },
    },
}));

export const BackButton = (props: Omit<ButtonProps, "children">) => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();

    const { className, ...otherProps } = props;
    const { t } = useTranslation();

    const icon = <LeftArrowIcon className={classes.icon} />;

    return (
        <Button startIcon={icon} className={className ? `${classes.root} ${className}` : classes.root} classes={buttonClasses} {...otherProps}>
            <span className={classes.text}>{t("BackButton: back")}</span>
        </Button>
    );
};
