import { PropsWithChildren } from "react";

import { Button, ButtonProps, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        padding: "12px 8px",
        backgroundColor: "#2FA52B",
        color: "#FFFFFF",
        textTransform: "none",
        lineHeight: "125%",

        "&:hover": {
            backgroundColor: "#238221",
        },

        "&.Mui-disabled": {
            backgroundColor: "#C0C0C0",
            color: "#FFFFFF",
        },
    },
});

interface PositiveActionProps extends Pick<ButtonProps, "disabled"> {
    onClick: () => void;
}

export const PositiveAction = (props: PropsWithChildren<PositiveActionProps>) => {
    const classes = useStyles();

    return (
        <Button className={classes.root} {...props} />
    );
};
