import { PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#297A1D",
        padding: "0 24px",

        [theme.breakpoints.down(500)]: {
            padding: "0 16px",
        },
    },
    title: {
        padding: "24px",
        fontSize: "20px",
        fontWeight: 600,
        letterSpacing: "0.75px",
        color: "#ffffff",
        textAlign: "center",
        lineHeight: "125%",
        margin: 0,

        [theme.breakpoints.down(500)]: {
            padding: "24px 16px",
            fontSize: "16px",
        },
    },
    dummySlot: {
        visibility: "hidden",
    },
}));

interface TitleHeaderProps {
    button?: JSX.Element;
}

export const TitleHeader = (props: PropsWithChildren<TitleHeaderProps>) => {
    const classes = useStyles();

    const { children, button } = props;

    return (
        <div className={classes.root}>

            <div>{button}</div>

            <h1 className={classes.title}>{children}</h1>

            <div className={classes.dummySlot}>{button}</div>

        </div>
    );
};
