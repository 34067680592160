import { cloneElement, createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";

const Context = createContext<{
    open: boolean;
    openDialog: (dialog: JSX.Element) => void;
    closeDialog: () => void;
}>(undefined as any);

export const DialogProvider = (props: PropsWithChildren<{}>) => {
    const [dialog, setDialog] = useState<JSX.Element | null>(null);

    const openDialog = useCallback((dialog: JSX.Element) => {
        const uniqueId = new Date().getTime();
        const uniqueDialog = cloneElement(dialog, { key: uniqueId });

        setDialog(uniqueDialog);
    }, []);

    const closeDialog = useCallback(() => {
        setDialog(null);
    }, []);

    const open = Boolean(dialog);
    const value = useMemo(() => ({ openDialog, closeDialog, open }), [openDialog, closeDialog, open]);

    const retainedDialog = useRetainedValue(dialog);

    return (
        <Context.Provider value={value}>

            {props.children}
            {retainedDialog}

        </Context.Provider>
    );
};

export const useDialog = () => {
    return useContext(Context);
}

const useRetainedValue = <T extends any>(value: T) => {
    const [retainedValue, setRetainedValue] = useState<T | null>(value);

    useEffect(() => {
        if ( !value ) return;

        setRetainedValue(value);
    }, [value]);

    return retainedValue;
};
