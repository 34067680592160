import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from "react";

import { FormItem } from "../core/form-item.model";
import { FormController } from "../core/form.controller";
import { PageFormItem, GroupFormItem } from "../../client/components";
import { useAnimation } from "../../../validation/animation.hook";

export type WillSubmitHandler = () => { id: string, block: boolean };

const FormContext = createContext({
    controller: null as unknown as FormController,
    validationAnimationDuration: 200,
    updateItem: <Item extends FormItem>(id: string, update: (item: Item) => Item) => {},
    page: null as unknown as PageFormItem<FormItem>,
    hasPreviousPage: false,
    hasNextPage: false,
    goBack: () => {},
    goForward: () => {},
});

export const useForm = () => {
    return useContext(FormContext);
};

export interface FormProps<T> {
    controller: FormController;
    item: T;
    onCancel: () => void;
    onSubmit: (controller: FormController, item: T) => void;
}

export const FormProvider = <T extends PageFormItem<FormItem> | GroupFormItem<PageFormItem<FormItem>[]>>(props: PropsWithChildren<FormProps<T>>) => {
    const { controller, onCancel, onSubmit } = props;
    
    const [item, setItem] = useState(props.item);
    const updateItem = useCallback(<Item extends FormItem>(id: string, update: (previousState: Item) => Item) => {
        setItem(state => controller.updateItem(id, state, update));
    }, [controller]);

    const pages = useMemo(() => controller.getPages(item), [controller, item]);
    const [pageIndex, setPageIndex] = useState(0);

    const page = pages[pageIndex];
    
    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();

    const scrollToSection = useCallback((sectionId: string) => {
        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, []);

    const validate = useCallback(() => {
        const blockingFormItem = controller.getBlockingItemDescendant(page.item);
        if ( !blockingFormItem ) return true;

        const pageItems = controller.getPageItems(page);
        pageItems.forEach(pageItem => {
            if ( "id" in pageItem && pageItem.id ) {
                updateItem(pageItem.id, state => controller.markDirty(state));
            }
        });

        skipValidationAnimation();

        setTimeout(() => {
            if ( "id" in blockingFormItem ) {
                scrollToSection(`${blockingFormItem.id}-section`);
            }
        }, 100);

        return false;
    }, [controller, page, updateItem, scrollToSection, skipValidationAnimation]);

    const hasPreviousPage = useMemo(() => {
        return pageIndex > 0;
    }, [pageIndex]);

    const hasNextPage = useMemo(() => {
        return pageIndex < pages.length - 1;
    }, [pageIndex, pages.length]);

    const goBack = useCallback(() => {
        if ( hasPreviousPage ) return setPageIndex(pageIndex - 1);

        onCancel();
    }, [onCancel, hasPreviousPage, pageIndex]);

    const goForward = useCallback(() => {
        const isValid = validate();
        if ( !isValid ) return;

        if ( hasNextPage ) return setPageIndex(pageIndex + 1);

        onSubmit(controller, item);
    }, [controller, item, validate, onSubmit, pageIndex, hasNextPage]);

    const formContext = useMemo(() => ({
        controller,
        validationAnimationDuration,
        updateItem,
        page,
        hasPreviousPage,
        hasNextPage,
        goBack,
        goForward,
    }), [controller, validationAnimationDuration, updateItem, page, hasPreviousPage, hasNextPage, goBack, goForward]);

    return (
        <FormContext.Provider value={formContext}>
            {props.children}
        </FormContext.Provider>
    );
};
