import { useCallback, useRef } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { LinearProgress } from "../application/LinearProgress";
import { useMatch } from "../application/matches.hook";
import { TitleHeader } from "../components/page/TitleHeader";
import { BackButton } from "../components/page/BackButton";
import { NotFoundPage } from "../application/NotFoundPage";
import { useTranslation } from "../internationalisation/translation.hook";
import { CustomerDetailPage } from "./CustomerDetailPage";
import { useCreateRegistrationWithoutAppointmentAction } from "../registration/create-registration-dialog/create-registration-without-appointment-action.hook";
import { useUser } from "../user/UserProvider";
import { useTaskResponseAction } from "../task/task-response-action.hook";
import { useOpenAppointmentsForCustomer } from "../appointment/appointments.hook";

export const IntegratedCustomerDetailPage = () => {
    const { t } = useTranslation();
    const { matchId, tab } = useParams<{ matchId: string, tab: "current" | "past" }>();

    const history = useHistory();
    const goBack = useCallback(() => {
        history.push(`/customers/${tab}`);
    }, [history, tab]);

    const gardener = useUser();
    const match = useMatch(matchId);
    const openAppointments = useOpenAppointmentsForCustomer(gardener.id, match && match.customer.id);

    const createRegistration = useCreateRegistrationWithoutAppointmentAction();
    const taskResponseAction = useTaskResponseAction();
    const now = useRef(moment().toISOString(true)).current;

    if ( match === null || openAppointments === null ) return <NotFoundPage />;
    if ( match === undefined || openAppointments === undefined ) return <LinearProgress />;

    const backButton = <BackButton onClick={goBack} />;

    return (
        <>
            <TitleHeader button={backButton}>{t("LeftTabBar: customers-tab")}</TitleHeader>
            
            <CustomerDetailPage
                gardener={gardener}
                customer={match}
                hasOpenAppointments={openAppointments.length > 0}
                createRegistration={createRegistration}
                taskResponseAction={taskResponseAction}
                now={now}
                canCreateAppointment={tab === "past"}
            />
        </>
    );
};
