import { useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";

import { FutureTasksTab } from "./FutureTasksTab";
import { useTasks } from "../../task/TaskProvider";
import { TaskDocument } from "../../task/task.model";

export const IntegratedFutureTasksTab = () => {
    const history = useHistory();
    const goToTask = useCallback((task: { id: string }) => {
        history.push(`/overview/future/${task.id}`);
    }, [history]);
    
    const now = useRef(moment().toISOString(true)).current;

    const tasks = useTasks();
    const relevantTasks = tasks.filter(task => task.type !== "offer-response" && moment(task.performAfterDate).isAfter(now, "day")) as Exclude<TaskDocument, { type: "offer-response" }>[];

    return (
        <FutureTasksTab
            goToTask={goToTask}
            tasks={relevantTasks}
            now={now}
        />
    );
};
