import { Validation, validateMandatory } from "../../components/validation/validation";
import { CreateRegistrationDialogViewModel } from "./CreateRegistrationDialog";

export const validateTools = (tools: CreateRegistrationDialogViewModel["tasks"][0]["tools"], customerName: string): Validation => {
    const error = validateMandatory(tools);
    if ( error ) return { severity: "error", message: error };

    if ( tools === "large-tools" ) return { severity: "warning", message: `Brug kun denne takst, hvis det på forhånd er aftalt med ${customerName.split(" ")[0]}` };

    return undefined;
};
