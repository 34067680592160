import moment from "moment";

import { Translation } from "../../internationalisation/translation.hook";
import { TaskDocument } from "../../task/task.model";
import { determineDaysUntilTaskDeadline, formatDate } from "../../utility";

export const formatNewTaskDate = (date: string, now: string, t: Translation): string => {
    const hours = moment(date).diff(now, "hours");
    if ( hours > 1 ) return t("CurrentTasksTab: new-task-format-hours-plural").replaceAll("$HOURS", hours.toString());
    if ( hours === 1 ) return t("CurrentTasksTab: new-task-format-hours-singular");

    const minutes = moment(date).diff(now, "minutes");
    if ( minutes === 1 ) return t("CurrentTasksTab: new-task-format-minutes-singular");
    
    return t("CurrentTasksTab: new-task-format-minutes-plural").replaceAll("$MINUTES", minutes.toString());
}

export function formatTaskDate(task: TaskDocument, now: string, t: Translation): string {
    const days = determineDaysUntilTaskDeadline(task, now);

    if ( days > 1 ) return t("CurrentTasksTab: response-future").replaceAll("$DATE", formatDate(task.performAfterDate));
    if ( days === 1 ) return t("CurrentTasksTab: response-tomorrow");
    if ( days === 0 ) return t("CurrentTasksTab: response-today")
    if ( days === -1 ) return `${t("CurrentTasksTab: response-missing")} ${t("CurrentTasksTab: days-ago-singular")}`;

    return `${t("CurrentTasksTab: response-missing")} ${t("CurrentTasksTab: days-ago-plural").replaceAll("$DAYS", Math.abs(days).toString())}`;
}
