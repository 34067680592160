import { Container } from "@material-ui/core";
import { useCallback, useMemo } from "react";

import { ContentSection } from "../../application/ContentSection";
import { useTranslation } from "../../internationalisation/translation.hook";
import { ListSectionAlt, ListSectionAltProps } from "../../components/list-section-alt/ListSectionAlt";
import { TaskDocument } from "../../task/task.model";
import { OverviewFaq } from "../../faq/OverviewFaq";
import { Section } from "../../components/section/Section";
import { SectionHeader } from "../../components/section/SectionHeader";
import { mapTaskToTaskItem, sortTaskByDateAndCustomerName } from "../current-tasks/CurrentTasksTab";

type Task = Extract<TaskDocument, { type: "appointment-follow-up" | "registration-follow-up" }>;

interface FutureTasksTabProps {
    goToTask: (task: Task) => void;
    tasks: Task[];
    now: string;
}

export const FutureTasksTab = (props: FutureTasksTabProps) => {
    const { t } = useTranslation();

    const { goToTask, tasks, now } = props;

    const futureTasks = useMemo(() => {
        return tasks.sort(sortTaskByDateAndCustomerName);
    }, [tasks]);

    const goToFutureTask = useCallback(index => goToTask(futureTasks[index]), [goToTask, futureTasks]);

    const futureTaskItems = useMemo<ListSectionAltProps["items"]>(() => {
        return tasks.map(task => mapTaskToTaskItem(task, now, t));
    }, [t, tasks, now]);

    return (
        <ContentSection>

            <Container maxWidth="sm" disableGutters>

                <ListSectionAlt
                    title={t("FutureTasksTab: future-header")}
                    placeholder={t("FutureTasksTab: future-placeholder")}
                    items={futureTaskItems}
                    onClick={goToFutureTask}
                />

            </Container>

            <Container maxWidth="md" disableGutters>
                <Section>
                    <SectionHeader>Ofte stillede spørgsmål</SectionHeader>
                    <OverviewFaq />
                </Section>
            </Container>

        </ContentSection>
    );
};
