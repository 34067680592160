import { useCallback, useRef } from "react";
import { NeutralAction, PositiveAction } from "../../components/card";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../../components/dialog";
import { useTranslation } from "../../internationalisation/translation.hook";

interface RequestPayoutDialogProps {
    onConfirm: () => void;
    onCancel: () => void;
}

export const RequestPayoutDialog = (props: RequestPayoutDialogProps) => {
    const { t } = useTranslation();
    const { onConfirm, onCancel } = props;

    return (
        <Dialog>
            <DialogHeader>{t("RequestPayoutDialog: dialog-header")}</DialogHeader>
            <DialogContent>{t("RequestPayoutDialog: dialog-content")}</DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>{t("Button: cancel")}</NeutralAction>
                <PositiveAction onClick={onConfirm}>{t("Button: confirm")}</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

export const useRequestPayoutDialog = (onConfirm: RequestPayoutDialogProps["onConfirm"]) => {
    const { openDialog, closeDialog } = useDialog();

    const hasConfirmed = useRef(false);
    const confirm: RequestPayoutDialogProps["onConfirm"] = useCallback(() => {
        if ( hasConfirmed.current ) return;
        hasConfirmed.current = true;

        closeDialog();

        onConfirm();
    }, [closeDialog, onConfirm, hasConfirmed]);

    return useCallback(() => {
        hasConfirmed.current = false;

        openDialog(<RequestPayoutDialog onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm, hasConfirmed]);
};
