import { useEffect, useState } from "react";

import firebase from "firebase/app";
import { MatchDocument } from "../customer/match.model";

export const useMatch = (matchId: string) => {
    const [state, setState] = useState<MatchDocument | null>();

    useEffect(() => {
        setState(undefined);

        return firebase.firestore().collection("matches").doc(matchId).onSnapshot(document => {
            if ( !document.exists ) return setState(null);

            setState({ id: document.id, ...document.data() } as MatchDocument);
        }, error => {
            console.error(error);

            setState(null);
        });

    }, [matchId]);

    return state;
}

export const useAllMatches = (gardenerId: string, status?: "current customers" | "past customers") => {
    const [state, setState] = useState<MatchDocument[]>();

    useEffect(() => {
        setState(undefined);

        let query = firebase.firestore().collection("matches").where("gardener.id", "==", gardenerId)

        if ( status === "current customers" ) query = query.where("gardeningTaskIds", "!=", []);
        if ( status === "past customers" ) query = query.where("gardeningTaskIds", "==", []);
        
        return query.onSnapshot(collection => {
                const matches = collection.docs.map(document => ({ id: document.id, ...document.data() } as MatchDocument));

                setState(matches);
            });

    }, [gardenerId, status]);

    return state;
};
