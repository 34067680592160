import { FormItem } from "../../framework/core/form-item.model";
import { FormItemSupport } from "../../framework/core/form-item.support";
import { FormItemComponent } from "../components/FormItemComponent";
import { useForm } from "../../framework/react/FormProvider";
import { FormController } from "../../framework/core/form.controller";

export interface GroupFormItem<NextItem extends FormItem[]> {
    type: "group";
    items: NextItem;
    render: "all at once" | "one at a time";
}

interface GroupFormItemComponentProps<NextItem extends FormItem[]> {
    item: GroupFormItem<NextItem>;
}

export const GroupFormItemComponent = <NextItem extends FormItem[]>(props: GroupFormItemComponentProps<NextItem>) => {
    const { item } = props;

    const { controller } = useForm();

    const blockingIndex = item.items.findIndex(x => controller.getBlockingItemDescendant(x));
    const childrenToDisplay = item.render === "all at once" || blockingIndex === -1 ? item.items : item.items.slice(0, blockingIndex + 1);

    return (
        <>
            {childrenToDisplay.map((child, childIndex) => (
                <FormItemComponent key={childIndex} item={child} />
            ))}
        </>
    );
};

export class GroupFormItemSupport implements FormItemSupport<GroupFormItem<FormItem[]>> {

    supportedType = "group" as const;

    updateChildren = (item: GroupFormItem<FormItem[]>, updateChildItem: (item: FormItem) => FormItem) => {
        return {
            ...item,
            items: item.items.map(childItem => {
                return updateChildItem(childItem);
            }),
        };
    };

    markDirty = undefined;

    getAllChildren(controller: FormController, item: GroupFormItem<FormItem[]>) {
        return item.items;
    }

    getActiveChildren(controller: FormController, item: GroupFormItem<FormItem[]>) {
        return item.items;
    }

    blocksParent(): boolean {
        return false;
    }
}
