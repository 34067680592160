import { useMemo } from "react";
import { Redirect, useParams } from "react-router-dom";

import { TitleHeader } from "../../components/page/TitleHeader";
import { TabBar } from "../../components/tabs/TabBar";
import { TabBarItem } from "../../components/tabs/TabBarItem";
import { useTranslation } from "../../internationalisation/translation.hook";
import { IntegratedCurrentCustomersTab } from "./IntegratedCurrentCustomersTab";
import { IntegratedPastCustomersTab } from "./IntegratedPastCustomersTab";
import { useUser } from "../../user/UserProvider";

export const IntegratedCustomerOverviewPage = () => {
    const { tab } = useParams<{ tab: "current" | "past" }>();

    const tabIndex = useMemo(() => {
        switch ( tab ) {
            case "current": return 0;
            case "past": return 1;
        }
    }, [tab]);

    const { t } = useTranslation();
    const gardener = useUser();

    if ( tab !== "current" && tab !== "past" ) return <Redirect to="/customers/current" />;

    return (
        <>
            <TitleHeader>{t("LeftTabBar: customers-tab")}</TitleHeader>

            <TabBar index={tabIndex}>
                <TabBarItem href="/customers/current">{t("CustomerOverviewPage: tab-current")}</TabBarItem>
                {!gardener.allowRegistrationBackdoor ? (
                    <TabBarItem href="/customers/past">{t("CustomerOverviewPage: tab-past")}</TabBarItem>
                ) : null}
            </TabBar>

            {(() => {
                if ( tab === "current" ) return <IntegratedCurrentCustomersTab />;
                if ( tab === "past" ) return <IntegratedPastCustomersTab />;

                return null;
            })()}
        </>
    );
};
