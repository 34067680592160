import { PropsWithChildren } from "react";

import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        padding: "12px 8px",
        backgroundColor: "#EDEDED",
        color: "#4A4A4A",
        textTransform: "none",
        lineHeight: "125%",

        "&:hover": {
            backgroundColor: "#D5D5D5",
        },
    },
});

interface NeutralActionProps {
    onClick: () => void;
}

export const NeutralAction = (props: PropsWithChildren<NeutralActionProps>) => {
    const classes = useStyles();

    return (
        <Button className={classes.root} {...props} />
    );
};
