import { ButtonProps, makeStyles } from "@material-ui/core";

import { ButtonBase } from "./ButtonBase";

const useStyles = makeStyles({
    root: {
        backgroundColor: "#FFFFFF",
        color: "#4A4A4A",
        border: "1px solid rgba(129, 129, 129, 0.5)",
        boxShadow: "none",

        "&:hover": {
            backgroundColor: "#E8E8E8",
            border: "1px solid rgba(129, 129, 129, 0.5)",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        },
    },
});

export const WhiteButton = (props: ButtonProps) => {
    const classes = useStyles();

    const { className, ...otherProps } = props;

    return (
        <ButtonBase
            className={className ? `${classes.root} ${className}` : classes.root}
            variant="outlined"
            {...otherProps}
        />
    );
};
