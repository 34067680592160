import { PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        position: "sticky",
        bottom: "32px",
        textAlign: "center",
    },
});

export const FloatingZone = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    return <div className={classes.root} {...props} />;
};
