import { useEffect } from "react";

import firebase from "firebase/app";

import { LinearProgress } from "../application/LinearProgress";
import { useHistory, useLocation } from "react-router-dom";

const useQuery = () => {
    const location = useLocation();

    return new URLSearchParams(location.search);
};

export const SignIn = () => {
    const query = useQuery();
    const token = query.get("token");
    const returnUrl = query.get("returnUrl") || "/";

    const history = useHistory();

    useEffect(() => {
        (async () => {
            await firebase.auth().signOut();

            if ( !token ) throw new Error("Needs a token to sign in");
            await firebase.auth().signInWithCustomToken(token);

            const returnPath = returnUrl.substring(returnUrl.indexOf("/"));

            history.replace(returnPath);
        })()
            .catch(() => history.replace("/logout"));

    }, [history, token, returnUrl]);

    return <LinearProgress />;
};
