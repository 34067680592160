import { PropsWithChildren } from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        "& > *": {
            display: "flex",
            flexDirection: "column",
            rowGap: "16px",
        },
    }
})

export const VerticalButtons = (props: PropsWithChildren<{}>) => {
    const classes = useStyles();

    const { children } = props;

    return (
        <div className={classes.root}>
            <div>
                {children}
            </div>
        </div>
    );
};
