import moment from "moment";

import { AppointmentDocument } from "../../../../../appointment/appointment.model";
import { GroupFormItem, NoticeFormItem, PageFormItem, SingleChoiceFormItem } from "../../components";

export interface ActionPageIds {
    singleChoiceAction: string;
}

export type ActionPage<Pages extends PageFormItem<any>[]> =
    PageFormItem<
        GroupFormItem<[
            NoticeFormItem,
            SingleChoiceFormItem<Pages>,
        ]>
    >;

export const createActionPage = (didVisitPage: PageFormItem<any> | null, changedDatePage: PageFormItem<any>, cancelledAppointmentPage: PageFormItem<any>, closeTaskPage: PageFormItem<any>, appointment: AppointmentDocument, formIds: ActionPageIds): ActionPage<any[]> => {
    const answerTexts = [];
    if ( didVisitPage ) answerTexts.push("Jeg har besøgt kunden");
    answerTexts.push("Vi har ændret datoen for besøget");
    answerTexts.push("Vi har aflyst aftalen");
    answerTexts.push("Opgaven skal lukkes");

    const answerItems: PageFormItem<any>[] = [];
    if ( didVisitPage ) answerItems.push(didVisitPage);
    answerItems.push(changedDatePage);
    answerItems.push(cancelledAppointmentPage);
    answerItems.push(closeTaskPage);

    const actionPage: ActionPage<any[]> = {
        type: "page",
        title: "Besvar opgave",
        item: {
            type: "group",
            render: "all at once",
            items: [
                {
                    type: "notice",
                    render: "info",
                    html: `Du har planlagt et besøg <strong>${formatAppointmentDate(appointment.scheduledDate)}</strong> hos <strong>${appointment.customer.name}</strong>. Det forventes, at du registrerer tid samme dag, som du har udført havearbejde hos kunden.`,
                },
                {
                    id: formIds.singleChoiceAction,
                    type: "single-choice",
                    mode: "switch page",
                    render: "vertical-buttons",
                    selectedChoiceIndex: null,
                    dirty: false,
                    questionText: "Hvad vil du gerne registrere?",
                    answerTexts,
                    answerItems,
                    destructive: true,
                },
            ],
        },
    };

    return actionPage;
};

function formatAppointmentDate(appointmentDate: string): string {
    const day = moment(appointmentDate).format("dddd");

    const date = moment(appointmentDate).format("D MMMM YYYY");

    return `${day} d. ${date}`;
}
